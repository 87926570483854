import { Add, Close } from '@mui/icons-material';
import { Box, Tab, Tabs, Button, ButtonGroup, Chip } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ColDef, GetRowIdParams, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React, { ReactElement, ReactNode, useEffect, useState, useMemo, useRef } from 'react';
import { useLoadingState, useMtmDate } from '../../../../Hooks';
import { StoneXAutocomplete, StoneXModal, StoneXTextField } from '../../../StoneXMui';
import useActiveApplication from '../../../../Hooks/CommodityManagement/useActiveApplication';
import { MarkItViewApi } from '../../../../Apis/Apis';
import { MarketViewApplication, MarketViewUserModel } from '../../../../Generated/Mark-It-View-Api';
import { StoneXRow } from '../../../StoneX';
import StoneXMainPage from '../../../StoneX/StoneXMainPage/StoneXMainPage';

interface AddUserRequest {
  firstName: string;
  lastName: string;
  isAdministrator: boolean;
  email: string;
  companyName?: string;
  marketViewApplications: MarketViewApplication[];
}

export default function Users() {
  const gridRef = useRef<AgGridReact<MarketViewUserModel>>(null);
  const siteGuid = useActiveApplication();
  const [siteUsers, setSiteUsers] = useState<MarketViewUserModel[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalUser, setModalUser] = useState<MarketViewUserModel | undefined>();
  const saveUserLoadingState = useLoadingState();

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )} 
      </div>
    );
  }

  useEffect(() => {
    MarkItViewApi.listMarketViewUsers(null).then((res) => setSiteUsers(res.data.rows ?? []));
  }, []);

  function updateUser(user: MarketViewUserModel) {
    setModalUser(user);
    setModalOpen(true);
  }

  function saveUser(userToSave: AddUserRequest) {
    //if (!userToSave.commodityManagementUserGuid) {
    //add
    MarkItViewApi.addMarketViewUser(
      saveUserLoadingState.setLoadingState,
      userToSave.email,
      undefined, // No StoneXUserGuid
      userToSave.firstName,
      userToSave.lastName,
      // userToSave.companyName,
      userToSave.marketViewApplications,
    ).then((res) => setSiteUsers((previous) => [...previous, res.data as MarketViewUserModel]));

    /*}else {
    MarkItViewApi.upd(       
        saveUserLoadingState.setLoadingState,
        userToSave.commodityManagementUserGuid!.toString(),
        userToSave!,
      ).then((res) => {
        setProductSymbols((previous) => previous.map((commodity) => (commodity.productId == res.data.product?.productId ? (res.data.product as MarketViewProductSymbolView) : commodity)));
      });
    }*/
  }
  function addUser() {
    setModalUser(undefined);
    setModalOpen(true);
  }
  const columnDefs = [
    {
      headerName: 'First Name',
      field: 'firstName',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Last Name',
      field: 'lastName',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      flex: 1,
    },
    {
      headerName: 'Admin',
      field: 'isAdmin',
      sortable: true,
      width: 90,
      cellRendererFramework: (props: ICellRendererParams<MarketViewUserModel>) =>
        props.data?.isAdministrator ? <Chip variant="success" label="ADMIN" /> : <></>,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 400,
      cellRendererFramework: (params: any) => {
        return (
          <ButtonGroup variant="text">
            <Button
              onClick={() => {
                updateUser(params.data);
              }}
            >
              Update
            </Button>
            <Button onClick={() => {}}>Promote</Button>
            <Button onClick={() => {}}>Disable</Button>
            <Button onClick={() => {}}>Remove</Button>
          </ButtonGroup>
        );
      },
    },
  ];

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      sortable: true,
      resizable: true,
      suppressMenu: true,
    };
  }, []);

  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <StoneXMainPage>
      <StoneXRow align="baseline">
        <h1>Users</h1>
      </StoneXRow>
      <StoneXRow align="end">
        <h3>Users</h3>
        <Button className="pull-right" variant="outlined" onClick={addUser}>
          Add
        </Button>
        <div className="ag-theme-alpine" style={{ width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            getRowId={(params: GetRowIdParams) => params.data.commodityManagementUserGuid}
            enableRangeSelection
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            domLayout="autoHeight"
            rowData={siteUsers}
          />
        </div>
      </StoneXRow>
      <UpdateUserModal open={modalOpen} onClose={() => setModalOpen(false)} onUserSave={saveUser} user={modalUser} />
    </StoneXMainPage>
  );
}

interface UpdateUserProps {
  open: boolean;
  onClose: () => void;
  onUserSave: (user: AddUserRequest) => void;
  user?: MarketViewUserModel;
}

function UpdateUserModal(props: UpdateUserProps) {
  const { open, onClose, onUserSave, user } = props;
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [companyName, setCompanyName] = useState<string>();
  const [isAdmin, setIsAdmin] = useState<boolean>();

  const actionLabel = props.user ? 'Update' : 'Add';

  useEffect(() => {
    setFirstName(user?.firstName ?? '');
    setLastName(user?.lastName ?? '');
    setEmail(user?.email ?? '');
    // setCompanyName(user?.companyName ?? '');
    setIsAdmin(user?.isAdministrator ?? false);
  }, [user]);

  function updateUser() {
    //todo: validate

    onUserSave({
      firstName: firstName!,
      lastName: lastName!,
      email: email!,
      companyName: companyName,
      isAdministrator: isAdmin!,
      marketViewApplications: [],
    });

    onClose();
  }

  return (
    <StoneXModal header={actionLabel + ' User'} open={open} onClose={onClose}>
      <StoneXRow>
        <StoneXTextField label="First Name" value={firstName} onChange={(event: any) => setFirstName(event.target.value)} />
        <StoneXTextField label="Last Name" value={lastName} onChange={(event: any) => setLastName(event.target.value)} />
        <StoneXTextField label="Email" value={email} onChange={(event: any) => setEmail(event.target.value)} />
        <StoneXTextField label="Company" value={companyName} onChange={(event: any) => setCompanyName(event.target.value)} />
        Admin? <input type="checkbox" checked={isAdmin} onChange={(event: any) => setIsAdmin(event.target.checked)} />
      </StoneXRow>

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={updateUser}>{actionLabel + ' User'}</Button>
      </StoneXRow>
    </StoneXModal>
  );
}
