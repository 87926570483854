import { ColDef, ColGroupDef } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { StoneXGrid } from '../../../../../StoneX';
import { VarianceColumns, VarianceReportColumn, VarianceReportRow } from './Types';
import { last } from 'lodash';
import { AgGridReactProps } from 'ag-grid-react';

interface VarianceTableProps{
  rows: VarianceReportRow[];
  activeColumns: VarianceReportColumn[];
  setPathToChart: (path: string[] | undefined) => void;
}

export function VarianceTable(props: VarianceTableProps) {
  const { activeColumns, rows, setPathToChart } = props;

  const [columns, setColumns] = useState<(ColDef<VarianceReportRow> | ColGroupDef<VarianceReportRow>)[]>();

  function updateColumns() {
    if (!activeColumns) {
      setColumns([]);
      return;
    }

    const columnDefs: (ColDef<VarianceReportRow> | ColGroupDef<VarianceReportRow>)[] = [];

    for (let i = 0; i < activeColumns.length; i++) {

      const previousColumn = i == 0 ? undefined : VarianceColumns[activeColumns[i-1]];
      const column = VarianceColumns[activeColumns[i]];
      const columnDef = column as ColDef<VarianceReportRow>;

      if (column.parentHeader) {
        // If the previous column has the same parent header, add it under that
        if (column.parentHeader === previousColumn?.parentHeader) {
          (last(columnDefs) as ColGroupDef<VarianceReportRow>).children.push(columnDef);
        } 
        else {
          // Add it as a new parent header
          columnDefs.push({
            headerName: column.parentHeader,
            children: [columnDef]
          })
        }
      }
      else {
        columnDefs.push(columnDef);
      }

    }

    setColumns(columnDefs);
  }

  useEffect(updateColumns, [activeColumns])

  const gridOptions = {
    treeData: true,
    getDataPath: (data: VarianceReportRow) => data.path
  } as AgGridReactProps<VarianceReportRow>;

  return (  
    <StoneXGrid
      columns={columns!} 
      rows={rows} 
      gridOptions={gridOptions}
      onChartClick={setPathToChart} 
      chartButtonDisplayCondition={x => x.data !== undefined} 
      expandCollapseRowCondition={x => x.data !== undefined}  
      groupColumnWidth={250} />
  );
}
