import { CellClassParams, IAggFuncParams, ICellRendererParams, ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { BudgetModel, CommodityModel, ComponentType, ContractDate, LocationModel } from "../../../../../../Generated/Commodity-Management-Api";
import { MonthInteger } from "../../../../../../Types/MonthDictionary";
import VarianceReport from "./VarianceReport";
import { StoneXNumber } from "../../../../../StoneXMui";
import { GridRow, GroupingOption } from '../../../../../../Types';

// Variance Report Types
// export interface VarianceReportConfiguration {
//   columns: VarianceColumn[];
//   startMonthStrategy: 'fiscalYearStart' | 'currentMonth';
//   showBudgetStartAndEndDateFilter?: boolean;
//   startMonthOffset?: number;
//   rollOffInToNewYearMonth?: MonthInteger;
//   numberOfMonthsToShow?: number;
// }

// Variance Report Types
export interface VarianceReportConfiguration {
    inputs?: {
        budgetStartDate?: {
            hide?: boolean;
            strategy?: 'fiscalYearStart' | 'currentMonth'; // Defaults to FiscalYearStart
            offset?: number;
            rollOffInToNewYearMonth?: MonthInteger;
        };
        budgetEndDate?: {
            hide: boolean;
            offsetFromStartMonth?: number; // Defaults to 12
        };
    };
    behavior?: {
        grouping?: GroupingOption[];
    };
    columns: {
        available: VarianceReportColumn[];
        active: VarianceReportColumn[];
    };
}

export interface VarianceReportUserOverride {
  behavior?: {
    grouping?: GroupingOption[];
  },
  columns?: {
    active?: VarianceReportColumn[];
    },
    chart?: {
        chart?: ChartTypes;
    };
}

// const VarianceColumn = {
//   BudgetMonth: 'BudgetMonth',
//   Commodity: "Commodity",
//   Location: "Location",
//   ContractMonth: 'ContractMonth',
//   BudgetVolume: 'BudgetVolume',
//   BudgetPrice: 'BudgetPrice',
//   HedgedVolume: 'HedgedVolume',
//   HedgedPercent: 'HedgedPercent',
//   HedgedPrice: 'HedgedPrice',
//   MarketPrice: 'MarketPrice',
//   OpenVolume: 'OpenVolume',
//   BlendedPrice: 'BlendedPrice',
//   VarianceToBudgetPrice: "VarianceToBudgetPrice",
//   VarianceToBudgetValue: "VarianceToBudgetValue",
//   VarianceToMarketPrice: "VarianceToMarketPrice",
//   VarianceToMarketValue: "VarianceToMarketValue",    
// };

// type AllInComponentColumns = 'HedgedPrice' | 'HedgedValue' | 'HedgedVolume';
// type AllInTypeColumn = {
//   [K in keyof AllInComponentColumns as `AllIn.${AllInComponentColumns}`]: string;
// }

// type ComponentColumns = 'BudgetPrice' | 'HedgedVolume' | 'HedgedPercent' | 'HedgedPrice' | 'MarketPrice' | 'OpenVolume' | 'BlendedPrice' | 'BlendedValue';
// type SubComopnentTypes = Omit<(typeof ComponentType), "None" | "AllIn">;
// type SubComponentTypeColumn = {
//   [K in keyof SubComopnentTypes as `${SubComopnentTypes[K]}.${ComponentColumns}`]: string;
// };

// type ExtendedVarianceColumn = typeof VarianceColumn & AllInTypeColumn & SubComponentTypeColumn;

// export type VarianceColumn = keyof ExtendedVarianceColumn;


export interface VarianceReportRow extends GridRow
// export interface VarianceReportRow extends GridRow
{
    // For ag-grid list view
    isAggregated?: boolean;
    isTotalByMonth?: boolean;

    // Budgets
    budgetValue: number;

    // Tags
    // commodity: CommodityModel;
    // budgetDate?: ContractDate;
    // location?: LocationModel;
    // locationHierarchy?: Array<LocationModel>;

    // For single column structur;
    // rowBudgetVolume: number;
    // rowBudgetPrice: number;
    // rowHedgedVolume: number;
    // rowHedgedPercent: number;
    // rowHedgedPrice: number;
    // rowMarketPrice: number;
    // rowOpenVolume: number;
    // rowBlendedPrice: number;

    // All In
    effectiveBudgetVolume?: number;
    budgetVolume?: number;
    budgetPrice?: number;
    hedgedVolume?: number;
    hedgedPercent?: number;
    hedgedPrice?: number;
    marketPrice?: number;
    openVolume?: number;
    blendedPrice?: number;
    blendedValue?: number;

    // Underlying;
    // underlyingBudgetVolume: number;
    underlyingBudgetPrice?: number;
    underlyingHedgedVolume?: number;
    underlyingHedgedPercent?: number;
    underlyingHedgedPrice?: number;
    underlyingMarketPrice?: number;
    underlyingOpenVolume?: number;
    underlyingBlendedPrice?: number;
    underlyingBlendedValue?: number;
    underlyingFixedHedgeVolume?: number;
    underlyingCallHedgeVolume?: number;
    underlyingCallStrikePrice?: number;
    underlyingNewFixedHedgeVolume?: number;
    underlyingNewFixedHedgePrice?: number;
    underlyingNewCallHedgeVolume?: number;
    underlyingNewCallHedgePrice?: number;
    underlyingNewCallStrikePrice?: number;

    // Basis
    // basisBudgetVolume: number;
    basisBudgetPrice?: number;
    basisHedgedVolume?: number;
    basisHedgedPercent?: number;
    basisHedgedPrice?: number;
    basisMarketPrice?: number;
    basisOpenVolume?: number;
    basisBlendedPrice?: number;
    basisBlendedValue?: number;

    // Variance
    marketValue?: number;
    varianceToBudgetPrice?: number,
    varianceToBudgetValue?: number,
    varianceToMarketPrice?: number,
    varianceToMarketValue?: number,
}

interface VarianceColumnConfiguration {
    colId: string;
    headerName?: string; // This is what appears on the table header
    columnName?: string; // This is a unique column name to distinguish from other versions of the same column
    parentHeader?: string;
    field?: string;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
    sortable?: boolean;
    flex?: number;
    hide?: boolean;
    filter?: string;
    cellClass?: string | string[] | ((params: CellClassParams<VarianceReportRow, any>) => string | string[] | undefined);
    aggFunc?: string | ((params: IAggFuncParams<VarianceReportRow, any>) => any) | null | undefined;
    valueFormatter?: (params: ValueFormatterParams<VarianceReportRow>) => string | ValueFormatterFunc<VarianceReportRow, any>;
    cellRenderer?: (params: ICellRendererParams<VarianceReportRow>) => any;
  }

export type VarianceReportColumn = 
    'Commodity'
    | 'BudgetMonth'
    | 'Location'
    // | 'LocationHierarchy'
    // | 'RowBudgetVolume'
    // | 'RowBudgetPrice'
    // | 'RowHedgedVolume'
    // | 'RowHedgedPercent'
    // | 'RowHedgedPrice'
    // | 'RowMarketPrice'
    // | 'RowOpenVolume'
    // | 'RowBlendedPrice'
    // | 'EffectiveBudgetVolume'
    // | 'BudgetValue'
    | 'BudgetVolume'
    | 'BudgetPrice'
    | 'HedgedVolume'
    | 'HedgedPercent' // Is Invalid?
    | 'HedgedPrice'
    | 'MarketPrice' 
    | 'OpenVolume' // Is Invalid?
    | 'BlendedPrice'
    // | 'BlendedValue'
    | 'UnderlyingBudgetPrice'
    | 'UnderlyingHedgedVolume'
    | 'UnderlyingCallHedgedVolume'
    | 'UnderlyingCallStrikePrice'
    | 'UnderlyingHedgedPercent'
    | 'UnderlyingHedgedPrice'
    | 'UnderlyingMarketPrice'
    | 'UnderlyingOpenVolume'
    | 'UnderlyingBlendedPrice'
    | 'UnderlyingBlendedValue'
    | 'UnderlyingFixedHedgeVolume'
    | 'UnderlyingNewFixedHedgeVolume'
    | 'UnderlyingNewFixedHedgePrice'
    | 'UnderlyingNewCallHedgeVolume'
    | 'UnderlyingNewCallHedgePrice'
    | 'UnderlyingNewCallStrikePrice'
    | 'BasisBudgetPrice'
    | 'BasisHedgedVolume'
    | 'BasisHedgedPercent'
    | 'BasisHedgedPrice'
    | 'BasisMarketPrice'
    | 'BasisOpenVolume'
    | 'BasisBlendedPrice'
    | 'BasisBlendedValue'
    // | 'MarketValue'
    | 'VarianceToBudgetPrice'
    | 'VarianceToBudgetValue'
    | 'VarianceToMarketPrice'
    | 'VarianceToMarketValue';


const priceWidth = 100;
const volumeWidth = 110;
const valueWidth = 120;
const percentWidth = 90;

const priceCellRenderer = (params: ICellRendererParams<VarianceReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} decimals={4}></StoneXNumber>;
const volumeCellRenderer = (params: ICellRendererParams<VarianceReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value}></StoneXNumber>;
const valueCellRenderer = (params: ICellRendererParams<VarianceReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} colored></StoneXNumber>;
const percentCellRenderer = (params: ICellRendererParams<VarianceReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} percent></StoneXNumber>;

export const VarianceColumns: {[key in VarianceReportColumn]: VarianceColumnConfiguration} = {
     BudgetMonth: {
         colId: 'Budget Month',
         headerName: 'Budget Month',
         field: 'budgetMonth.label',
         parentHeader: "Budget",
         flex: 1
     },
     Commodity: {
         colId: 'Commodity',
         headerName: 'Commodity',
         field: 'commodity.commodityName',
         parentHeader: "Budget"
     },
     Location: {
         colId: 'Location',
         headerName: 'Location',
         field: 'location.locationName',
         parentHeader: "Budget"
     },
    BudgetVolume: {
        colId: 'Budget Volume',
        headerName: 'Budget Volume',
        field: 'budgetVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "All In"
    },
    BudgetPrice: {
        colId: 'Budget Price',
        headerName: 'Budget Price',
        field: 'budgetPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "All In"
    },
    HedgedVolume: {
        colId: 'Hedged Volume',
        headerName: 'Hedged Volume',
        field: 'hedgedVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "All In"
    },
    HedgedPercent: {
        colId: 'Hedged Percent',
        headerName: 'Hedged Percent',
        field: 'hedgedPercent',
        width: percentWidth,
        cellRenderer: percentCellRenderer,
        parentHeader: "All In"
    },
    HedgedPrice: {
        colId: 'Hedged Price',
        headerName: 'Hedged Price',
        field: 'hedgedPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "All In"
    },
    MarketPrice: {
        colId: 'Market Price',
        headerName: 'Market Price',
        field: 'marketPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "All In"
    },
    OpenVolume: {
        colId: 'Open Volume',
        headerName: 'Open Volume',
        field: 'openVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "All In"
    },
    BlendedPrice: {
        colId: 'Blended Price',
        headerName: 'Blended Price',
        field: 'blendedPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "All In"
    },
    UnderlyingBudgetPrice: {
        colId: 'Underlying Budget Price',
        headerName: 'Budget Price',
        field: 'budgetPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingHedgedVolume: {
        colId: 'Underlying Hedged Volume',
        headerName: 'Hedged Volume',
        field: 'underlyingHedgedVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingHedgedPercent: {
        colId: 'Underlying Hedged Percent',
        headerName: 'Hedged Percent',
        field: 'underlyingHedgedPercent',
        width: percentWidth,
        cellRenderer: percentCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingHedgedPrice: {
        colId: 'Underlying Hedged Price',
        headerName: 'Hedged Price',
        field: 'underlyingHedgedPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingMarketPrice: {
        colId: 'Underlying Market Price',
        headerName: 'Market Price',
        field: 'underlyingMarketPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingOpenVolume: {
        colId: 'Underlying Open Volume',
        headerName: 'Open Volume',
        field: 'underlyingOpenVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingBlendedPrice: {
        colId: 'Underlying Blended Price',
        headerName: 'Blended Price',
        field: 'underlyingBlendedPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingBlendedValue: {
        colId: 'Underlying Blended Value',
        headerName: 'Blended Value',
        field: 'underlyingBlendedValue',
        width: valueWidth,
        cellRenderer: valueCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingFixedHedgeVolume: {
        colId: 'Underlying Fixed Hedge Volume',
        headerName: 'Fixed Volume',
        field: 'underlyingFixedHedgeVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingNewFixedHedgeVolume: {
        colId: 'Underlying New Fixed Hedge Volume',
        headerName: 'New Fixed Volume',
        field: 'underlyingNewFixedHedgeVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingNewFixedHedgePrice: {
        colId: 'Underlying New Fixed Hedge Price',
        headerName: 'New Fixed Price',
        field: 'underlyingNewFixedHedgePrice',
        width: volumeWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingNewCallHedgeVolume: {
        colId: 'Underlying New Call Hedge Volume',
        headerName: 'New Call Volume',
        field: 'underlyingNewCallHedgeVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingNewCallHedgePrice: {
        colId: 'Underlying New Call Hedge Price',
        headerName: 'New Call Price',
        field: 'underlyingNewCallHedgePrice',
        width: volumeWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingNewCallStrikePrice: {
        colId: 'Underlying New Call Strike Price',
        headerName: 'New Strike Price',
        field: 'underlyingNewCallStrikePrice',
        width: volumeWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingCallHedgedVolume: {
        colId: 'Underlying Call Hedged Volume',
        headerName: 'Call Hedged Volume',
        field: 'underlyingCallHedgedVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "Underlying"
    },
    UnderlyingCallStrikePrice: {
        colId: 'Underlying Call Strike Price',
        headerName: 'Call Price',
        field: 'underlyingCallStrikePrice',
        width: volumeWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Underlying"
    },
    BasisBudgetPrice: {
        colId: 'basis Budget Price',
        headerName: 'Budget Price',
        field: 'basisBudgetPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Basis"
    },
    BasisHedgedVolume: {
        colId: 'basis Hedged Volume',
        headerName: 'Hedged Volume',
        field: 'basisHedgedVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "Basis"
    },
    BasisHedgedPercent: {
        colId: 'basis Hedged Percent',
        headerName: 'Hedged Percent',
        field: 'basisHedgedPercent',
        width: percentWidth,
        cellRenderer: percentCellRenderer,
        parentHeader: "Basis"
    },
    BasisHedgedPrice: {
        colId: 'basis Hedged Price',
        headerName: 'Hedged Price',
        field: 'basisHedgedPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Basis"
    },
    BasisMarketPrice: {
        colId: 'basis Market Price',
        headerName: 'Market Price',
        field: 'basisMarketPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Basis"
    },
    BasisOpenVolume: {
        colId: 'basis Open Volume',
        headerName: 'Open Volume',
        field: 'basisOpenVolume',
        width: volumeWidth,
        cellRenderer: volumeCellRenderer,
        parentHeader: "Basis"
    },
    BasisBlendedPrice: {
        colId: 'basis Blended Price',
        headerName: 'Blended Price',
        field: 'basisBlendedPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Basis"
    },
    BasisBlendedValue: {
        colId: 'basis Blended Value',
        headerName: 'Blended Value',
        field: 'basisBlendedValue',
        width: valueWidth,
        cellRenderer: valueCellRenderer,
        parentHeader: "Basis"
    },
    VarianceToBudgetPrice: {
        colId: 'VTB Price',
        headerName: 'VTB Price',
        width: priceWidth,
        field: 'varianceToBudgetPrice',
        cellRenderer: priceCellRenderer,
        parentHeader: "Variance"
    },
    VarianceToBudgetValue: {
        colId: 'VTB Value',
        headerName: 'VTB Value',
        field: 'varianceToBudgetValue',
        width: valueWidth,
        cellRenderer: valueCellRenderer,
        parentHeader: "Variance"
    },
    VarianceToMarketPrice: {
        colId: 'VTM Price',
        headerName: 'VTM Price',
        field: 'varianceToMarketPrice',
        width: priceWidth,
        cellRenderer: priceCellRenderer,
        parentHeader: "Variance"
    },
    VarianceToMarketValue: {
        colId: 'VTM Value',
        headerName: 'VTM Value',
        field: 'varianceToMarketValue',
        width: valueWidth,
        cellRenderer: valueCellRenderer,
        parentHeader: "Variance"
    },
    // BudgetValue: undefined,
    // LocationHierarchy: undefined,
    // RowBudgetVolume: undefined,
    // RowBudgetPrice: undefined,
    // RowHedgedVolume: undefined,
    // RowHedgedPercent: undefined,
    // RowHedgedPrice: undefined,
    // RowMarketPrice: undefined,
    // RowOpenVolume: undefined,
    // RowBlendedPrice: undefined,
    // EffectiveBudgetVolume: undefined,
    // BlendedValue: undefined,
    // MarketValue: undefined
}

export type VarianceColumn = keyof VarianceReportRow;

// export type GroupByOption = 'commodity' | 'location' | 'tiers' | 'budgetMonth' | 'components';

export type SeriesOption = 'budgetValue' | 'marketValue' | 'blendedValue' | 'budgetPrice' | 'marketPrice' | 'blendedPrice';

export type FixedCallSeriesOption = 'underlyingCallHedgedVolume' | 'underlyingFixedHedgeVolume' | 'strikePrice' | 'marketPrice' | 'blendedPrice';

export type ChartTypes = 'Variance' | 'Fixed/Call Coverage';
