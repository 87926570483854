import { Add, Close, Insights } from '@mui/icons-material';
import { Button, ClickAwayListener, IconButton, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import React, { ReactElement, useMemo, useRef, useState } from 'react';
import { CommodityManagementSiteAccessModel, CustomerReportModel, GetCustomerDataResponse, GetCustomerSettingsResponse, ReportType } from '../../../../Generated/Commodity-Management-Api';
import { useMtmDate } from '../../../../Hooks';
import { RootState, useApplicationSelector } from '../../../../Redux/ReduxStore';
import { StoneXRow, StoneXTab, StoneXTabs } from '../../../StoneX';
import StoneXMainPage from '../../../StoneX/StoneXMainPage/StoneXMainPage';
import CoverageReport from './Reports/Coverage/CoverageReport';
import { CoverageReportConfiguration } from './Reports/Coverage/Types';
import { PositionReport } from './Reports/PositionReport/PositionReport';
import { ProfitLossReport } from './Reports/ProfitLoss/ProfitLossReport';
import { VarianceColumn, VarianceReportConfiguration } from './Reports/Variance/Types';
import VarianceReport from './Reports/Variance/VarianceReport';
import ScenarioReport from './Reports/Scenario/ScenarioReport';
import TimeTriggerReport from './Reports/TimeTrigger/TimeTriggerReport';

interface Report extends CustomerReportModel {
  report: ReactElement;
}

const reportPageStyle = {
  margin: '20px',
};

export default function ReportsPage() {
  const customerData = useApplicationSelector((state: RootState): GetCustomerDataResponse => state.commodityManagement.customerData);
  const customerSettings = useApplicationSelector((state: RootState): GetCustomerSettingsResponse => state.commodityManagement.customerSettings);
  const customerReports = useApplicationSelector((state: RootState): CustomerReportModel[] => state.commodityManagement.customerReports);
  const activeSite = useApplicationSelector((state: RootState): CommodityManagementSiteAccessModel => state.activeSite.site);
  
  const quoteDate = useMtmDate();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [activeReports, setActiveReports] = useState<Report[]>([]);

  const buttonRef = useRef<HTMLDivElement>(null);
  const [ open, setOpen ] = useState(false);

  function handleTabChange(newIndex: number) {
    setActiveTabIndex(newIndex);
  }

  function handleTabClose(event: any, indexToClose: number) {
    event.stopPropagation(); 

    const lastIndex = activeReports.length > 0 ? activeReports.length - 1 : 0;

    setActiveReports(activeReports.filter((report, i) => i !== indexToClose));

    if (activeReports.length > 1) {
      if (indexToClose == lastIndex) {
        setActiveTabIndex(indexToClose - 1);
      } 
      else if (indexToClose < activeTabIndex) {
        setActiveTabIndex(activeTabIndex - 1);
      }
      else if (indexToClose >= activeTabIndex) {
        // Do nothing
      }
    } else {
      setActiveTabIndex(0);
    }

  }

  function addReport(reportType: CustomerReportModel | null) {
    const lastIndex = activeReports.length - 1;

    if (reportType) {
      setActiveReports((previous) => [...previous, getNewReport(reportType)]);
    }

    setActiveTabIndex(lastIndex + 1); // Go to new report
    closeReportSelector();
  }

  function createNewReportElement(customerReport: CustomerReportModel): ReactElement {

    switch (customerReport.reportType) {
      case ReportType.MarkToMarket: return markToMarketReport(customerReport);
      case ReportType.VarianceToBudget: return varianceReport(customerReport);
      case ReportType.Scenario: return scenarioReport(customerReport);
      case ReportType.TimeTrigger: return timeTriggerReport(customerReport);
      case ReportType.ProfitAndLoss: return profitAndLossReport(customerReport);
      default: return <>Error</>;
    }
  }

  function getNewReport(customerReport: CustomerReportModel): Report {
    return {
      customerReportId: customerReport.customerReportId,
      reportType: customerReport.reportType,
      reportName: customerReport.reportName,
      configuration: customerReport.configuration,
      userOverrides: customerReport.userOverrides,
      report: createNewReportElement(customerReport),
    };
  }

  function markToMarketReport(customerReport: CustomerReportModel) {    
    return <PositionReport customerName={activeSite.siteName ?? undefined} customerReport={customerReport} customerData={customerData} customerSettings={customerSettings} quoteDate={quoteDate} />;
    }
    function profitAndLossReport(customerReport: CustomerReportModel) {
        return <ProfitLossReport customerName={activeSite.siteName ?? undefined} customerReport={customerReport} customerData={customerData} customerSettings={customerSettings} quoteDate={quoteDate} />;
    }

  function varianceReport(customerReport: CustomerReportModel) {
    return <VarianceReport customerName={activeSite.siteName ?? undefined} customerReport={customerReport} customerData={customerData} customerSettings={customerSettings} quoteDate={quoteDate} />
  }

  // function coverageReport(configuration: string) {
  //   const reportConfiguration = JSON.parse(configuration!) as CoverageReportConfiguration;
  //   return <CoverageReport customerData={customerData} customerSettings={customerSettings} quoteDate={quoteDate} reportConfiguration={reportConfiguration}/>
  // }

  function scenarioReport(customerReport: CustomerReportModel) {
    return <ScenarioReport customerName={activeSite.siteName ?? undefined} customerReport={customerReport} customerData={customerData} customerSettings={customerSettings} quoteDate={quoteDate} />
  }

  function timeTriggerReport(customerReport: CustomerReportModel) {
    return <TimeTriggerReport customerName={activeSite.siteName ?? undefined} customerReport={customerReport} customerData={customerData} customerSettings={customerSettings} quoteDate={quoteDate} />
  }
    
  function openReportSelector() {
    setOpen(true);
  };
  
  function closeReportSelector() {
    setOpen(false);
  };

  return (
    <StoneXMainPage>
      <StoneXRow>
        <h1>Reports</h1>
      </StoneXRow>

      <StoneXTabs activeTabIndex={activeTabIndex}>
        {activeReports.map((report, i) => {return <StoneXTab key={i} onClick={handleTabChange}>{report.reportName}<IconButton sx={{marginLeft: '5px'}} size='small' onClick={(event: any) => handleTabClose(event, i)}><Close fontSize="inherit" /></IconButton></StoneXTab>;})}
        <StoneXTab key={-1} ref={buttonRef} onClick={openReportSelector}><IconButton><Add fontSize="medium" /></IconButton></StoneXTab>
      </StoneXTabs>

      <Popper open={open} anchorEl={buttonRef.current} placement="bottom-start" >
        <ClickAwayListener onClickAway={closeReportSelector}>
          <Paper>
            <MenuList autoFocusItem={open}>
              {customerReports.map(x => <MenuItem key={x.customerReportId} onClick={() => addReport(x)}>{x.reportName}</MenuItem>)}
            </MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>

      <>
        {activeReports.map((report, i) => {return <div key={i} style={{  display: i == activeTabIndex ? 'inherit' : 'none', ...reportPageStyle }}>{report.report}</div>;})}
      </>

      {activeReports.length == 0 && <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column', alignItems: 'center', justifyContent: 'center', marginTop: '150px'}}>
          <StoneXRow>
            <Insights sx={{fontSize: '4em', color: 'var(--dark-secondary-blue)'}}/>
        </StoneXRow>
        <StoneXRow>
          <h1>Reports</h1>
        </StoneXRow>
        <StoneXRow>
          There are no reports to show
        </StoneXRow>
        <StoneXRow>
          <Button onClick={openReportSelector}>Add a report</Button>
        </StoneXRow>
      </div>}
      
    </StoneXMainPage>
  );
}
