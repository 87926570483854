import { Route, Routes } from 'react-router';
import Commodities from './Commodities/Commodities';
import AdminNavigation from './Navigation/AdminNavigation';
import Sites from './Sites/Sites';
import Users from './Users/Users';

interface AdminSolutionProps {
}

export default function AdminSolution(props: AdminSolutionProps) {
  return (
    // Setting the site's guid as the key will force the component to reload when the site changes
    <div style={{ width: '100%', height: '100%' }}>
      <Routes>
        <Route path="users" element={<Users />} />
        <Route path="sites" element={<Sites />} />
        <Route path="commodities" element={<Commodities />} />
      </Routes>
    </div>
  );
}
