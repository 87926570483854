import { Button,ButtonGroup } from '@mui/material';
import { ColDef, ISelectCellEditorParams,ValueGetterParams,ValueSetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React,{ useEffect,useMemo,useState } from 'react';
import { LocationModel,TierModel } from '../../../../Generated/Commodity-Management-Api';
import { StoneXRow } from '../../../StoneX';
import { StoneXModal,StoneXSelect,StoneXTextField } from '../../../StoneXMui';

interface OrganizationSettingsProps {
  tiers: TierModel[];
  locations: LocationModel[];
}

export default function OrganizationSettings(props: OrganizationSettingsProps) {
  const { tiers, locations } = props;

  const [locationModalOpen, setLocationModalOpen] = useState<boolean>(false);
  const [tierModalOpen, setTierModalOpen] = useState<boolean>(false);
  const [modalTier, setModalTier] = useState<TierModel | undefined>();
  const [modalLocation, setModalLocation] = useState<LocationModel | undefined>();

  const defaultColumnDefs = {
    suppressMenu: true,
  };

  const columnDefsTier: ColDef[] = [
    {
      headerName: 'Number',
      field: 'number',
      sortable: true,
      width: 100,
    },
    {
      headerName: 'Tier Name',
      field: 'tierName',
      sortable: true,
      editable: true,
      flex: 1,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 160,
      cellRenderer: (params: any) => (
        <ButtonGroup variant="text">
          <Button onClick={() => updateTier(params.data)}>Edit</Button>
          <Button>Delete</Button>
        </ButtonGroup>
      ),
    },
  ];

  function getColumnDefs(tier: TierModel): ColDef[] {
    return [
      {
        headerName: 'Location Name',
        field: 'locationName',
        sortable: true,
        editable: true,
        flex: 1,
      },
      {
        headerName: `${tier.tierName}`,
        field: 'parentLocationId',
        valueGetter: (params: ValueGetterParams) => locations.find((location) => location.locationId == params.data.parentLocationId)?.locationName,
        valueSetter: (params: ValueSetterParams) => {
          const newLocation = locations.find((locaiton) => locaiton.locationName == params.newValue);
          return false;
        },
        sortable: true,
        editable: true,
        hide: tier.tierRank == 1,
        flex: 1,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: locations
            .filter((location) => location.tierId == tiers.find((t) => t.tierRank == (tier.tierRank ?? 0) - 1)?.tierId)
            .map((location) => location.locationName),
        } as ISelectCellEditorParams,
      },
      {
        headerName: 'Actions',
        width: 160,
        cellRenderer: (params: any) => (
          <ButtonGroup variant="text">
            <Button onClick={() => updateLocation(tier, params.data)}>Edit</Button>
            <Button>Delete</Button>
          </ButtonGroup>
        ),
      },
    ];
  }

  function updateTier(tier: TierModel) {
    setModalTier(tier);
    setTierModalOpen(true);
  }

  function addLocation(tier: TierModel) {
    setModalTier(tier);
    setModalLocation(undefined);
    setLocationModalOpen(true);
  }

  function updateLocation(tier: TierModel, location: LocationModel) {
    setModalTier(tier);
    setModalLocation(location);
    setLocationModalOpen(true);
  }

  return (
    <>
      <StoneXRow>
        <h3>Tiers</h3>
        {/* <Button className="pull-right" variant="outlined" onClick={addTier}>
          Add
        </Button> */}
      </StoneXRow>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact enableRangeSelection columnDefs={columnDefsTier} domLayout="autoHeight" rowData={tiers} defaultColDef={defaultColumnDefs} />
      </div>
      <UpdateTierModal open={locationModalOpen} onClose={() => setLocationModalOpen(false)} onTierUpdated={() => {}} tier={modalTier} />

      {tiers
        .filter((q) => q.tierRank != 1)
        .map((tier) => {
          return (
            <>
              <StoneXRow align="end">
                <h3>{tier.tierName}</h3>
                <Button className="pull-right" variant="outlined" onClick={() => addLocation(tier)}>
                  Add
                </Button>
              </StoneXRow>
              <div className="ag-theme-alpine" style={{ width: '100%' }}>
                <AgGridReact
                  enableRangeSelection
                  editType="fullRow"
                  columnDefs={getColumnDefs(tier)}
                  domLayout="autoHeight"
                  rowData={locations.filter((location) => location.tierId == tier.tierId)}
                  defaultColDef={defaultColumnDefs}
                />
              </div>
            </>
          );
        })}

        <UpdateTierModal 
          open={tierModalOpen}
          onClose={() => setTierModalOpen(false)}
          onTierUpdated={() => {}}
          tier={modalTier}
        />

      <UpdateLocationModal
        open={locationModalOpen}
        onClose={() => setLocationModalOpen(false)}
        location={modalLocation}
        onLocationUpdated={() => {}}
        locations={locations}
        tier={modalTier!}
        tiers={tiers}
      />
    </>
  );
}

function UpdateTierModal(props: { open: boolean; onClose: () => void; onTierUpdated: (tier: TierModel) => void; tier?: TierModel }) {
  const { open, onClose, onTierUpdated, tier } = props;

  const action = props.tier ? 'Update' : 'Add';
  const [tierName, setTierName] = useState<string>();

  useEffect(() => {
    setTierName(tier?.tierName ?? '')
  }, [tier])

  return (
    <StoneXModal header={`${action} Tier`} open={open} onClose={onClose}>
      <StoneXRow>
        <StoneXTextField label="Tier Name" value={tierName} onChange={(event: any) => setTierName(event.target.value)} />
      </StoneXRow>

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button>{`${action} Tier`}</Button>
      </StoneXRow>
    </StoneXModal>
  );
}

interface UpdateLocationModalProps {
  open: boolean;
  onClose: () => void;
  onLocationUpdated: (location: LocationModel) => void;
  location?: LocationModel;
  locations: LocationModel[];
  tier: TierModel;
  tiers: TierModel[];
}

function UpdateLocationModal(props: UpdateLocationModalProps) {
  const { open, onClose, onLocationUpdated, location, locations, tier, tiers } = props;

  const action = props.location ? 'Update' : 'Add';

  const [selectedTier, setSelectedTier] = useState<TierModel | null>();
  const [selectedParentLocation, setSelectedParentLocation] = useState<LocationModel | null>();
  const [locationName, setLocationName] = useState<string | undefined>();

  useEffect(() => {
    if (location) {
      setSelectedTier(tier);
      setSelectedParentLocation(locations.find((q) => q.locationId == location?.parentLocationId)!);
      setLocationName(location?.locationName);
    } else {
      setSelectedTier(tier);
      setSelectedParentLocation(null);
      setLocationName("");
    }
  }, [location, tier])

  const parentTier = useMemo(() => tiers.find((tier) => tier.tierRank === (selectedTier?.tierRank ?? 0) - 1), [selectedTier]);


  return (
    <StoneXModal header={`${action} Location`} open={open} onClose={onClose}>
      <StoneXRow>
        <StoneXSelect
          label="Tier"
          value={selectedTier}
          options={tiers}
          getId={(tier) => tier.tierId!}
          getOptionLabel={(tier) => tier.tierName!}
          onChange={(tier: TierModel | null) => setSelectedTier(tier!)}
        />

        {parentTier && (
          <StoneXSelect
            label={parentTier.tierName!}
            value={selectedParentLocation}
            options={locations.filter(q => q.tierId == parentTier.tierId)}
            getId={(location) => location.locationId}
            getOptionLabel={(location) => location.locationName}
            onChange={(location: LocationModel | null) => setSelectedParentLocation(location!)}
          />
        )}

        <StoneXTextField label="Location Name" value={location?.locationName} onChange={(event: any) => setLocationName(event.target.value)}/>
      </StoneXRow>

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button>{`${action} Location`}</Button>
      </StoneXRow>
    </StoneXModal>
  );
}