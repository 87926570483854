import { CellClassParams, IAggFuncParams, ICellRendererParams, ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import dayjs from 'dayjs';
import { Currency, ExpiryStatus, PositionModel } from '../../../../../../Generated/Commodity-Management-Api';
import Formatting from '../../../../../../Helpers/Formatting';
import { GridRow, GroupingOption } from '../../../../../../Types';
import { StoneXNumber } from '../../../../../StoneXMui';

// Position Report Types
export interface PositionReportConfiguration {
  inputs: {
    quoteDate?: {
      show: boolean;
    };
    budgetStartDate?: {
      show: boolean;
    };
    budgetEndDate?: {
      show: boolean;
    };
  };
  behavior?: {
    expiryStatus?: ExpiryStatus;
    netOptionPremiums?: boolean;
    grouping?: GroupingOption[];
  };
  columns: {
    available: PositionReportColumn[];
    active: PositionReportColumn[];
  };
};

export interface PositionReportUserOverride {
  behavior?: {
    grouping?: GroupingOption[];
  },
  columns?: {
    active?: PositionReportColumn[];
  };
}

export interface PositionReportRow extends GridRow, Partial<PositionModel> {}

interface PositionColumnConfiguration {
  colId: string;
  headerName?: string; // This is what appears on the table header
  columnName?: string; // This is a unique column name to distinguish from other versions of the same column
  parentHeader?: string;
  field?: string;
  width?: number;
  maxWidth?: number;
  sortable?: boolean;
  flex?: number;
  hide?: boolean;
  filter?: string;
  cellClass?: string | string[] | ((params: CellClassParams<PositionReportRow, any>) => string | string[] | undefined);
  aggFunc?: string | ((params: IAggFuncParams<PositionReportRow, any>) => any) | null | undefined;
  valueFormatter?: (params: ValueFormatterParams<PositionReportRow>) => string | ValueFormatterFunc<PositionReportRow, any>;
  cellRenderer?: (params: ICellRendererParams<PositionReportRow>) => any;
}

export type PositionReportColumn = 'BudgetMonth' | 'TradeDate' | 'Commodity' | 'Counterparty' | 'Portfolio' | 'ContractMonth' | 'Side' | 'DealTicket' | 'Volume' | 'Currency' | 'TradePrice' | 'PositionType' | 'PositionTypeOrOption' | 'OptionType' | 'OptionStyle' | 'OptionStrike' | 'ScenarioMarketPrice' | 'ScenarioMtmPrice' | 'ScenarioMtmValue' | 'MarketPrice' | 'MtmPrice' | 'MtmValue';

export const PositionColumns: {[key in PositionReportColumn]: PositionColumnConfiguration} = {
  BudgetMonth: {
    colId: 'Budget Month',
    headerName: 'Budget Month',
    field: 'budgetDate.label',
    filter: 'agDateColumnFilter',
    maxWidth: 150,
  },
  Commodity: {
    colId: 'Commodity',
    headerName: 'Commodity',
    field: 'commodity.commodityName',
    filter: 'agSetColumnFilter',
    width: 150,
    sortable: true,
  },
  TradeDate: {
    colId: 'Trade Date',
    headerName: 'Trade Date',
    field: 'tradeDate',
    filter: 'agDateColumnFilter',
    valueFormatter: (params: ValueFormatterParams<PositionReportRow>) => params.node?.allChildrenCount ?? 0 > 0 ? '' : dayjs(params.value).format('L'),
    maxWidth: 150,
  },
  Counterparty: {
    colId: 'Counterparty',
    headerName: 'Counterparty',
    field: 'counterparty.counterpartyName',
    filter: 'agSetColumnFilter',
    sortable: true,
    width: 150,
  },
  Portfolio: {
    colId: 'Portfolio',
    headerName: 'Portfolio',
    field: 'portfolio.portfolioName',
    filter: 'agSetColumnFilter',
    sortable: true,
    width: 150,
  },
  ContractMonth: {
    colId: 'Contract Month',
    headerName: 'Contract Month',
    field: 'contractDate.label',
    filter: 'agDateColumnFilter',
    sortable: true,
    maxWidth: 150,
  },
  Side: {
    colId: 'Side',
    headerName: 'Side',
    field: 'side',
    filter: 'agSetColumnFilter',
    sortable: true,
    maxWidth: 100
  },
  DealTicket: {
    colId: 'Deal Ticket',
    headerName: 'Deal Ticket',
    field: 'dealTicketNumber',
    filter: 'agTextColumnilter',
    sortable: true,
    width: 200,
  },
  Volume: {
    colId: 'Volume',
    headerName: 'Volume',
    field: 'volume',
    filter: 'agNumberColumnFilter',
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) => params.node.allChildrenCount ?? 0 > 0 ? null : <StoneXNumber number={params.value}></StoneXNumber>,
    sortable: true,
    maxWidth: 150
  },
  Currency: {
    colId: 'Currency',
    headerName: 'Currency',
    field: 'currency',
    filter: 'agSetColumnFilter',
    sortable: true,
    width: 110
  },
  TradePrice: {
    colId: 'Trade Price',
    headerName: 'Trade Price',
    field: 'price',
    filter: 'agNumberColumnFilter',
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) => params.node.allChildrenCount ?? 0 > 0 ? null : <StoneXNumber number={params.value} currency={Currency.Usd} decimals={params.data?.commodity?.displayPrecision}></StoneXNumber>,
    maxWidth: 150
  },
  PositionType: {
    colId: 'Position Type',
    headerName: 'Position Type',
    field: 'positionType',
    filter: 'agSetColumnFilter',
    sortable: true,
    maxWidth: 150
  },
  PositionTypeOrOption: {
    colId: 'Position/Option Type',
    headerName: 'Position Type',
    columnName: 'Position/Option Type',
    field: 'positionTypeOrOption',
    filter: 'agSetColumnFilter',
    sortable: true,
    maxWidth: 150
  },
  OptionType: {
    colId: 'Option Type',
    headerName: 'Option Type',
    field: 'optionType',
    valueFormatter: (params: ValueFormatterParams<PositionReportRow>) => params.value ? params.value : '',
    filter: 'agSetColumnFilter',
    sortable: true,
    maxWidth: 150
  },
  OptionStyle: {
    colId: 'Option Style',
    headerName: 'Option Style',
    field: 'optionStyle',
    valueFormatter: (params: ValueFormatterParams<PositionReportRow>) => params.value ? params.value : '',
    filter: 'agSetColumnFilter',
    sortable: true,
    maxWidth: 150
  },
  OptionStrike: {
    colId: 'Option Strike',
    headerName: 'Option Strike',
    field: 'optionStrike',
    filter: 'agNumberColumnFilter',
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) => params.node.allChildrenCount ?? 0 > 0 ? null : <StoneXNumber number={params.value} currency={Currency.Usd} decimals={params.data?.commodity?.displayPrecision}></StoneXNumber>,
    sortable: true,
    maxWidth: 150
  },
  ScenarioMarketPrice: {
    colId: 'Scenario Market Price',
    headerName: 'Scenario Market Price',
    field: 'scenarioMarketPrice',
    filter: 'agNumberColumnFilter',
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) => params.node.allChildrenCount ?? 0 > 0 ? null : <StoneXNumber number={params.value} currency={Currency.Usd} decimals={params.data?.commodity?.displayPrecision}></StoneXNumber>,
    sortable: true,
    maxWidth: 150
  },
  ScenarioMtmPrice: {
    colId: 'Scenario Mtm Price',
    headerName: 'Scenario MTM Price',
    field: 'scenarioMarkToMarketPrice',
    filter: 'agNumberColumnFilter',
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) =>  params.node.allChildrenCount ?? 0 > 0 ? null : <StoneXNumber number={params.value} currency={Currency.Usd} decimals={params.data?.commodity?.displayPrecision}></StoneXNumber>,
    sortable: true,
    maxWidth: 150
  },
  ScenarioMtmValue: {
    colId: 'Scenario Mtm Value',
    headerName: 'Scenario MTM Value',
    field: 'scenarioMarkToMarketValue',
    filter: 'agNumberColumnFilter',
    aggFunc: 'sum',
    cellClass: (params: CellClassParams<PositionReportRow, any>) => params.node.rowIndex == params.api.getDisplayedRowCount() - 1 
      ? 'justify-right' 
      : Formatting.getAgGridProfitLossCellClasses(params),
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) => params.node.rowIndex == params.api.getDisplayedRowCount() - 1 
      ? <h3><StoneXNumber number={params.value} currency={Currency.Usd} colored></StoneXNumber></h3> 
      : <StoneXNumber number={params.value} currency={Currency.Usd}></StoneXNumber>,
    sortable: true,
    maxWidth: 200
  },
  MarketPrice: {
    colId: 'Market Price',
    headerName: 'Market Price',
    field: 'marketPrice',
    filter: 'agNumberColumnFilter',
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) => params.node.allChildrenCount ?? 0 > 0 ? null : <StoneXNumber number={params.value} currency={Currency.Usd} decimals={params.data?.commodity?.displayPrecision}></StoneXNumber>,
    sortable: true,
    maxWidth: 150
  },
  MtmPrice: {
    colId: 'Mtm Price',
    headerName: 'MTM Price',  
    field: 'markToMarketPrice',
    filter: 'agNumberColumnFilter',
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) =>  params.node.allChildrenCount ?? 0 > 0 ? null : <StoneXNumber number={params.value} currency={Currency.Usd} decimals={params.data?.commodity?.displayPrecision}></StoneXNumber>,
    sortable: true,
    maxWidth: 150
  },
  MtmValue: {
    colId: 'Mtm Value',
    headerName: 'MTM Value',
    field: 'markToMarketValue',
    filter: 'agNumberColumnFilter',
    aggFunc: 'sum',
    cellClass: (params: CellClassParams<PositionReportRow, any>) => params.node.rowIndex == params.api.getDisplayedRowCount() - 1 
      ? 'justify-right' 
      : Formatting.getAgGridProfitLossCellClasses(params),
    cellRenderer: (params: ICellRendererParams<PositionReportRow>) => params.node.rowIndex == params.api.getDisplayedRowCount() - 1 
      ? <h3><StoneXNumber number={params.value} currency={Currency.Usd} colored></StoneXNumber></h3> 
      : <StoneXNumber number={params.value} currency={Currency.Usd}></StoneXNumber>,
    sortable: true,
    maxWidth: 200
  },
};

