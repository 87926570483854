import { Cancel } from '@mui/icons-material';
import { Box,Chip,FormControl,FormHelperText,InputLabel,ListItemText,MenuItem,MenuProps,OutlinedInput,Select,SelectChangeEvent } from '@mui/material';
import { ReactNode,useEffect,useState } from 'react';
import { StoneXLabeledItem } from '..';

type AllowedValue = string | number;

interface PropsType<TItem, TValue> {
  list: TItem[];
  loading?: () => boolean;
  labelSelector: (q: TItem) => string | number;
  valueSelector: (q: TItem) => string | number;
  autoFocusValue?: string | number;
  resultSetter: (value: TValue[]) => void;
  initial?: TValue[];
  label: string;
  error?: string | undefined;
}

export function StoneXMultiselect<TItem, AllowedValue>(props: PropsType<TItem, AllowedValue>) {
  const itemHeight = 60;
  const itemPaddingTop = 0;

  const MenuProps: Partial<MenuProps> = {
    disableScrollLock: true,
    PaperProps: {
      style: {
        maxHeight: itemHeight * 4.5 + itemPaddingTop,
        width: 250,
      },
    },
  };

  const [selectedItems, setSelectedItems] = useState<AllowedValue[]>([]);

  const onChange = (event: SelectChangeEvent<AllowedValue[]>, child: ReactNode) => {
    const items = (event.target.value as AllowedValue[]).sort();
    setSelectedItems(items);
    props.resultSetter(items);
  };

  const unSelectItem = (value: AllowedValue) => {
    const items = selectedItems.filter((q) => q != value);
    setSelectedItems(items);
    props.resultSetter(items);
  };

  const renderChips = (selectedItems: any[]): ReactNode => {
    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
        {selectedItems &&
          selectedItems
            .map((value: any) => (
              <Chip
                key={value}
                label={props.labelSelector(props.list.find(x => props.valueSelector(x) === value)!)}
                deleteIcon={<Cancel onMouseDown={(event) => event.stopPropagation()} />}
                onDelete={() => unSelectItem(value)}
                clickable
              />
            ))}
      </Box>
    );
  };

  useEffect(() => {
    const items = props.initial ? props.initial.sort() : [];
    setSelectedItems(items);
  }, []);

  const renderMenuItems = () => {
    if (props.list && props.list.length > 0) {
      return props.list.map((item: TItem) => (
        <MenuItem key={props.valueSelector(item)} value={props.valueSelector(item)} autoFocus={props.valueSelector(item) === props.autoFocusValue}>
          {/* <Checkbox checked={selectedYears.indexOf(year) > -1} /> */}
          <ListItemText primary={props.labelSelector(item)} />
        </MenuItem>
      ));
    }

    if (props.loading && props.loading()) {
      return (
        <MenuItem disabled>
          <ListItemText primary="Loading..."></ListItemText>
        </MenuItem>
      );
    }

    return (
      <MenuItem disabled>
        <ListItemText primary="No options"></ListItemText>
      </MenuItem>
    );
  };

  return (
    <StoneXLabeledItem label={props.label}>
        <Select
          multiple
          value={selectedItems}
          onChange={onChange}
          input={<OutlinedInput />}
          renderValue={renderChips}
          MenuProps={MenuProps}
        >
        {renderMenuItems()}
      </Select>
      {props.error && <FormHelperText>{props.error}</FormHelperText>}
    </StoneXLabeledItem>
  );
}
