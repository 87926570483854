import { AdminPanelSettings, Calculate, ChevronRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, Lightbulb, StackedLineChart } from '@mui/icons-material';
import React, { ReactElement, useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { RootState, useApplicationSelector } from '../../Redux/ReduxStore';
import { INavProps } from './InterfaceNav';
import './SidebarNavigation.scss';
import { UserAccessState } from '../../Redux/Slices/UserAccessSlice';
import { MarketViewApplication } from '../../Generated/Mark-It-View-Api';
import { includes } from 'lodash';
import CommodityManagementSiteSelector from '../MainContainer/CommodityManagement/CommodityManagementSiteSelector';

// interface NavigationItem {
//   type: 'Link' | 'Header' | 'HorizontalBreak';
// }

interface NavigationLink {
  type: 'Link' | 'Header' | 'HorizontalBreak';
  url: string;
  label: string;
  end?: boolean;
}

interface NavigationHeader {
  type: 'Link' | 'Header' | 'HorizontalBreak';
  headerName: string;
}

interface NavigationHorizontalBreak {
  type: 'Link' | 'Header' | 'HorizontalBreak';
}

interface NavigationItemGroup {

}

interface NavigationItem2 {

}

type NavigationItem = NavigationLink | NavigationHeader | NavigationHorizontalBreak;

export const SidebarNavigation: React.FC<INavProps> = () => {
  const access = useApplicationSelector((state: RootState): boolean => state.userAccess.hasAccess);
  const [open, setOpen] = useState<boolean | undefined>();
  const toggleOpen = () => setOpen(!open);

  const userAccess = useApplicationSelector((state: RootState): UserAccessState => state.userAccess);
  const userHasCommodityMangementSite = () => userAccess.commodityManagementUserSiteAccesses?.length > 0;
  const userHasApplication = (application: MarketViewApplication) => includes(userAccess.marketViewApplications, application);
  const userIsAdmin = () => userAccess.isAdmin;

  const navigate = useNavigate();
  const [navigationItems, setNavigationItems] = useState<NavigationItem[]>([]);


  function renderNavigationItem(item: NavigationItem, index: number) {

    if (item.type == 'Link') {
      const link = item as NavigationLink;
      return <li key={index}>
        <NavLink end={link.end } className={(props) => props.isActive ? 'nav-link active' : 'nav-link'} to={link.url}>
        <span className="aside-text">{link.label}</span>
      </NavLink>
      </li>
    }

    if (item.type == 'Header') {
      const header = item as NavigationHeader;
      return <li key={index}><span className='nav-header'>{header.headerName}</span></li>
    }

    if (item.type == 'HorizontalBreak') {
      const header = item as NavigationHeader;
      return <hr key={index}/>
    }

  }
 
  useEffect(() => {
    const items: NavigationItem[] = [];

    if (userHasCommodityMangementSite()) {
      // items.push({ url: '/commodity-management', icon: <Lightbulb />, label: 'Know-Risk' });
      items.push({ type: 'Header', headerName:'Commodity Management'})
      items.push({ type: 'Link', url: '/commodity-management/reports', label: 'Reports' });
      items.push({ type: 'Link', url: '/commodity-management/budgets', label: 'Budgets' });
      items.push({ type: 'Link', url: '/commodity-management/positions', label: 'Positions', end: true });
      items.push({ type: 'Link', url: '/commodity-management/positions/update', label: 'Add Positions', end: true });
      items.push({ type: 'Link', url: '/commodity-management/notes', label: 'Notes' });
      items.push({ type: 'Link', url: '/commodity-management/settings', label: 'Settings' });
      items.push({ type: 'HorizontalBreak'})
    }

    if (userHasApplication(MarketViewApplication.MarketView)) {
      items.push({ type: 'Header', headerName:'Tools'})
      items.push({type: 'Link', url: '/market-view', label: 'Mark-It-View' });
    }

    if (userHasApplication(MarketViewApplication.Deciles)) {
      items.push({type: 'Link', url: '/deciles', label: 'Deciles' });
    }

    if (userIsAdmin()) {
      items.push({ type: 'HorizontalBreak'})
      items.push({ type: 'Header', headerName:'Administration'})
      items.push({type: 'Link', url: '/admin/sites', label: 'Sites' });
      items.push({type: 'Link', url: '/admin/users', label: 'Users' });
      items.push({type: 'Link', url: '/admin/commodities', label: 'Commodities' });
    }

    setNavigationItems(items);

    // If user has only one application, automatically go to it
    if (items.length == 1) {
      // navigate(items[0].url)
    }

  }, [userAccess]);

  useEffect(() => {
    if (open != undefined) {
      localStorage.setItem('isSidebarOpen', JSON.stringify(open));
    }
  }, [open]);

  useEffect(() => {
    const isSidebarOpenString = localStorage.getItem('isSidebarOpen');
    const isSidebarOpen = isSidebarOpenString ? JSON.parse(isSidebarOpenString) : false;
    setOpen(isSidebarOpen);
  }, []);

  if (!access || navigationItems?.length <= 1) {
    return <></>;
  }

  return (
    <aside id="menu" className={open ? '' : 'collapsed'}>
      <ul>
        <span style={{margin: 10}}>
          <CommodityManagementSiteSelector />
        </span>
        <br />
        {navigationItems?.map((item, index) => renderNavigationItem(item, index))}
      </ul>
    </aside>
  );
};

export default SidebarNavigation;
