import { Close } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import { CellFocusedEvent, ColDef, ICellRendererParams, ProcessCellForExportParams, SuppressKeyboardEventParams, ValueSetterParams } from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { toNumber } from 'lodash';
import { useSnackbar } from 'notistack';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { CommodityModel, CounterpartyModel, Currency, GetCustomerDataResponse, GetCustomerSettingsResponse, OptionStyle, OptionType, PortfolioModel, PositionType, Side, Unit, UpdateDealTicketRequest, UpdatePositionModel } from '../../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../../Hooks';
import { UpdateDealTicketFormSchema } from '../../../../../Validations';
import { StoneXDivider, StoneXRow } from '../../../../StoneX';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXAutocomplete, StoneXDatePicker, StoneXLabeledItem, StoneXMonthDropdown, StoneXNumber, StoneXSelect, StoneXTextField } from '../../../../StoneXMui';
import { PositionRow } from './PositionTypes';

interface AveragePosition {
  unit?: Unit;
  currency?: Currency;
  volume?: number;
  price?: number;
}

export default function UpdatePositions() {
  const gridRef = useRef<AgGridReact<PositionRow>>(null);

  const dealTicketLoadingState = useLoadingState();
  const { enqueueSnackbar } = useSnackbar();

  const totalVolumeRef = useRef<HTMLDivElement>(null);
  const averagePriceRef = useRef<HTMLDivElement>(null);

  const customerDataLoadingState = useLoadingState();
  const [customerData, setCustomerData] = useState<GetCustomerDataResponse>();
  const [customerSettings, setCustomerSettings] = useState<GetCustomerSettingsResponse>({});

  const [dealTicketId, setDealTicketId] = useState<number>(0);
  const [selectedCommodity, setSelectedCommodity] = useState<CommodityModel | null>(null);
  const [selectedCounterparty, setSelectedCounterparty] = useState<CounterpartyModel | null>(null);
  const [selectedPortfolio, setSelectedPortfolio] = useState<PortfolioModel | null>(null);
  const [dealTicketNumber, setDealTicketNumber] = useState<string>('');

  const [addPositionCountOptions, setAddPositionCountOptions] = useState<number[]>([1, 6, 12]);

  const [positions, setPositions] = useState<PositionRow[]>([]);

  const [rowIdCounter, setRowIdCounter] = useState<number>(1);

  const [lastInteraction, setLastInteraction] = useState<'mouse' | 'keyboard' | null>(null);
  const [average, setAverage] = useState<AveragePosition>();

  const colIdToValueGetter: {[key: string]: (row: PositionRow) => any} = {
    'Side': (row: PositionRow) => row.side,
    'Position Type': (row: PositionRow) => row.positionType,
    'Budget Year': (row: PositionRow) => row.budgetDate?.year,
    'Budget Month': (row: PositionRow) => row.budgetDate?.month,
    'Contract Year': (row: PositionRow) => row.contractDate?.year,
    'Contract Month': (row: PositionRow) => row.contractDate?.month,
    'Option Type': (row: PositionRow) => row.optionType,
    'Option Style': (row: PositionRow) => row.optionStyle,
    'Option Strike': (row: PositionRow) => row.optionStrike,
    'Quantity': (row: PositionRow) => row.volume,
    'Price': (row: PositionRow) => row.price,
    'Unit': (row: PositionRow) => row.unit,
    'Currency': (row: PositionRow) => row.currency,
  };

  // const [formErrorsHashmap, setFormErrorsHashmap] = useState<{ [key: string]: string }[]>([]);

  useEffect(getDealTicketFromSearchParamaters, []);

  const updatePosition = <K extends keyof PositionRow, V extends PositionRow[K]>(index: number, key: K, value: V): void => {
    setPositions((previous) =>
      previous.map((row, i) => {
        if (i == index) {
          return { ...row, [key]: value };
        }

        return row;
      }),
    );
  };

  useEffect(() => {
    CommodityManagementApi.getCustomerData(customerDataLoadingState.setLoadingState).then((q) => setCustomerData(q.data));
    CommodityManagementApi.getCustomerSettings(customerDataLoadingState.setLoadingState).then((q) => setCustomerSettings(q.data));
  }, []);

  const [tradeDate, setTradeDate] = useState<Date | null>(dayjs().toDate());
  const onTradeDateChange = (value: Date | null): void => setTradeDate(value);

  const addNewPosition = (count: number): void => {
    let currentId = rowIdCounter;
    let newPositions: PositionRow[] = [];

    for (let i = 0; i < count; i++) {
      const newPosition = { 
        rowId: (currentId++).toString(),
        price: null,
        volume: null
      };
      newPositions.push(newPosition);
    }

    setRowIdCounter(currentId);
    setPositions((previous) => [...previous, ...newPositions]);
  };

  const removePosition = (rowId: string) => {
    setPositions(previous => previous.filter(item => item.rowId !== rowId));
  }

  function onCellUpdated(params: any) {
    console.log('on cell updated', params.colDef.colId);
    if (params.colDef.colId === 'Position Type') {
      // Check if any row has positionType equal to PositionType.Option
      const hasOption = positions.some(x => x.positionType === PositionType.Option);
  
      console.log('Setting columns visibility based on hasOption:', hasOption);
  
      // Use setColumnsVisible to set visibility for multiple columns
      const columnsToToggle = ['optionType', 'optionStyle', 'optionStrike'];
  
      gridRef?.current?.api?.setColumnsVisible(columnsToToggle, hasOption);
  
      // Refresh the affected rows if needed
      const rowNode = gridRef?.current?.api.getDisplayedRowAtIndex(params.rowIndex);
      if (rowNode) {
        gridRef?.current?.api.refreshCells({
          rowNodes: [rowNode], // Array of row nodes to refresh
          columns: columnsToToggle, // Array of columns to refresh
          force: true, // Force the refresh if necessary
        });
        gridRef?.current?.api?.refreshHeader();
      }
    }
  }

  function onCellFocused(cellEvent: CellFocusedEvent) {

    const cell = document.querySelector(
      `.ag-row[row-index="${cellEvent.rowIndex}"] .ag-cell[col-id="${(cellEvent.column as any).getColId()}"]`
    );

    const element = cell?.querySelector('.MuiSelect-select, .MuiInputBase-input');

    if (!element) {
      return;
    }

    if (element.classList.contains('MuiSelect-select')) {
      (element as HTMLElement).focus();
      
      // Don't auto focus if the cell was just clicked on, it can get annoying for user
      // if (lastInteraction === 'keyboard') {
      //   const event = new KeyboardEvent('keydown', {
      //     key: 'Enter',
      //     code: 'Enter',
      //     keyCode: 13,
      //     bubbles: true,
      //   });
      // element.dispatchEvent(event);
      // }
      
    } else {
      (element as HTMLElement).focus();
    }

  }

  function getDealTicketFromSearchParamaters() {
    const dealTicketId = new URLSearchParams(location.search).get('dealTicketId');

    if (dealTicketId) {
      CommodityManagementApi.getDealTicket(dealTicketLoadingState.setLoadingState, dealTicketId).then((res) => {
        const dealTicket = res.data.dealTicket!;

        setDealTicketId(dealTicket.dealTicketId);
        setTradeDate(dayjs(dealTicket.tradeDate).toDate());
        setSelectedCommodity(dealTicket.commodity);
        setDealTicketNumber(dealTicket.dealTicketNumber ?? '');

        if (dealTicket.portfolio) {
          setSelectedPortfolio(dealTicket.portfolio);
        }

        if (dealTicket.counterparty) {
          setSelectedCounterparty(dealTicket.counterparty);
        }

        let currentId = rowIdCounter;
        const positions = dealTicket.positions.map((x) => ({ ...x, rowId: (currentId++).toString() }));
        setRowIdCounter(currentId);

        setPositions(positions);
      });
    }
  }

  function getPositionsFromAgGrid() {
    const gridApi = gridRef!.current!.api;
    const positions: PositionRow[] = [];

    gridApi.forEachNode((node: any) => {
      positions.push(node.data);
    });

    return positions;
  }

  function validateDealTicket(dealTicket: UpdateDealTicketRequest): boolean {
    const errors: { [key: string]: string }[] = [];

    try {
      UpdateDealTicketFormSchema.validateSync(dealTicket, {
        context: { customerSettings: customerSettings },
        abortEarly: false,
      });
    } catch (err: any) {
      err.inner.forEach((e: any) => {
        // if (!errors[index]) {
        //   errors[index] = {};
        // }

        // errors[index][e.path] = e.message;
        console.log(e.path, e.message);
      });
    }

    // setFormErrorsHashmap(errors);

    return !!errors;
  }

  function updatePositions() {
    // const positions = getPositionsFromAgGrid();

    const dealTicket = {
      dealTicketId: dealTicketId,
      tradeDate: dayjs(tradeDate).format(),
      commodityId: selectedCommodity?.commodityId,
      counterpartyId: selectedCounterparty?.counterpartyId,
      portfolioId: selectedPortfolio?.portfolioId,
      dealTicketNumber: dealTicketNumber,
      positions: positions.map((q) => {
        console.log(q);
        return {
          positionId: q.positionId,
          locationId: q.location?.locationId,
          budgetDate: q.budgetDate,
          contractDate: q.contractDate,
          price: q.price ? toNumber(q.price) : null,
          volume: q.volume ? toNumber(q.volume) : null,
          positionType: q.positionType,
          optionType: q.optionType,
          optionStrike: q.optionStrike ? toNumber(q.optionStrike) : null,
          optionStyle: q.optionStyle,
          side: q.side,
          unit: q.unit,
          currency: q.currency,
        } as UpdatePositionModel;
      }),
    } as UpdateDealTicketRequest;

    const dealTicketIsValid = validateDealTicket(dealTicket);
    console.log('dealTicketIsValid', dealTicketIsValid);

    if (!dealTicketIsValid) {
      return;
    }

    CommodityManagementApi.addTrade(null, dealTicket)
      .then(res => enqueueSnackbar('Dealticket was successfully updated', {variant: 'success'}))
      .catch(res => enqueueSnackbar('An error occured while saving the dealticket', {variant: 'error'}));
  }

  function processCellForClipboard(params: ProcessCellForExportParams) {
    // Copying data
    // console.group('Copying start');
    
    const colId = params.column.getColId();
    const row = positions.find(x => x.rowId == params.node?.data.rowId);
    
    // Handle columns that have a direct mapping.
    const valueGetter = colIdToValueGetter[colId];

    if (!valueGetter || !row) {
      // console.groupEnd();
      return;
  }

    const value = valueGetter(params.node?.data);

    // console.log(`Copying ${value} for ${colId}`);

    // console.groupEnd();

    return value;
  }

  function processCellFromClipboard(params: ProcessCellForExportParams) {
      // Pasting data
      
      const colId = params.column.getColId();
      // const row = positions.find(x => x.rowId == params.node?.data.rowId);
      
      // console.log(`Pasting ${params.value} for ${colId}`);
  
      // // Handle special cases first.
      // if (colId == 'budgetMonth' || colId == 'contractMonth') {
      //   return params.value;
      // }

      // Return default value
      return params.value;
  }

  function updatePositionRow(rowId: string, positionUpdateFunction: (position: PositionRow) => void) {
    setPositions(previous => {
      return previous.map((x: PositionRow) => {

        if (x.rowId !== rowId) {
          return x;
        }

        // console.log('calling function');
        positionUpdateFunction(x);
        // console.log('updated position', x);
        return x;
      })
    });
  }

  function updatePositionRow2(rowId: string, positionUpdateFunction: (position: PositionRow) => PositionRow) {
    setPositions(previous => {
      return previous.map((x: PositionRow) => {

        if (x.rowId !== rowId) {
          return x;
        }

        // console.log('calling function');
        const y = positionUpdateFunction(x);
        // console.log('updated position', y);
        return y;
      })
    });
  }

  function setRowValue(params: ValueSetterParams<PositionRow>, valueSetter: (row: PositionRow, newValue: any) => void) {
    
    // console.group('setRowValue', {newValue: params.newValue, oldValue: params.oldValue});

    if (params.newValue === params.oldValue) {
      // console.log('new and old values are the same');
      return false; // No change, return false to not update the cell
    }

    const rowId = params.data.rowId;

    // console.log('settingBudgets');

    setPositions(previous => {
      return previous.map((x: PositionRow) => {

        if (x.rowId !== rowId) {
          return x;
        }

        valueSetter(x, params.newValue);
        return x;
      })
    });

    // console.log('setBudgets');
    // console.groupEnd();

    return true;
  }

  function cleanAndValidateNumber(input?: string | null): string | null {
    if (!input) {
      return null;
    }  
    
    // Step 1: Remove commas and non-numeric characters except periods
    const cleanedInput = input.replace(/[^0-9.]/g, '');
  
    // Step 2: Check if the cleaned input is a valid number
    const parsedNumber = Number(cleanedInput);
  
    // Step 3: Validate if the parsed value is a finite number
    if (!isNaN(parsedNumber) && Number.isFinite(parsedNumber)) {
      return cleanedInput;
    }
  
    // If not valid, return null
    return null;
  }

  function calculateAverage() {
    const totalVolume = positions
      .filter(x => !!x.volume)
      .map(x => parseFloat(x.volume!.toString()))
      .reduce((previous, current) => previous + current, 0);

    const averagePrice = positions
    .filter(x => !!x.volume && !!x.price)
    .map(x => (parseFloat(x.volume!.toString()) * parseFloat(x.price!.toString())) / totalVolume)
    .reduce((previous, current) => previous + current, 0);

    const firstCurrencySet = positions.find(x => !!x.currency)?.currency;
    const isMultipleCurrencies = positions.some(x => !!x.currency && x.currency != firstCurrencySet);

    const firstUnitSet = positions.find(x => !!x.unit)?.unit;
    const isMultipleUnits = positions.some(x => !!x.unit && x.unit != firstUnitSet);

    console.log(firstCurrencySet, isMultipleCurrencies, firstUnitSet, isMultipleUnits);

    if (isMultipleCurrencies || isMultipleUnits) {
      setAverage(undefined);
      console.log('no averages for you!');
      return;
    }

    setAverage({
      currency: firstCurrencySet,
      unit: firstUnitSet,
      volume: totalVolume,
      price: averagePrice,
    });
  }

  useEffect(calculateAverage, [positions]);

  // const [ isAligning, setIsAligning ] = useState<boolean>(false);

  function alignAverageDivs() {

    // if (isAligning) {
    //   return;
    // }
    // setIsAligning(true);
      if (!gridRef.current || !totalVolumeRef.current || !averagePriceRef.current) {
        // console.log('doesnt have all so returning', gridRef.current, totalVolumeRef.current, averagePriceRef.current);
        return;
      }

      // Get the elements representing the cells for 'quantity' and 'price' headers
      const quantityColumnElement = document.querySelector(
        `.ag-header-cell[col-id="Quantity"]`
      ) as HTMLElement;
      
      const priceColumnElement = document.querySelector(
        `.ag-header-cell[col-id="Price"]`
      ) as HTMLElement;

      if (quantityColumnElement && totalVolumeRef.current) {
        // console.log('aligning total volume');
        // Align total volume div with the quantity column
        totalVolumeRef.current.style.width = `${quantityColumnElement.offsetWidth}px`;
        totalVolumeRef.current.style.left = `${quantityColumnElement.offsetLeft}px`;
        totalVolumeRef.current.style.visibility = 'visible';
        totalVolumeRef.current.style.position = 'relative';
      } else {
        totalVolumeRef.current.style.visibility = 'hidden';
      }

      if (priceColumnElement && averagePriceRef.current) {
        // console.log('aligning average price');
        // Align average price div with the price column
        averagePriceRef.current.style.width = `${priceColumnElement.offsetWidth}px`;
        averagePriceRef.current.style.left = `${priceColumnElement.offsetLeft - (priceColumnElement.offsetLeft - quantityColumnElement.offsetLeft)}px`;
        averagePriceRef.current.style.visibility = 'visible';
        averagePriceRef.current.style.position = 'relative';
      } else {
        averagePriceRef.current.style.visibility = 'hidden';
      }

      // setIsAligning(false);
  }

  useEffect(() => {

    // if (!gridRef.current?.api) {
    //   return;
    // }

    // Add global listeners to track the last interaction type
    // gridRef?.current?.api.addEventListener('columnResized', alignAverageDivs);

    const handleMouseDown = () => setLastInteraction('mouse');
    const handleKeyDown = () => setLastInteraction('keyboard');

    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('keydown', handleKeyDown);

    // Clean up listeners on component unmount
    return () => {
      // gridRef?.current?.api.removeEventListener('columnResized', alignAverageDivs);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const columnDefs: ColDef[] = useMemo(() => {
    
    if (!customerData) {
      return [];
    }

    return [
      {
        headerName: 'Side',
        colId: 'Side',
        minWidth: 90,
        flex: .5,
        editable: true,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.side = Object.keys(Side).includes(params.newValue) ? newValue : null;
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXSelect
            options={['Buy', 'Sell']}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.data.side ?? null} 
            onChange={(side: Side | null) => params.setValue?.(side ?? null)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Position Type',
        colId: 'Position Type',
        editable: true,
        minWidth: 110,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.side = Object.keys(PositionType).includes(params.newValue) ? newValue : null;
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.positionTypes ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.data.positionType ?? null} 
            onChange={(positionType: PositionType | null) => params.setValue?.(positionType ?? null)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Budget Year',
        colId: 'Budget Year',
        editable: true,
        minWidth: 60,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          const year = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
          row.budgetDate = row.budgetDate ? { ...row.budgetDate, year }: { year };
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXTextField 
            type="number" 
            fullWidth
            value={params.data.budgetDate?.year ?? ""} 
            onChange={(e: any) => params.setValue?.(e?.target?.value)}
          />
        ),
      },
      {
        headerName: 'Budget Month',
        colId: 'Budget Month',
        editable: true,
        minWidth: 60,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          const month = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
          row.budgetDate = row.budgetDate ? { ...row.budgetDate, month }: { month };
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXMonthDropdown 
            fullWidth 
            value={params.data.budgetDate?.month ?? null} 
            onChange={(month: number | null) => params.setValue?.(month)}
          />
        ),
      },
      // {
      //   headerName: 'Contract Year',
      //   colId: 'Contract Year',
      //   // editable: true,
      //   minWidth: 60,
      //   flex: .5,
      //   valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
      //     const year = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
      //     row.contractDate = row.contractDate ? { ...row.contractDate, year } : { year };
      //   }),
      //   cellRenderer: (params: ICellRendererParams) => (
      //     <StoneXTextField 
      //       type="number" 
      //       fullWidth 
      //       value={params.data.contractDate?.year ?? ""} 
      //       onChange={(e: any) => params.setValue?.(e?.target?.value)}
      //     />
      //   ),
      // },
      // {
      //   headerName: 'Contract Month',
      //   colId: 'Contract Month',
      //   editable: true,
      //   minWidth: 60,
      //   flex: .5,
      //   valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
      //     const month = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
      //     row.contractDate = row.contractDate ? { ...row.contractDate, month }: { month };
      //   }),
      //   cellRenderer: (params: ICellRendererParams) => (
      //     <StoneXMonthDropdown 
      //       fullWidth 
      //       value={params.data.contractDate?.month ?? null} 
      //       onChange={(month: number | null) => params.setValue?.(month)}
      //     />
      //   ),
      // },
      {
        headerName: 'Option Type',
        colId: 'Option Type',
        editable: true,
        minWidth: 90,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.side = Object.keys(OptionType).includes(params.newValue) ? newValue : null;
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.optionTypes ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.data.positionType !== 'Option' ? null : params.data.optionType ?? null} 
            disabled={params.data.positionType !== 'Option'}
            onChange={(optionType: OptionType | null) => params.setValue?.(optionType)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Option Style',
        colId: 'Option Style',
        editable: true,
        minWidth: 90,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.side = Object.keys(OptionStyle).includes(params.newValue) ? newValue : null;
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.optionStyles ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.data.positionType !== 'Option' ? null : params.data.optionStyle ?? null} 
            onChange={(optionStyle: OptionStyle | null) => params.setValue?.(optionStyle)}
            disabled={params.data.positionType !== 'Option'}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Option Strike',
        colId: 'Option Strike',
        editable: true,
        minWidth: 90,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.optionStrike = cleanAndValidateNumber(newValue);
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXTextField 
            type="price" 
            value={params.data.positionType !== 'Option' ? "" : params.data.optionStrike ?? ""} 
            onChange={(e: any) => params.setValue?.(e?.target?.value)}
            disabled={params.data.positionType !== 'Option'}
            fullWidth 
          />
        ),
      },
      {
        headerName: 'Quantity',
        colId: 'Quantity',
        editable: true,
        minWidth: 110,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.volume = cleanAndValidateNumber(newValue);
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXTextField 
            type="volume" 
            fullWidth 
            value={params.data.volume ?? ""} 
            onChange={(e: any) => params.setValue?.(e?.target?.value)}
          />
        ),
      },
      {
        headerName: 'Price',
        colId: 'Price',
        editable: true,
        minWidth: 110,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.price = cleanAndValidateNumber(newValue);
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXTextField 
            type="price" 
            fullWidth 
            value={params.data.price ?? ""} 
            onChange={(e: any) => params.setValue?.(e?.target?.value)}
          />
        ),
      },
      {
        headerName: 'Unit',
        colId: 'Unit',
        editable: true,
        minWidth: 100,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.side = Object.keys(Unit).includes(params.newValue) ? newValue : null;
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.units ?? ['None']}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.data.unit ?? null} 
            onChange={(unit: Unit | null) => params.setValue?.(unit)}
            fullWidth
          />
        ),
      },
      {
        headerName: 'Currency',
        colId: 'Currency',
        editable: true,
        minWidth: 100,
        flex: .5,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          row.side = Object.keys(Currency).includes(params.newValue) ? newValue : null;
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXSelect
            options={customerData.currencies ?? []}
            getId={(q) => q}
            getOptionLabel={(q) => q}
            value={params.data.currency ?? null} 
            onChange={(currency: Currency | null) => params.setValue?.(currency)}
            fullWidth
          />
        ),
      },
      {
        headerName: '',
        colId: '',
        width: 56,
        cellRenderer: (params: ICellRendererParams) => (
          <IconButton onClick={() => removePosition(params.data.rowId)}><Close /></IconButton>
        )
      }
    ];
  }, [customerData]);

  const isAllowedKey = (event: KeyboardEvent): boolean => {
    const allowedKeys = ['Tab', 'Shift', 'Control', 'Alt', 'Escape', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Delete', 'c', 'v'];

    return allowedKeys.includes(event.key);
  };

  return (
    <StoneXMainPage>
      <StoneXRow>
        <StoneXDatePicker value={tradeDate} onChange={onTradeDateChange} label="Trade Date" />

        <StoneXAutocomplete
          options={customerData?.commodities ?? []}
          getOptionLabel={(q) => q.commodityName}
          value={selectedCommodity}
          onChange={(e: any, commodity: CommodityModel | null) => setSelectedCommodity(commodity)}
          isOptionEqualToValue={(a, b) => a.commodityId == b.commodityId}
          label="Commodity"
          showRequired
        />

        <StoneXAutocomplete
          options={customerData?.counterparties ?? []}
          getOptionLabel={(q) => q.counterpartyName!}
          value={selectedCounterparty}
          onChange={(e: any, counterparty: CounterpartyModel | null) => setSelectedCounterparty(counterparty)}
          isOptionEqualToValue={(a, b) => a.counterpartyId == b.counterpartyId}
          label="Counterparty"
          showRequired
        />

        <StoneXAutocomplete
          options={customerData?.portfolios ?? []}
          getOptionLabel={(q) => q.portfolioName!}
          value={selectedPortfolio}
          onChange={(e: any, portfolio: PortfolioModel | null) => setSelectedPortfolio(portfolio)}
          isOptionEqualToValue={(a, b) => a.portfolioId == b.portfolioId}
          label="Portfolio"
          showRequired
        />

        <StoneXTextField 
          type="text" 
          label="Deal Ticket" 
          value={dealTicketNumber} 
          onChange={(event) => setDealTicketNumber(event.target.value)} 
          showRequired
        />

        <div className="pull-right nested-row-item">
          {/* <Button variant="outlined">Cancel</Button> */}
          <Button onClick={updatePositions}>Save</Button>
        </div>
      </StoneXRow>
      <StoneXDivider />
      <div className="ag-theme-alpine ag-with-inputs" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          getRowId={(params) => params.data?.rowId}
          processCellForClipboard={processCellForClipboard}
          processCellFromClipboard={processCellFromClipboard}
          onFirstDataRendered={() => setTimeout(alignAverageDivs, 200)}
          onColumnResized={alignAverageDivs}
          suppressRowHoverHighlight
          suppressColumnMoveAnimation
          suppressClickEdit
          enableRangeSelection
          enterNavigatesVertically
          animateRows={false}
          reactiveCustomComponents={true}
          rowHeight={56}
          rowData={positions}
          domLayout="autoHeight"
          onCellValueChanged={onCellUpdated}
          onCellFocused={onCellFocused}
          defaultColDef={{
            suppressHeaderMenuButton: true,
            suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => !isAllowedKey(params.event),
          }}
          // statusBar={{
          //   statusPanels: [
          //     {
          //       statusPanel: 'agTotalRowCountComponent',
          //       align: 'left',
          //     },
          //     {
          //       statusPanel: StatusBarAggregation,
          //     },
          //     {
          //       statusPanel: 'agAggregationComponent',
          //       statusPanelParams: {
          //         aggFuncs: ['sum', 'avg'],
          //         componentParams: {
          //           onlySelected: false, // This ensures it aggregates over all rows
          //         },
          //       },
          //     },
          //   ],
          // }}
          columnDefs={columnDefs}
        />
        <div>
          {/* <StoneXLabeledItem inline label="Count: ">
            {positions.length}
          </StoneXLabeledItem>
          
          <span className='pull-right'> */}
            <div ref={totalVolumeRef} style={{display: 'inline-block', textAlign: 'right', visibility: 'hidden'}}>
              <StoneXLabeledItem label="Total Volume">
                <div style={{display: 'inline-block'}}>
                < StoneXNumber number={average?.volume} decimals={0}/> {average?.unit}
                </div>
              </StoneXLabeledItem>
            </div>

            <div ref={averagePriceRef} style={{display: 'inline-block', textAlign: 'right', visibility: 'hidden'}}>
              <StoneXLabeledItem label="Weighted Avg">
                <StoneXNumber number={average?.price} decimals={selectedCommodity?.displayPrecision ?? 2} currency={average?.currency}/>
              </StoneXLabeledItem>
            </div>
          {/* </span> */}

        </div>
      </div>
      {/* {JSON.stringify(positions)} */}
      <StoneXRow>
        {addPositionCountOptions.map((x) => (
          <Button key={x} variant="outlined" onClick={() => addNewPosition(x)}>
            Add {x}
          </Button>
        ))}
      </StoneXRow>
    </StoneXMainPage>
  );
}
