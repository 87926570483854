import { useEffect, useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { GetCustomerSettingsResponse, PositionModel, YearDataModel } from '../../../../../Generated/Commodity-Management-Api';
import { ContractDateHelper } from '../../../../../Helpers';
import { LoadingState } from '../../../../../Types';
import { StoneXLoading, StoneXRow } from '../../../../StoneX';
import { StoneXMultiYearPills, StoneXSelectPills, StoneXYearPills } from '../../../../StoneXMui';
import { useFetcher } from 'react-router-dom';
import dayjs from 'dayjs';
import { uniq, uniqBy, uniqWith } from 'lodash';
import { useLoadingState } from '../../../../../Hooks';

interface BasicQueryFormProps {
  customerSettings: GetCustomerSettingsResponse;
  years: YearDataModel[];
  loadingStateSetter: (loadingState: LoadingState) => void;
  onPositionsUpdated: (positions: PositionModel[]) => void;
}

export default function BasicQueryForm(props: BasicQueryFormProps) {
  const { customerSettings, years, loadingStateSetter, onPositionsUpdated } = props;

  const [ positions, setPositions ] = useState<PositionModel[]>([]);
  const [ selectedYears, setSelectedYears ] = useState<number[]>([]);

  const loadingState = useLoadingState();

  function getInitalPositions() {
    const currentYear = dayjs().year();
    const startDate = ContractDateHelper.getStartContractDate(currentYear, customerSettings?.fiscalMonthOffset);
    // const endDate = ContractDateHelper.addMonths(startDate, 12);

    CommodityManagementApi.listPositions(
      loadingState.setLoadingState,
      undefined, //mtmDate
      false, //include expiration dates
      false, //include market prices
      false, // don't use display units - use original units
      undefined, // no scenario market prices
      undefined, //commodityFilter,
      undefined, //locationFilter,
      undefined, //counterpartyFilter,
      undefined, //trade start date
      undefined, //trade end date
      startDate.year,
      startDate.month,
      undefined, //label (ignore)
      // endDate.year,   //get all forward positions
      // endDate.month,  //get all forward positions
    ).then((q) => {
      setPositions(q.data.rows ?? []);
      const years = uniq(q.data.rows?.map(x => x.budgetDate.year!));
      setSelectedYears(years);
    });
  }

  function removeYearPositions(year: number) {
    const startDate = ContractDateHelper.getStartContractDate(year, customerSettings?.fiscalMonthOffset);
    const endDate = ContractDateHelper.addMonths(startDate, 12);
    setSelectedYears(previous => previous.filter(x => x != year));
    setPositions(previous => previous.filter(x => !(ContractDateHelper.isGreaterThanOrEqualTo(x.budgetDate, startDate) && ContractDateHelper.isLessThanOrEqualTo(x.budgetDate, endDate))));
  }

  function addYearPositions(year: number) {
    const startMonth = ContractDateHelper.getStartContractDate(year, customerSettings?.fiscalMonthOffset);
    const endMonth = ContractDateHelper.addMonths(startMonth, 12);

    CommodityManagementApi.listPositions(
      loadingState.setLoadingState,
      undefined, //mtmDate
      false, //include expiration dates
      false, //include market prices
      false, // don't use display units - use original units
      undefined, // no scenario market prices
      undefined, //commodityFilter,
      undefined, //locationFilter,
      undefined, //counterpartyFilter,
      undefined, //trade start date
      undefined, //trade end date
      startMonth.year,
      startMonth.month,
      undefined, //label (ignore)
      endMonth.year,
      endMonth.month,
    ).then((q) => {
      setPositions(previous => uniqBy([...previous, ...q.data.rows ?? []], x => x.positionId))
      setSelectedYears(previous => [...previous, year]);
    });
  }

  useEffect(() => onPositionsUpdated(positions), [positions]);
  useEffect(getInitalPositions, []);

  return (
    <StoneXRow>
      <StoneXSelectPills 
        label="Fiscal Year" 
        options={years.map(x => x.year)} 
        multiple={true}
        getOptionId={x => x} 
        getOptionLabel={x => `${x}`}
        getSortingValue={x => x} 
        value={selectedYears}
        onSelect={addYearPositions}
        onDeselect={removeYearPositions}
      />
      <StoneXLoading show={loadingState.isLoading()}/>
    </StoneXRow>
  );
}
