import { CellClassParams, IAggFuncParams, ICellRendererParams, ValueFormatterFunc, ValueFormatterParams } from 'ag-grid-community';
import { Currency, StressIncrementType } from '../../../../../../Generated/Commodity-Management-Api';
import { GridRow } from '../../../../../../Types';
import { MonthInteger } from '../../../../../../Types/MonthDictionary';
import { StoneXNumber } from '../../../../../StoneXMui';

export interface ScenarioReportConfiguration {
  inputs?: {
    budgetStartDate?: {
      hide?: boolean;
      strategy?: 'fiscalYearStart' | 'currentMonth'; // Defaults to FiscalYearStart
      offset?: number;
      rollOffInToNewYearMonth?: MonthInteger;
    };
    budgetEndDate?: {
      hide: boolean;
      offsetFromStartMonth?: number; // Defaults to 12
    };
    stressIncrements?: number[],
    stressIncrementType?: StressIncrementType,
    commodities?: {
      multiple?: boolean;
    }
  };
  columns: {
    available: ScenarioReportColumn[];
    active: ScenarioReportColumn[];
  };
  chart: {
    series: {
      available: ScenarioReportSeries[];
      active: ScenarioReportSeries[];
    }
  }
}

export interface ScenarioReportUserOverride {
  inputs?: {
    stressIncrements?: number[],
    stressIncrementType?: StressIncrementType,
  };
  columns?: {
    active?: ScenarioReportColumn[];
  };
  chart: {
    series: {
      available: ScenarioReportSeries[];
      active: ScenarioReportSeries[];
    }
  };
}

export interface ScenarioReportRow extends GridRow {

  isAggregated?: boolean;

  currency?: Currency;

  stressIncrement?: number;
  stressIncrementType?: StressIncrementType;

  effectiveBudgetVolume: number;
  budgetVolume: number;
  budgetPrice: number;
  budgetValue: number;
  marketPrice: number;
  marketValue: number;
  openVolume?: number;

  underlyingHedgedVolume: number;
  blendedPrice: number;
  blendedValue: number;
  varianceToBudgetPrice: number;
  varianceToBudgetValue: number;

  hasWhatIfPositions: boolean;

  whatIfUnderlyingHedgedVolume?: number;
  whatIfBlendedPrice?: number;
  whatIfBlendedValue?: number;
  whatIfVarianceToBudgetPrice?: number;
  whatIfVarianceToBudgetValue?: number;
}

// Use a shared type for column config?
interface ScenarioColumnConfiguration {
  colId: string;
  headerName?: string; // This is what appears on the table header
  columnName?: string; // This is a unique column name to distinguish from other versions of the same column
  parentHeader?: string;
  field?: string;
  width?: number;
  minWidth?: number;
  maxWidth?: number;
  sortable?: boolean;
  flex?: number;
  hide?: boolean;
  filter?: string;
  isWhatIfColumn?: boolean;
  cellClass?: string | string[] | ((params: CellClassParams<ScenarioReportRow, any>) => string | string[] | undefined);
  aggFunc?: string | ((params: IAggFuncParams<ScenarioReportRow, any>) => any) | null | undefined;
  valueFormatter?: (params: ValueFormatterParams<ScenarioReportRow>) => string | ValueFormatterFunc<ScenarioReportRow, any>;
  cellRenderer?: (params: ICellRendererParams<ScenarioReportRow>) => any;
}

// Use a shraed type for cell renderers and widths...
const priceCellRenderer = (params: ICellRendererParams<ScenarioReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} decimals={4}></StoneXNumber>;
const volumeCellRenderer = (params: ICellRendererParams<ScenarioReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value}></StoneXNumber>;
const valueCellRenderer = (params: ICellRendererParams<ScenarioReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value}></StoneXNumber>;
const percentCellRenderer = (params: ICellRendererParams<ScenarioReportRow>) => params.node.level == -1 ? undefined : <StoneXNumber number={params.value} percent></StoneXNumber>;

const priceWidth = 100;
const volumeWidth = 110;
const valueWidth = 120;
const percentWidth = 90;

export type ScenarioReportColumn = 'BudgetVolume' 
| 'BudgetPrice' 
| 'BudgetValue' 
| 'MarketPrice' 
| 'MarketValue' 
| 'UnderlyingHedgedVolume' 
| 'BlendedPrice' 
| 'BlendedValue' 
| 'VarianceToBudgetPrice' 
| 'VarianceToBudgetValue' 
| 'WhatIfUnderlyingHedgedVolume' 
| 'WhatIfBlendedPrice' 
| 'WhatIfBlendedValue' 
| 'WhatIfVarianceToBudgetPrice' 
| 'WhatIfVarianceToBudgetValue';

export const allColumns: ScenarioReportColumn[] = ['BudgetVolume'
  , 'BudgetPrice'
  , 'BudgetValue'
  , 'MarketPrice'
  , 'MarketValue'
  , 'UnderlyingHedgedVolume'
  , 'BlendedPrice'
  , 'BlendedValue'
  , 'VarianceToBudgetPrice'
  , 'VarianceToBudgetValue'
  , 'WhatIfUnderlyingHedgedVolume'
  , 'WhatIfBlendedPrice'
  , 'WhatIfBlendedValue'
  , 'WhatIfVarianceToBudgetPrice'
  , 'WhatIfVarianceToBudgetValue'
];

export const ScenarioColumns: { [key in ScenarioReportColumn]: ScenarioColumnConfiguration } = {
  BudgetVolume: {
    colId: 'Budget Volume',
    headerName: 'Budget Volume',
    field: 'budgetVolume',
    flex: 1,
    cellRenderer: volumeCellRenderer,
  },
  BudgetPrice: {
    colId: 'Budget Price',
    headerName: 'Budget Price',
    field: 'budgetPrice',
    flex: 1,
    minWidth: priceWidth,
    cellRenderer: priceCellRenderer,
  },
  BudgetValue: {
    colId: 'Budget Value',
    headerName: 'Budget Value',
    field: 'budgetValue',
    flex: 1,
    minWidth: valueWidth,
    cellRenderer: valueCellRenderer,
  },
  MarketPrice: {
    colId: 'Market Price',
    headerName: 'Market Price',
    field: 'marketPrice',
    flex: 1,
    minWidth: priceWidth,
    cellRenderer: priceCellRenderer,
  },
  MarketValue: {
    colId: 'Market Value',
    headerName: 'Market Value',
    field: 'marketValue',
    flex: 1,
    minWidth: valueWidth,
    cellRenderer: valueCellRenderer,
  },
  UnderlyingHedgedVolume: {
    colId: 'UnderlyingHedgedVolume',
    parentHeader: 'Without Strategy',
    headerName: 'Hedged Volume',
    field: 'underlyingHedgedVolume',
    flex: 1,
    minWidth: volumeWidth,
    cellRenderer: volumeCellRenderer,
  },
  BlendedPrice: {
    colId: 'Blended Price',
    parentHeader: 'Without Strategy',
    headerName: 'Blended Price',
    field: 'blendedPrice',
    flex: 1,
    minWidth: priceWidth,
    cellRenderer: priceCellRenderer,
  },
  BlendedValue: {
    colId: 'Blended Value',
    parentHeader: 'Without Strategy',
    headerName: 'Blended Value',
    field: 'blendedValue',
    flex: 1,
    minWidth: valueWidth,
    cellRenderer: valueCellRenderer,
  },
  VarianceToBudgetPrice: {
    colId: 'VarianceToBudgetPrice',
    parentHeader: 'Without Strategy',
    headerName: 'VTB Price',
    field: 'varianceToBudgetPrice',
    flex: 1,
    minWidth: priceWidth,
    cellRenderer: priceCellRenderer,
  },
  VarianceToBudgetValue: {
    colId: 'Variance To Budget Value',
    parentHeader: 'Without Strategy',
    headerName: 'VTB Value',
    field: 'varianceToBudgetValue',
    flex: 1,
    minWidth: valueWidth,
    cellRenderer: valueCellRenderer,
  },
  WhatIfUnderlyingHedgedVolume: {
    colId: 'What-If Underlying Hedged Volume',
    parentHeader: 'With Strategy',
    headerName: 'Hedged Volume',
    field: 'whatIfUnderlyingHedgedVolume',
    flex: 1,
    isWhatIfColumn: true,
    minWidth: volumeWidth,
    cellRenderer: volumeCellRenderer,
  },
  WhatIfBlendedPrice: {
    colId: 'What-If Blended Price',
    parentHeader: 'With Strategy',
    headerName: 'Blended Price',
    field: 'whatIfBlendedPrice',
    flex: 1,
    isWhatIfColumn: true,
    minWidth: priceWidth,
    cellRenderer: priceCellRenderer,
  },
  WhatIfBlendedValue: {
    colId: 'WhatIfBlendedValue',
    parentHeader: 'With Strategy',
    headerName: 'Blended Value',
    field: 'whatIfBlendedValue',
    flex: 1,
    isWhatIfColumn: true,
    minWidth: valueWidth,
    cellRenderer: valueCellRenderer,
  },
  WhatIfVarianceToBudgetPrice: {
    colId: 'WhatIfVarianceToBudgetPrice',
    parentHeader: 'With Strategy',
    headerName: 'VTB Price',
    field: 'whatIfVarianceToBudgetPrice',
    flex: 1,
    isWhatIfColumn: true,
    minWidth: priceWidth,
    cellRenderer: priceCellRenderer,
  },
  WhatIfVarianceToBudgetValue: {
    colId: 'What-If Variance To Budget Value',
    parentHeader: 'With Strategy',
    headerName: 'VTB Value',
    field: 'whatIfVarianceToBudgetValue',
    flex: 1,
    isWhatIfColumn: true,
    minWidth: valueWidth,
    cellRenderer: valueCellRenderer,
  },
};

export type ScenarioReportSeries = 'budgetValue' | 'marketValue' | 'blendedValue' | 'budgetPrice' | 'marketPrice' | 'blendedPrice' | 'blendedPriceWithStrategy';

interface ScenarioSeriesConfiguration {
  seriesId: string;
  headerName?: string; // This is what appears on the table header
  columnName?: string; // This is a unique column name to distinguish from other versions of the same series
  parentHeader?: string;
}

export const ScenarioSeries: { [key in ScenarioReportSeries]: ScenarioSeriesConfiguration } = {
  budgetPrice: {
    seriesId: 'budgetPrice',
    headerName: 'Budget Price',
    columnName: 'Budget Price',
  },
  budgetValue: {
    seriesId: 'budgetValue',
    headerName: 'Budget Value',
    columnName: 'Budget Value',
  },
  marketPrice: {
    seriesId: 'marketPrice',
    headerName: 'Market Price',
    columnName: 'Market Price',
  },
  marketValue: {
    seriesId: 'marketValue',
    headerName: 'Budget Value',
    columnName: 'Budget Value',
  },
  blendedPrice:{
    seriesId: 'blendedPrice',
    headerName: 'Blended Price',
    columnName: 'Blended Price',
  },
  blendedValue:{
    seriesId: 'blendedValue',
    headerName: 'Blended Value',
    columnName: 'Blended Value',
  },
  blendedPriceWithStrategy: {
    seriesId: 'blendedPriceWithStrategy',
    headerName: 'Blended Price (With Strategy)',
    columnName: 'Blended Price (With Strategy)',
  },
}