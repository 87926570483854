import 'ag-grid-enterprise';
import { CellFocusedEvent, ColDef, ICellRendererParams,ProcessCellForExportParams,SuppressKeyboardEventParams,ValueGetterParams,ValueSetterParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import React,{ useEffect,useMemo,useRef,useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import {
ContractDate,GetCustomerDataResponse,
GetCustomerSettingsResponse,
BudgetPricingStrategyModel,
UpdateBudgetModel,
BudgetModel
} from '../../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../../Hooks';
import { BudgetFormSchema } from '../../../../../Validations';
import { StoneXRow } from '../../../../StoneX';
import { StoneXDivider } from '../../../../StoneX/StoneXDivider';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXContractMonthDropdown,StoneXMonthDropdown,StoneXTextField } from '../../../../StoneXMui';
import BudgetSelectionForm from './BudgetSelectionForm';
import { BudgetRow } from './BudgetTypes';
import { isNumber, set, toNumber, update } from 'lodash';

export default function UpdateBudgetsPage() {
  const gridRef = useRef<AgGridReact<BudgetRow>>(null);

  const [customerData, setCustomerData] = useState<GetCustomerDataResponse>({});
  const [customerSettings, setCustomerSettings] = useState<GetCustomerSettingsResponse>();
  const [pricingStrategies, setPricingStrategies] = useState<BudgetPricingStrategyModel[] | null>();
  const [budgets, setBudgets] = useState<BudgetRow[]>([]);
  const [isInEditMode, setIsInEditMode] = useState(false);
  const [formErrorsHashmap, setFormErrorsHashmap] = useState<{ [key: string]: string }[]>([]);
  const [pricingStrategy, setPricingStrategy] = useState<BudgetPricingStrategyModel>();

  //init data on load
  const customerDataLoadingState = useLoadingState();
  const customerSettingsLoadingState = useLoadingState();
  const pricingStrategyLoadingState = useLoadingState();
  const budgetSaveLoadingState = useLoadingState();

  const colIdToValueGetter: {[key: string]: (row: BudgetRow) => any} = {
    'Budget Year': (row: BudgetRow) => row.budgetDate?.year,
    'Budget Month': (row: BudgetRow) => row.budgetDate?.month,
    'Contract Year': (row: BudgetRow) => row.contractDate?.year,
    'Contract Month': (row: BudgetRow) => row.contractDate?.month,
    'Quantity': (row: BudgetRow) => row.volume,
    'Unit': (row: BudgetRow) => row.unit,
    'Currency': (row: BudgetRow) => row.currency,
  };

  useEffect(() => {
    CommodityManagementApi.getCustomerData(customerDataLoadingState.setLoadingState).then((q) => setCustomerData(q.data));
    CommodityManagementApi.getCustomerSettings(customerSettingsLoadingState.setLoadingState).then((q) => setCustomerSettings(q.data));
    CommodityManagementApi.listPricingStrategies(pricingStrategyLoadingState.setLoadingState).then((q) => setPricingStrategies(q.data.rows));
  }, []);

  function getBudgetsFromAgGrid() {
    const gridApi = gridRef!.current!.api;
    const budgets: BudgetRow[] = [];

    gridApi.forEachNode((node: any) => {
      budgets.push(node.data);
    });

    return budgets;
  }

  function validateBudgets(budgets: BudgetRow[]): boolean {
    const errors: { [key: string]: string }[] = [];

    budgets.forEach((row, index) => {
      try {
        BudgetFormSchema.validateSync(row, { abortEarly: false });
      } catch (err: any) {
        err.inner.forEach((e: any) => {
          if (!errors[index]) {
            errors[index] = {};
          }

          errors[index][e.path] = e.message;
        });
      }
    });

    setFormErrorsHashmap(errors);

    return !!errors;
  }

  const saveBudgets = (): void => {
    // const budgets = getBudgetsFromAgGrid();
    const budgetsAreValid = validateBudgets(budgets);
    console.log('budgetsAreValid', budgetsAreValid, budgets);

    var updateBudgetsModel = {
      budgets: budgets as UpdateBudgetModel[]
    } ;

    CommodityManagementApi.updateBudgets(budgetSaveLoadingState.setLoadingState, updateBudgetsModel);
  };

  const isAllowedKey = (event: KeyboardEvent): boolean => {
    const allowedKeys = ['Tab', 'Shift', 'Control', 'Alt', 'Escape', 'ArrowLeft', 'ArrowUp', 'ArrowRight', 'ArrowDown', 'Delete', 'c', 'v'];

    return allowedKeys.includes(event.key);
  };

  function updatePricingStrategy(budgets: BudgetRow[]) {
    if (pricingStrategy?.budgetPricingStrategyId !== budgets?.[0]?.pricingStrategyId) {
      setPricingStrategy(pricingStrategies?.find(x => x.budgetPricingStrategyId == budgets?.[0]?.pricingStrategyId));
    }
  }

  function updateBudgetRow(rowId: string, budgetUpdateFunction: (position: BudgetRow) => void) {
    setBudgets(previous => {
      return previous.map((x: BudgetRow) => {

        if (x.rowId !== rowId) {
          return x;
        }

        budgetUpdateFunction(x);
        return x;
      })
    });
  }

  function onCellFocused(cellEvent: CellFocusedEvent) {

    const cell = document.querySelector(
      `.ag-row[row-index="${cellEvent.rowIndex}"] .ag-cell[col-id="${(cellEvent.column as any).getColId()}"]`
    );

    const element = cell?.querySelector('.MuiSelect-select, .MuiInputBase-input');

    if (!element) {
      return;
    }

    if (element.classList.contains('MuiSelect-select')) {
      (element as HTMLElement).focus();
      
      // Don't auto focus if the cell was just clicked on, it can get annoying for user
      // if (lastInteraction === 'keyboard') {
      //   const event = new KeyboardEvent('keydown', {
      //     key: 'Enter',
      //     code: 'Enter',
      //     keyCode: 13,
      //     bubbles: true,
      //   });
      // element.dispatchEvent(event);
      // }
      
    } else {
      (element as HTMLElement).focus();
    }
  }
  
  function processCellForClipboard(params: ProcessCellForExportParams) {
    // Copying data
    // console.group('Copying start');
    
    const colId = params.column.getColId();
    const row = budgets.find(x => x.rowId == params.node?.data.rowId);
    
    // Handle columns that have a direct mapping.
    const valueGetter = colIdToValueGetter[colId];

    if (!valueGetter || !row) {
      // console.groupEnd();
      return;
  }

    const value = valueGetter(params.node?.data);

    // console.log(`Copying ${value} for ${colId}`);

    // console.groupEnd();

    return value;
  }

  function processCellFromClipboard(params: ProcessCellForExportParams) {
    // Pasting data
    // console.group('Pasting start');
    
    // const colId = params.column.getColId();
    
    // console.log(`Pasting ${params.value} for column`);

    // Return default value
    // console.groupEnd();
    return params.value;
  }

  function setRowValue(params: ValueSetterParams<BudgetRow>, valueSetter: (row: BudgetRow, newValue: any) => void) {
    
    // console.group('setRowValue', {newValue: params.newValue, oldValue: params.oldValue});

    if (params.newValue === params.oldValue) {
      // console.log('new and old values are the same');
      return false; // No change, return false to not update the cell
    }

    const rowId = params.data.rowId;

    // console.log('settingBudgets');

    setBudgets(previous => {
      return previous.map((x: BudgetRow) => {

        if (x.rowId !== rowId) {
          return x;
        }

        valueSetter(x, params.newValue);
        return x;
      })
    });

    // console.log('setBudgets');
    // console.groupEnd();

    return true;
  }

  const columnDefs: ColDef[] = useMemo(() => {
    return [
      {
        colId: 'Budget Year',
        headerName: 'Budget Year',
        editable: true,
        minWidth: 60,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          const year = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
          row.budgetDate = row.budgetDate ? { ...row.budgetDate, year }: { year };
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXTextField 
            type="number" 
            fullWidth
            value={params.data.budgetDate?.year ?? ""} 
            onChange={(e: any) => params.setValue?.(e?.target?.value)}
          />
        ),
      },
      {
        colId: 'Budget Month',
        headerName: 'Budget Month',
        editable: true,
        minWidth: 60,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          const month = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
          row.budgetDate = row.budgetDate ? { ...row.budgetDate, month }: { month };
        }),
        cellRenderer: (params: ICellRendererParams) => (
          <StoneXMonthDropdown 
            fullWidth 
            value={params.data.budgetDate?.month ?? null} 
            onChange={(month: number | null) => params.setValue?.(month)}
          />
        ),
      },
      // {
      //   colId: 'Contract Year',
      //   editable: true,
      //   headerName: 'Contract Year',
      //   minWidth: 60,
      //   hide: !budgets[0]?.hasContractDate,
      //   valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
      //     const year = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
      //     row.contractDate = row.contractDate ? { ...row.contractDate, year } : { year };
      //   }),
      //   cellRenderer: (params: ICellRendererParams) => (
      //     <StoneXTextField 
      //       type="number" 
      //       fullWidth 
      //       value={params.data.contractDate?.year ?? ""} 
      //       onChange={(e: any) => params.setValue?.(e?.target?.value)}
      //     />
      //   ),
      // },
      // {
      //   colId: 'Contract Month',
      //   editable: true,
      //   headerName: 'Contract Month',
      //   minWidth: 60,
      //   hide: !budgets[0]?.hasContractDate,
      //   valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
      //     const month = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
      //     row.contractDate = row.contractDate ? { ...row.contractDate, month }: { month };
      //   }),
      //   cellRenderer: (params: ICellRendererParams) => (
      //     <StoneXMonthDropdown 
      //       fullWidth 
      //       value={params.data.contractDate?.month ?? null} 
      //       onChange={(month: number | null) => params.setValue?.(month)}
      //     />
      //   ),
      // },
      {
        colId: 'Quantity',
        editable: true,
        headerName: 'Budget Volume',
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          const volume = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
          row.volume = volume;
        }),
        cellRenderer: (props: ICellRendererParams) => (
          <StoneXTextField 
            type="volume" 
            fullWidth 
            value={props.data.volume ?? ""}
            min={0} 
            onChange={(event: any) => props.setValue!(event.target.value)} 
          />
        ),
      },
      ...(budgets[0]?.budgetPrices?.map((component, index) => ({
        headerName: `${component.componentType} Price`,
        editable: true,
        valueSetter: (params: ValueSetterParams) => setRowValue(params, (row, newValue) => {
          const price = Number.isFinite(toNumber(newValue)) ? toNumber(newValue) : undefined;
          row.budgetPrices![index].price = price!;
        }),
        cellRenderer: (props: ICellRendererParams) => (
          <StoneXTextField fullWidth type="price" value={props.data.budgetPrices[index].price ?? ""} onChange={(event: any) => props.setValue!(event.target.value)} />
        ),
      })) ?? []),
    ];
  }, [pricingStrategy]);

  useEffect(() => updatePricingStrategy(budgets), [budgets]);

  return (
    <StoneXMainPage>
      <StoneXRow>
        <h1>Add Budgets</h1>
      </StoneXRow>

      {customerSettings && customerData && pricingStrategies && (
        <BudgetSelectionForm
          customerSettings={customerSettings!}
          customerData={customerData}
          pricingStrategies={pricingStrategies!}
          onBudgetsUpdated={(budgets: BudgetRow[]) => setBudgets(budgets)}
          onEditStart={() => setIsInEditMode(true)}
          onEditCancel={() => setIsInEditMode(false)}
          onSave={saveBudgets}
        />
      )}

      <StoneXDivider />

      {isInEditMode && (
        <div className="ag-theme-alpine ag-with-inputs" style={{ width: '100%' }}>
          <AgGridReact
            ref={gridRef}
            getRowId={(params) => params.data?.rowId}
            processCellForClipboard={processCellForClipboard}
            processCellFromClipboard={processCellFromClipboard}
            suppressRowHoverHighlight
            suppressColumnMoveAnimation
            suppressClickEdit
            enableRangeSelection          
            enterNavigatesVertically
            animateRows={false}
            reactiveCustomComponents={true}
            rowHeight={56}
            rowData={budgets}
            domLayout="autoHeight"
            // onCellFocused={onCellFocused}
            defaultColDef={{
              flex: 1,
              editable: true,
              suppressHeaderMenuButton: true,
              suppressKeyboardEvent: (params: SuppressKeyboardEventParams) => !isAllowedKey(params.event),
            }}
            columnDefs={columnDefs}
          />
        </div>
      )}
    </StoneXMainPage>
  );
  
}