import { createTheme } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    primary: true,
    text: true
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    info: true;
    neutral: true;
    success: true;
  }
}

export default createTheme({
  components: {
    // Name of the component
    MuiPaper: {
      styleOverrides: {
        root: {
          transitionDuration: '0s !important',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          // backgroundColor: 'blue',
        },
      },
    },    
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          width: '250px',
        },
        inputRoot: {
          padding: 1,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {},
      styleOverrides: {
        root: {
          
        },
      },
      variants: [
        {
          props: { variant: 'filled' },
          style: {
            '& .MuiInputBase-root': {
              backgroundColor: 'var(--textfield-filled-background-color)',
              color: 'var(--textfield-filled-text-color)',
              borderRadius: '4px'
            },
            '& .MuiSvgIcon-root': {
              color: 'var(--white)',
            },
          },
        },
      ]
    },
    MuiOutlinedInput: {
      defaultProps: {},
      styleOverrides: {
        root: {
          minHeight: '40px',
          width: '250px',
          lineHeight: '18px',
          backgroundColor: 'var(--white)',
          '&.Mui-disabled': {
            WebkitTextFillColor: 'var(--font-color)',
            backgroundColor: 'var(--input-disabled-color)',
          },
          '.MuiSelect-select': {
            paddingTop: '7.5px',
            paddingBottom: '7.5px',
            lineHeight: '23px',
          },
          input: {
            padding: '7.5px 6px',
          },
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          '.MuiButtonGroup-grouped': {
            borderColor: 'var(--button-group-textual-divider-color) !important',
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'primary',
      },
      styleOverrides: {
        root: {
          height: '40px',
          lineHeight: '18px',
          padding: '11px 16px',
          borderRadius: 3,
          letterSpacing: '0.07em',
          fontWeight: 700,
          '&.Mui-disabled': {
            backgroundColor: 'var(--button-primary-disabled-color)',
          },
        },
      },
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            color: 'var(--button-primary-text-color)',
            backgroundColor: 'var(--button-primary-color)',
            ':hover': {
              color: 'var(--button-primary-hover-text-color)',
              backgroundColor: 'var(--button-primary-hover-color)',
            },
          },
        },
        {
          props: { variant: 'outlined' },
          style: {
            borderColor: 'var(--button-outlined-border-color)',
            color: 'var(--button-outlined-text-color)',
            backgroundColor: 'var(--button-outlined-color)',
            ':hover': {
              color: 'var(--button-outlined-hover-text-color)',
              backgroundColor: 'var(--button-outlined-hover-color)',
            },
          },
        },
        {
          props: { variant: 'text' },
          style: {
            borderColor: 'transparent',
            color: 'var(--button-textual-text-color)',
            backgroundColor: 'transparent',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            textTransform: 'none',
            letterSpacing: '0',
            ':hover': {
              color: 'var(--button-textual-text-hover-color)',
              backgroundColor: 'transparent',
            },
          },
        },
      ],
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: '22px',
          borderRadius: '3px',
          fontSize: '12px',
          color: 'var(--chip-text-color)',
          borderColor: 'var(--chip-border-color)',
          borderWidth: '1px',
          borderStyle: 'solid',
          backgroundColor: 'var(--chip-color)',
          span: {
            padding: '0px 8px',
          },
          svg: {
            fontSize: '1.25em !important',
            color: 'var(--chip-delete-icon-color) !important',
          },
          transition: 'none'
        },
      },
      variants: [
        {
          props: { variant: 'info' },
          style: {
            border: 0,
            color: 'var(--chip-info-text-color)',
            backgroundColor: 'var(--chip-info-color)',
          },
        },
        {
          props: { variant: 'neutral' },
          style: {
            border: 0,
            color: 'var(--chip-neutral-text-color)',
            backgroundColor: 'var(--chip-neutral-color)',
          },
        },
        {
          props: { variant: 'success' },
          style: {
            border: 0,
            color: 'var(--chip-success-text-color)',
            backgroundColor: 'var(--chip-success-color)',
          },
        },
      ],
    },
    MuiToggleButtonGroup: {
     styleOverrides: {
      root: {
        backgroundColor: 'var(--toggle-button-group-background-color)',
        padding: '4px',
        gap: '4px'
      }
     } 
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: 'var(--toggle-button-color)',
          backgroundColor: 'transparent',
          border: 0,
          height: '34px',
          borderRadius: '3px !important',
          '&.Mui-selected': {
            backgroundColor: 'white',
            boxShadow: '0 0 4px 0 rgb(0 0 0 / 33%)',
            ':hover': {
              backgroundColor: 'var(--toggle-button-background-color)',
            },
          },
          ':hover': {
            backgroundColor: 'var(--toggle-button-background-color)',
          },
        }
      }
    }
  },
  typography: {
    fontFamily: '"Mulish", sans-serif',
    fontSize: 14,
  },
});
