import { AttachMoney, Percent, SubdirectoryArrowRightOutlined } from "@mui/icons-material";
import { Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { CustomerReportModel, StressIncrementType } from "../../../../../../Generated/Commodity-Management-Api";
import { GroupingOption } from "../../../../../../Types";
import { StoneXDivider, StoneXRow } from "../../../../../StoneX";
import { StoneXAutocomplete, StoneXModal, StoneXMultiselect, StoneXSelect, StoneXTextField } from "../../../../../StoneXMui";
import { StoneXTransferList } from "../../../../../StoneXMui/StoneXTransferList/StoneXTransferList";
import { difference, intersection } from "lodash";
import { useApplicationDispatch } from "../../../../../../Redux/ReduxStore";
import { setCustomerReportUserOverride } from "../../../../../../Redux/Slices/CommodityManagementSlice";
import { CommodityManagementApi } from "../../../../../../Apis/Apis";
import { ScenarioColumns, ScenarioReportColumn, ScenarioReportConfiguration, ScenarioReportSeries, ScenarioReportUserOverride, ScenarioSeries } from "./Types";

interface ScenarioPositionReportSettingsModalProps {
    customerReport: CustomerReportModel;
    open: boolean;
    onClose: () => void;
    onSave: (customerReport: CustomerReportModel) => void;
}

export default function VarianceReportSettingsModal(props: ScenarioPositionReportSettingsModalProps) {

    const { customerReport, open, onClose, onSave } = props;
    const dispatch = useApplicationDispatch();

    const reportConfiguration = useMemo(() => JSON.parse(customerReport.configuration!) as ScenarioReportConfiguration, [customerReport]);
    const [ userOverrides, setUserOverrides ] = useState<ScenarioReportUserOverride | undefined>(customerReport.userOverrides ? JSON.parse(customerReport.userOverrides) : undefined);
    useEffect(updateUserOverrides, [customerReport]);
    
    const [ hiddenColumns, setHiddenColumns ] = useState<ScenarioReportColumn[]>([]);
    const [ activeColumns, setActiveColumns ] = useState<ScenarioReportColumn[]>([]);
    const [ modifiedActiveColumns, setModifiedActiveColumns ] = useState<ScenarioReportColumn[]>();
    useEffect(updateColumns, [reportConfiguration, userOverrides]);
    
    const [ hiddenSeries, setHiddenSeries ] = useState<ScenarioReportSeries[]>([]);
    const [ activeSeries, setActiveSeries ] = useState<ScenarioReportSeries[]>([]);
    const [ modifiedActiveSeries, setModifiedActiveSeries ] = useState<ScenarioReportSeries[]>();
    useEffect(updateSeries, [reportConfiguration, userOverrides]);
    
    const columnOrderMap = useMemo<{[key: string]: number}>(() => {
      const keys = Object.keys(ScenarioColumns);
      const map: {[key: string]: number} = {};
      keys.forEach((value: string, index) => map[value] = index);
      return map; 
    }, []);
    
    const seriesOrderMap = useMemo<{[key: string]: number}>(() => {
      const keys = Object.keys(ScenarioSeries);
      const map: {[key: string]: number} = {};
      keys.forEach((value: string, index) => map[value] = index);
      return map; 
    }, []);

    function save() {
      const activeColumnsToSave = modifiedActiveColumns ?? activeColumns;
      const activeSeriesToSave = modifiedActiveSeries ?? activeSeries;

      const userSettings = {
        columns: {
          active: activeColumnsToSave,
        },
        chart: {
          series: {
            active: activeSeriesToSave
          }
        }
      } as ScenarioReportUserOverride;

      const userOverrides = JSON.stringify(userSettings);

      CommodityManagementApi.updateCustomerReportUserOverrides(undefined, customerReport.customerReportId, userOverrides);

      dispatch(
        setCustomerReportUserOverride({
          customerReportId: customerReport.customerReportId, 
          userOverrides: userOverrides
        })
      )

      onSave({...customerReport, userOverrides: userOverrides});
    }
    
    function updateUserOverrides() {
      setUserOverrides(customerReport.userOverrides ? JSON.parse(customerReport.userOverrides) as ScenarioReportUserOverride : undefined)
    }

    function updateColumns() {
      const availableColumns = reportConfiguration.columns.available ?? [];
      const activeColumns = userOverrides?.columns?.active ?? reportConfiguration.columns.active ?? [];
      const hiddenColumns = difference(availableColumns, activeColumns);
      setHiddenColumns(hiddenColumns);
      setActiveColumns(activeColumns);
    }

    function updateSeries() {
      const availableSeries = reportConfiguration.chart.series.available ?? [];
      const activeSeries = userOverrides?.chart.series?.active ?? reportConfiguration.chart.series.active ?? [];
      const hiddenSeries = difference(availableSeries, activeSeries);
      setHiddenSeries(hiddenSeries);
      setActiveSeries(activeSeries);
    }

    function reset() {
      setModifiedActiveColumns([]);
      setModifiedActiveSeries([]);
    }

    function resetToDefaultConfiguration() {
      // Reset to customer settings. Empty the overrides
      reset();
      updateColumns();
    }

    function close() {
      resetToDefaultConfiguration();
      onClose();
    }

    function onColumnTransferListUpdate(hiddenItems: ScenarioReportColumn[], activeItems: ScenarioReportColumn[]) {
      setModifiedActiveColumns(activeItems);
    }
    
    function onSeriesTransferListUpdate(hiddenItems: ScenarioReportSeries[], activeItems: ScenarioReportSeries[]) {
      setModifiedActiveSeries(activeItems);
    }

    return (
        <StoneXModal header={customerReport.reportName + ' Report Configuration'} width='850px' open={open} onClose={onClose}>
          
          <h2>Columns</h2>

          <p>Click on one or multiple items to select, then use the arrows to move. </p>

          <StoneXRow>
            <StoneXTransferList 
              leftList={hiddenColumns} 
              leftHeader="Available Columns"
              rightList={activeColumns}
              rightHeader="Active Columns"
              getId={x => ScenarioColumns[x].colId}
              getGroup={x => ScenarioColumns[x].parentHeader}
              getSortingOrder={x => columnOrderMap[x]}
              getOptionLabel={x => ScenarioColumns[x].columnName ?? ScenarioColumns[x].headerName ?? x}
              onUpdate={onColumnTransferListUpdate}
            />
          </StoneXRow>

          <h2>Chart Series</h2>

          <p>Click on one or multiple items to select, then use the arrows to move. </p>

          <StoneXRow>
            <StoneXTransferList 
              leftList={hiddenSeries} 
              leftHeader="Available Series"
              rightList={activeSeries}
              rightHeader="Active Series"
              getId={x => ScenarioSeries[x].seriesId}
              getGroup={x => ScenarioSeries[x].parentHeader}
              getSortingOrder={x => seriesOrderMap[x]}
              getOptionLabel={x => ScenarioSeries[x].columnName ?? ScenarioSeries[x].headerName ?? x}
              onUpdate={onSeriesTransferListUpdate}
            />
          </StoneXRow>

          <StoneXRow justify="end" marginDirection='top'>
            <Button variant="outlined" onClick={close}> Cancel </Button>
            <Button variant="outlined" onClick={resetToDefaultConfiguration}> Reset To Default Configuration </Button>
            <Button onClick={save}> Set </Button>
          </StoneXRow>
        </StoneXModal>
    );
}
    
