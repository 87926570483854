import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { StoneXLabeledItem } from '..';
import { useEffect, useMemo, useState } from 'react';
import { numberToMonth } from '../../../Helpers';

interface StoneXMonthDropdownProps {
  value?: number | null | undefined;
  label?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  width?: string | number;
  onChange?: (month: number | null) => void;
}

export function StoneXMonthDropdown(props: StoneXMonthDropdownProps) {
  const { value, label, disabled, fullWidth, onChange } = props;
  const [month, setMonth] = useState<number | string | undefined>(value == null ? '' : value);
  const width = useMemo(() => (props.width === 'small' ? '186px' : props.width), [props.width]);

  function onMonthChange(event: SelectChangeEvent<number | string>) {
    if (!props.onChange) {
      return;
    }

    const fieldValue = event.target.value;

    if (fieldValue) {
      const month = typeof fieldValue === 'string' ? parseInt(fieldValue) : (fieldValue as number);
      setMonth(month);
      props.onChange(month);
    } else {
      setMonth('');
      props.onChange(null);
    }
  }

  useEffect(() => {
    setMonth(value == null ? '' : value);
  }, [value]);

  return (
    <StoneXLabeledItem label={props.label} fullWidth={props.fullWidth}>
      <div style={{ display: 'flex', gap: '8px', width: width }}>
        <Select sx={{ width: '100%' }} value={month} onChange={onMonthChange}>
          <MenuItem value={1}>{numberToMonth(1)}</MenuItem>
          <MenuItem value={2}>{numberToMonth(2)}</MenuItem>
          <MenuItem value={3}>{numberToMonth(3)}</MenuItem>
          <MenuItem value={4}>{numberToMonth(4)}</MenuItem>
          <MenuItem value={5}>{numberToMonth(5)}</MenuItem>
          <MenuItem value={6}>{numberToMonth(6)}</MenuItem>
          <MenuItem value={7}>{numberToMonth(7)}</MenuItem>
          <MenuItem value={8}>{numberToMonth(8)}</MenuItem>
          <MenuItem value={9}>{numberToMonth(9)}</MenuItem>
          <MenuItem value={10}>{numberToMonth(10)}</MenuItem>
          <MenuItem value={11}>{numberToMonth(11)}</MenuItem>
          <MenuItem value={12}>{numberToMonth(12)}</MenuItem>
        </Select>
      </div>
    </StoneXLabeledItem>
  );
}
