import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

interface StoneXFileUploaderProps {
  message?: string;
  onUpload: (file: File) => void;
}

export function StoneXFileUploader(props: StoneXFileUploaderProps) {
  const { message, onUpload } = props;

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    setSelectedFile(file);
    if (file) {
      onUpload(file);
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files ? event.dataTransfer.files[0] : null;
    setSelectedFile(file);
    if (file) {
      onUpload(file);
    }
  };

  return (
    <Box
      sx={{
        border: isDragging ? '2px dashed #3f51b5' : '2px dashed #ccc',
        padding: '20px',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: isDragging ? '#f0f0f0' : '#fafafa',
        borderRadius: '4px',
        transition: 'background-color 0.3s ease',
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {message}
      <br />
      <input type="file" id="fileUpload" style={{ display: 'none' }} onChange={handleFileChange} />
      <label htmlFor="fileUpload">
        <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />} sx={{ margin: '10px 0px' }}>
          Choose File
        </Button>
      </label>

      <br />

      {selectedFile ? selectedFile.name : 'Drag and drop a file here, or click to select a file'}
    </Box>
  );
}

export default StoneXFileUploader;
