import { sum } from 'lodash';
import { ComponentType, ScenarioVarianceModel } from '../../../../../../Generated/Commodity-Management-Api';
import { ModelToRowTransformer } from '../../../../../../Helpers/ModelToRowTransformer';
import { ScenarioReportRow } from './Types';

export class ScenarioVarianceModelToRowTransformer extends ModelToRowTransformer {
  static scenarioVarianceModelToRow(scenarioVariance: ScenarioVarianceModel, groupByCommodity: boolean): ScenarioReportRow[] {
    return scenarioVariance.commodityScenarios.map((y) => {
      return {
        path: groupByCommodity
          ? [scenarioVariance.stressIncrement, y.commodity?.commodityName ?? '', y.budgetDate?.label]
          : [scenarioVariance.stressIncrement, y.budgetDate?.label],
        currency: y.variance?.currency,
        stressIncrement: scenarioVariance.stressIncrement,
        stressIncrementType: scenarioVariance.stresIncrementType,
        commodity: y.commodity,
        effectiveBudgetVolume: y.variance?.effectiveBudgetVolume,
        budgetVolume: y.variance?.budgetVolume,
        budgetPrice: y.variance?.budgetPrice,
        budgetValue: y.variance?.budgetValue,
        marketPrice: y.variance?.marketPrice,
        marketValue: y.variance?.marketValue,
        openVolume: 0, // Todo: How do you determine all in open volume? Can you?
        underlyingHedgedVolume: y.variance?.components?.find((c) => c.componentType == ComponentType.Underlying)?.hedgedVolume,
        blendedPrice: y.variance?.blendedPrice,
        blendedValue: y.variance?.blendedValue,
        varianceToBudgetPrice: y.variance?.varianceToBudgetPrice,
        varianceToBudgetValue: y.variance?.varianceToBudgetValue,
        hasWhatIfPositions: !!y.whatIfPositionsVariance,
        whatIfUnderlyingHedgedVolume: y.whatIfPositionsVariance?.components?.find((c) => c.componentType == ComponentType.Underlying)?.hedgedVolume,
        whatIfBlendedPrice: y.whatIfPositionsVariance?.blendedPrice,
        whatIfBlendedValue: y.whatIfPositionsVariance?.blendedValue,
        whatIfVarianceToBudgetPrice: y.whatIfPositionsVariance?.varianceToBudgetPrice,
        whatIfVarianceToBudgetValue: y.whatIfPositionsVariance?.varianceToBudgetValue,
      } as ScenarioReportRow;
    });
  }

  static aggregateScenarioReportRows(rows: ScenarioReportRow[], path: string[]): ScenarioReportRow {
    // Need to know current grouping & parent groupings

    // Certain fields can only be aggregated if one of the parent groupByOptions is 'commodity'
    // And would also have to assumes units & currencies are the same

    const isSameCommodity = super.areAllPropertiesSame(rows, 'commodity');
    const isSameCurrency = super.areAllPropertiesSame(rows, 'currency'); // Should throw an error if not the same...?
    const isSameStressIncrement = super.areAllPropertiesSame(rows, 'stressIncrement') && super.areAllPropertiesSame(rows, 'stressIncrementType');

    return {
      path: path,
      isAggregated: true,
      currency: isSameCurrency ? rows[0]?.currency : undefined,
      commodity: isSameCommodity ? rows[0]?.commodity : undefined,
      stressIncrement: isSameStressIncrement ? rows[0]?.stressIncrement : undefined,
      stressIncrementType: isSameStressIncrement ? rows[0]?.stressIncrementType : undefined,
      budgetVolume: isSameCommodity ? sum(rows.map(x => x.budgetVolume)) : undefined,
      budgetPrice: isSameCommodity ? super.weightedAverage(rows, x => x.budgetPrice, x => x.effectiveBudgetVolume) : undefined,
      budgetValue: sum(rows.map(x => x.budgetValue)),
      marketPrice: isSameCommodity ? super.weightedAverage(rows, x => x.marketPrice, x => x.openVolume) : undefined, // If total open volume is 0, then this is weighted on budget volume?
      marketValue: sum(rows.map(x => x.marketValue)),
      underlyingHedgedVolume: isSameCommodity ? sum(rows.map(x => x.underlyingHedgedVolume)) : undefined,
      blendedPrice: isSameCommodity ? super.weightedAverage(rows, x => x.blendedPrice, x => x.effectiveBudgetVolume) : undefined, // This used to be weighted by component volume, but that doesn't make sense
      blendedValue: sum(rows.map(x => x.blendedValue)),
      varianceToBudgetPrice: isSameCommodity ? super.weightedAverage(rows, x => x.varianceToBudgetPrice, x => x.effectiveBudgetVolume) : undefined, // Weighting by effective budget volume (actual volume or budget volume)
      varianceToBudgetValue: sum(rows.map(x => x.varianceToBudgetValue)),
      hasWhatIfPositions: rows.filter(x => x.hasWhatIfPositions).length > 0,
      whatIfUnderlyingHedgedVolume: isSameCommodity ? sum(rows.map(x => x.whatIfUnderlyingHedgedVolume)) : undefined,
      whatIfBlendedPrice: isSameCommodity ? super.weightedAverage(rows, x => x.whatIfBlendedPrice, x => x.effectiveBudgetVolume) : undefined, // This used to be weighted by component volume, but that doesn't make sense
      whatIfBlendedValue: sum(rows.map(x => x.whatIfBlendedValue)),
      whatIfVarianceToBudgetPrice: isSameCommodity ? super.weightedAverage(rows, x => x.whatIfVarianceToBudgetPrice, x => x.effectiveBudgetVolume) : undefined, // Weighting by effective budget volume (actual volume or budget volume)
      whatIfVarianceToBudgetValue: sum(rows.map(x => x.whatIfVarianceToBudgetValue)),
    } as ScenarioReportRow;
  }
}
