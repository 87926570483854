import { useEffect, useState } from "react";
import { StoneXNumber } from "../../../../../StoneXMui";
import { sum } from "lodash";
import { VarianceReportRow } from "./Types";

export interface VarianceReportSummaryDisplayProps
{
    variances: VarianceReportRow[];
    quoteDateString: string;
}

export default function VarianceReportSummaryDisplay(props: VarianceReportSummaryDisplayProps) 
{
    const { variances, quoteDateString } = props;

    const [totalVarianceToMarket, setTotalVarianceToMarket] = useState<number>();
    const [totalVarianceToBudget, setTotalVarianceToBudget] = useState<number>();

    function updateTotals() {
        setTotalVarianceToMarket(sum(variances.map(x => x.varianceToMarketValue ?? 0)));
        setTotalVarianceToBudget(sum(variances.map(x => x.varianceToBudgetValue ?? 0)));
    }

    useEffect(updateTotals, [variances]);

    return (
        <div className="pull-right">
            <div className="metric">
                <span className="metric-label"> Total VTM | {quoteDateString} </span> 
                <span className="metric-value">{<StoneXNumber number={totalVarianceToMarket} currency='USD' colored/>}</span>
            </div>
            <div className="metric">
                <span className="metric-label"> Total VTB | {quoteDateString} </span> 
                <span className="metric-value">{<StoneXNumber number={totalVarianceToBudget} currency='USD' colored/>}</span>
            </div>
      </div>
    )
}