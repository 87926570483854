import { MoreVert } from '@mui/icons-material';
import { IconButton, Popper, ClickAwayListener, Paper, MenuList, MenuItem, MenuItemProps, IconButtonProps } from '@mui/material';
import { useState, useRef, ReactElement } from 'react';

interface StoneXPopupMenuProps {
  iconButtonProps?: IconButtonProps;
  children: ReactElement<MenuItemProps>[] | ReactElement<MenuItemProps>;
}

export function StoneXPopupMenu(props: StoneXPopupMenuProps) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  return (
    <>
      <IconButton {...props.iconButtonProps} ref={anchorRef} sx={{":hover": {borderRadius: '25px'}}} onClick={() => setOpen(true)}>
        <MoreVert />
      </IconButton>

      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end">
        <ClickAwayListener onClickAway={() => setOpen(false)}>
          <Paper>
            <MenuList autoFocusItem={open}>{props.children}</MenuList>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
