import { Clear } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { Checkbox,Chip } from '@mui/material';
import Autocomplete,{ AutocompleteRenderGetTagProps } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SyntheticEvent, useState } from 'react';
import { StoneXLabeledItem } from '..';

interface PropsType<TItem> {
  options: TItem[];
  loading?: () => boolean;
  getOptionLabel: (q: TItem) => string;
  onChange?: (event: SyntheticEvent<Element, Event>, value: TItem[]) => void;
  label?: string;
  dropdownAutoWidth?: boolean;
  listKey?: React.Key | null | undefined;
  groupBy?: ((option: TItem) => string) | undefined;
  variant?: undefined | 'standard';
  width?: string | number;
  name?: string;
  placeholder?: string;
  disabled?: boolean;
  value: TItem[];
  getOptionDisabled?: ((option: TItem) => boolean) | undefined;
  disableClearable?: boolean;
  multiple?: boolean;
}

export function StoneXMultiAutocomplete<TItem>(props: PropsType<TItem>) {

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <>
      <StoneXLabeledItem label={props.label}>
        <Autocomplete
          multiple
          disableCloseOnSelect
          options={props.options}
          getOptionDisabled={props.getOptionDisabled}
          getOptionLabel={props.getOptionLabel}
          onChange={props.onChange}
          value={props.value}
          renderOption={(innerProps, option, { selected }) => (
            <li {...innerProps}>
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {props.getOptionLabel(option)}
            </li>
          )}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                inputProps={{ ...params.inputProps, sx: { height: '22px !important', paddingTop: '0 !important', paddingBottom: '0 !important' } }}
                name={props.name}
                variant={props.variant}
              />
            );
          }}
          renderTags={(items: TItem[], getTagProps: AutocompleteRenderGetTagProps) =>
            items.map((item, index) => <Chip label={props.getOptionLabel(item)} deleteIcon={<Clear fontSize='small'/>} {...getTagProps({index})} />)
          }
        />
      </StoneXLabeledItem>
    </>
  );
}
