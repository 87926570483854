import { CircularProgress, CircularProgressProps } from '@mui/material';
import './StoneXLoading.scss';

interface PropsType extends CircularProgressProps {
  show?: boolean;
  small?: boolean;
}

function StoneXLoading(props: PropsType) {

  return props.show ? <CircularProgress size={props.small ? '1em' : undefined} /> : <></>;

  // return props.show ? (
  //   <div className="snx-container">
  //     <div className="snx-grid">
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //       <div></div>
  //     </div>
  //   </div>
  // ) : null;
}

export default StoneXLoading;