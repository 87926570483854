import { Button,ButtonGroup } from '@mui/material';
import { ColDef, GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React,{ useEffect,useRef,useState } from 'react';
import { CommodityManagementApi } from '../../../../Apis/Apis';
import { PortfolioModel } from '../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../Hooks';
import useActiveApplication from '../../../../Hooks/CommodityManagement/useActiveApplication';
import { StoneXRow } from '../../../StoneX';
import { StoneXModal,StoneXTextField } from '../../../StoneXMui';

interface PortfolioSettingsProps {
  portfolios: PortfolioModel[];
}

export default function PortfolioSettings(props: PortfolioSettingsProps) {
  const [portfolios, setPortfolios] = useState<PortfolioModel[]>(props.portfolios);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalPortfolio, setModalPortfolio] = useState<PortfolioModel | undefined>();
  const applicationGuid = useActiveApplication();
  const savePortfolioLoadingState = useLoadingState();
  const gridRef = useRef<AgGridReact<PortfolioModel>>(null);

  const columnDefs: ColDef[] = [
    {
      headerName: 'Portfolio Name',
      field: 'portfolioName',
      sortable: true,
      editable: true,
      flex: 1,
    },
    {
      headerName: 'Actions',
      field: 'actions',
      width: 150,
      cellRenderer: (params: any) => {
        return (
          <ButtonGroup variant="text">
            <Button onClick={() => updatePortfolio(params.data)}>Edit</Button>
            <Button onClick={() => deletePortfolio(params.data)}>Delete</Button>
          </ButtonGroup>
        );
      },
    },
  ];

  function savePortfolio(portfolioToSave: Partial<PortfolioModel>) {

    if (portfolioToSave.portfolioId) {
      //add
        CommodityManagementApi.addPortfolio(savePortfolioLoadingState.setLoadingState, portfolioToSave.portfolioName!)
        .then((res) => setPortfolios((previous) => [...previous, res.data.portfolio as PortfolioModel]));

    } else {
      CommodityManagementApi.updatePortfolio(
        savePortfolioLoadingState.setLoadingState,
        portfolioToSave.portfolioId!.toString(),
        portfolioToSave.portfolioName!,
      ).then((res) => {
        setPortfolios((previous) => previous.map((portfolio) => (portfolio.portfolioId == res.data.portfolio?.portfolioId ? (res.data.portfolio as PortfolioModel) : portfolio)));
      });
    }

    
  }

  function deletePortfolio(portfolioToDelete: PortfolioModel) {
    const porfolioToDeleteId = portfolioToDelete.portfolioId!;
    CommodityManagementApi.deletePortfolio(null, porfolioToDeleteId.toString()).then(res => setPortfolios(previous => previous.filter(portfolio => portfolio.portfolioId != porfolioToDeleteId)))
  }

  useEffect(() => {
    // console.log('applying transaction', portfolios)
    // gridRef?.current?.api?.applyTransaction({ update: portfolios });
  }, [portfolios]);

  function addPortfolio() {
    setModalPortfolio(undefined);
    setModalOpen(true);
  }

  function updatePortfolio(portfolio: PortfolioModel) {
    setModalPortfolio(portfolio);
    setModalOpen(true);
  }

  return (
    <>
      <StoneXRow align="end">
        <h3>Portfolios</h3>
        <Button className="pull-right" variant="outlined" onClick={addPortfolio}>
          Add
        </Button>
      </StoneXRow>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact 
        ref={gridRef}
        getRowId={(params: GetRowIdParams) => params.data.portfolioId}
         enableRangeSelection columnDefs={columnDefs} domLayout="autoHeight" rowData={portfolios} />
      </div>
      <UpdatePortfolioModal open={modalOpen} onClose={() => setModalOpen(false)} onPortfolioSave={savePortfolio} portfolio={modalPortfolio} />
    </>
  );
}

interface UpdatePortfolioProps {
  open: boolean;
  onClose: () => void;
  onPortfolioSave: (portfolio: Partial<PortfolioModel>) => void;
  portfolio?: PortfolioModel;
}

function UpdatePortfolioModal(props: UpdatePortfolioProps) {
  const { open, onClose, onPortfolioSave, portfolio } = props;
  const [portfolioName, setPortfolioName] = useState<string>();
  const actionLabel = props.portfolio ? 'Update' : 'Add';

  useEffect(() => {
    setPortfolioName(portfolio?.portfolioName ?? '');
  }, [portfolio])

  function updatePortfolio() {
    //todo: validate

    onPortfolioSave({
      portfolioId: portfolio?.portfolioId ?? 0,
      portfolioName: portfolioName,
    });

    onClose();
  }

  return (
    <StoneXModal header={actionLabel + ' Portfolios'} open={open} onClose={onClose}>
      <StoneXRow>
        <StoneXTextField label="Portfolio Name" value={portfolioName} onChange={(event: any) => setPortfolioName(event.target.value)} />
      </StoneXRow>

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={updatePortfolio}>{actionLabel + ' Portfolio'}</Button>
      </StoneXRow>
    </StoneXModal>
  );
}
