import { ColDef, ColGroupDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReactProps } from 'ag-grid-react';
import { last } from 'lodash';
import { useEffect, useState } from 'react';
import { StressIncrementType } from '../../../../../../Generated/Commodity-Management-Api';
import { StoneXGrid } from '../../../../../StoneX';
import { StoneXNumber } from '../../../../../StoneXMui';
import { ScenarioColumns, ScenarioReportColumn, ScenarioReportRow } from './Types';

interface ScenarioTableProps {
  rows: ScenarioReportRow[];
  activeColumns: ScenarioReportColumn[];
}

export default function ScenarioTable(props: ScenarioTableProps) {
  const { rows, activeColumns } = props;

  const [columns, setColumns] = useState<(ColDef<ScenarioReportRow> | ColGroupDef<ScenarioReportRow>)[]>();

  function updateColumns() {
    if (!activeColumns) {
      setColumns([]);
      return;
    }

    const columnDefs: (ColDef<ScenarioReportRow> | ColGroupDef<ScenarioReportRow>)[] = [];

    const hasWhatIfPositions = rows.filter(x => x.hasWhatIfPositions).length > 0;
    console.log('has what if positions?', hasWhatIfPositions);

    for (let i = 0; i < activeColumns.length; i++) {
      const previousColumn = i == 0 ? undefined : ScenarioColumns[activeColumns[i - 1]];
      const column = ScenarioColumns[activeColumns[i]];
      const columnDef = column as ColDef<ScenarioReportRow>;

      if (!hasWhatIfPositions && column.isWhatIfColumn) {
        continue;
      }

      console.log('has waht if positions', hasWhatIfPositions);

      if (!hasWhatIfPositions) {
        // No need to include the parent headers. Set them to undefined
        column.parentHeader = undefined;
      }

      if (column.parentHeader) {
        // If the previous column has the same parent header, add it under that
        if (column.parentHeader === previousColumn?.parentHeader) {
          (last(columnDefs) as ColGroupDef<ScenarioReportRow>).children.push(columnDef);
        } else {
          // Add it as a new parent header
          columnDefs.push({
            headerName: column.parentHeader,
            children: [columnDef],
          });
        }
      } else {
        columnDefs.push(columnDef);
      }
    }

    setColumns(columnDefs);
  }

  function stressIncrementRenderer(params: ICellRendererParams<ScenarioReportRow, any>) {
    if (params.node.level == 0) {
      if (params.data?.stressIncrementType == StressIncrementType.Percent) {
        return <StoneXNumber number={params.value} colored flipped standardNotation percent />
      }
      if (params.data?.stressIncrementType == StressIncrementType.Price) {
        return <StoneXNumber number={params.value} colored flipped standardNotation currency={params.data.currency} />
      }
    }
    return <>{params.value}</>
  }

  useEffect(updateColumns, [activeColumns]);

  const gridOptions = {
    treeData: true,
    getDataPath: (x: ScenarioReportRow) => x.path,
    autoGroupColumnDef: {
        headerName: 'Stress Increment'
    }
  } as AgGridReactProps;

  return (
    <div className="ag-theme-alpine" style={{ width: 'auto' }}>
      <StoneXGrid columns={columns!} rows={rows} expandCollapseRowCondition={(x) => x.data !== undefined} groupCellInnerRenderer={stressIncrementRenderer} groupColumnWidth={250} gridOptions={gridOptions}/>
    </div>
  );
}
