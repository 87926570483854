type Environment = 'local' | 'development' | 'staging' | 'qa' | 'production';

interface ApplicationSettings {
  applicationName: string;
  applicationVersion: number;
  displayName: string;
  environment: Environment;
  isDevelopmentLike: boolean;
  ravenEnvironment: string;
  api?: {
    // ravenBackendUri: string;
    ravenBackendMarketViewUri: string;
    ravenBackendCommodityManagementUri: string;
  };
  applicationInsightsInstrumentKey?: string;
  okta: {
    issuer?: string;
    clientId?: string;
    redirectUri?: string;
    postLogoutRedirectUri?: string;
    scopes: string[];
  };
}

const environment = (process.env.REACT_APP_ENVIRONMENT_NAME as Environment) ?? 'local';

const applicationSettings: ApplicationSettings = {
  applicationName: 'KnowRisk 4',
  applicationVersion: 1,
  displayName: 'KnowRisk',
  environment,
  // eslint-disable-next-line no-nested-ternary
  ravenEnvironment: environment === 'development' || environment === 'local' ? 'development' : environment === 'production' ? 'production' : 'staging',
  isDevelopmentLike: environment !== 'production',
  // General settings, please alphabetize this.
  api: {
    ravenBackendMarketViewUri: process.env.REACT_APP_RAVEN_BACKEND_MARKET_VIEW_URI as string,
    ravenBackendCommodityManagementUri: process.env.REACT_APP_RAVEN_BACKEND_COMMODITY_MANAGEMENT_URI as string,
  },
  applicationInsightsInstrumentKey: process.env.REACT_APP_APPLICATION_INSIGHTS_INSTRUMENT_KEY,
  okta: {
    issuer: process.env.REACT_APP_OKTA_ISSUER,
    clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
    redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
    postLogoutRedirectUri: process.env.REACT_APP_OKTA_POST_LOGOUT_URI,
    scopes: ['openid', 'profile', 'email', 'phone', 'offline_access', 'Identity.Api'],
  },
};

// eslint-disable-next-line no-console
console.log(`RUNNING in ${applicationSettings.environment} Environment.`);

export default applicationSettings;
