import dayjs from "dayjs";
import { every, get, groupBy, isEqual, uniqWith } from "lodash";
import { GridRow, GroupingOption } from "../Types";
import { AllotmentEntity } from "../Types/AllotmentEntity";
import { LocationModel } from "../Generated/Commodity-Management-Api";

// This was meant to be a common class between position & budgets.
// But budgets had a differnet set of requiremnets so it is only being used for positions at the moment.

export class ModelToRowTransformer {

    // Should move to a math class. 
    static weightedAverage<T>(items: T[], fieldSelector: (a: T) => number | null | undefined, totalSelector: (a: T) => number | null | undefined): number {
        const { totalWeightedValue, totalWeight } = items.reduce(
          (acc, item) => {
            const value = fieldSelector(item) ?? 0;
            const weight = totalSelector(item) ?? 0;
            acc.totalWeightedValue += value * weight;
            acc.totalWeight += weight;
            return acc;
          },
          { totalWeightedValue: 0, totalWeight: 0 },
        );
      
        // Return the weighted average, ensuring no division by zero
        const weightedValue = totalWeight === 0 ? 0 : totalWeightedValue / totalWeight;
      
        return weightedValue;
      
        //   return {
        //     total: totalWeight,
        //     weightedValue: weightedValue
        //   };
    }
    
    // Function to check if all properties are the same
    static areAllPropertiesSame<T>(arr: T[], property: keyof T): boolean {
    // Get the value of the first item's property
    const firstValue = get(arr[0], property);
    
    // Check if all other properties match the first value
    return every(arr, (item) => isEqual(get(item, property), firstValue));
    }

    static convertModelToTableRow<T extends AllotmentEntity>(model: T): T & {path: string[]}
    {
        const tableRow = 
        {
            path: [],
            ...model
        };
    
        return tableRow;
    }
    
    static updatePaths<T extends GridRow>(rows: T[], groupingOptions: GroupingOption[])
    {
        for (let i = 0; i < rows.length; i++) {
            // Update path for each item.
    
            rows[i].path = [];
    
            for (let j = 0; j < groupingOptions.length; j++) {
    
                // Get path's node value
                const groupingPathValue = this.getGroupingValue(rows[i], groupingOptions[j]);
    
                if (Array.isArray(groupingPathValue)) 
                {
                    rows[i].path.push(...groupingPathValue);
                } else 
                {
                    rows[i].path.push(groupingPathValue);
                }
            }
        }
    }
    
    static getAggregatedRows<T extends GridRow>(rows: T[], depth: number, aggregationFunction: (rows: T[], path: string[]) => T) : T[]
    {
        const aggregatedRows: T[] = [];
    
        // Depth is how many levels into the path to aggregate
        for (let d = 0; d < depth; d++) {
    
            const uniquePathsAtCurrentDepth = uniqWith(rows.map((x) => x.path.slice(0, d + 1)), isEqual);
    
            for (let i = 0; i < uniquePathsAtCurrentDepth.length; i++) {
                const pathToAggregate = uniquePathsAtCurrentDepth[i];
                const rowsToAggregate = rows.filter((x) => isEqual(x.path.slice(0, d + 1), pathToAggregate));
                const aggregatedRow = aggregationFunction(rowsToAggregate, pathToAggregate);
                aggregatedRows.push(aggregatedRow);
            }
        }
    
        return aggregatedRows;
    }
    
    static getAggregatedRowsGrouped<T extends GridRow>(rows: T[], depth: number, aggregationFunction: (rows: T[], path: string[]) => T, groupingFunction: (rows: T) => string, pathLabel: string) : T[]
    {
        const aggregatedRows: T[] = [];
    
        // Depth is how many levels into the path to aggregate
        for (let d = 0; d < depth; d++) {
    
            const uniquePathsAtCurrentDepth = uniqWith(rows.map((x) => x.path.slice(0, d + 1)), isEqual);
    
            for (let i = 0; i < uniquePathsAtCurrentDepth.length; i++) {
                const pathToAggregate = uniquePathsAtCurrentDepth[i];
                const rowsToAggregate = rows.filter((x) => isEqual(x.path.slice(0, d + 1), pathToAggregate));
    
                const rowsToAggregateGrouped = groupBy(rowsToAggregate, groupingFunction);
    
                Object.entries(rowsToAggregateGrouped).forEach(([key, rows]) => {
                    
                    const path = [...pathToAggregate, pathLabel, key];
                    const rowsInGroup = aggregationFunction(rows as T[], path);
    
                    aggregatedRows.push(rowsInGroup);
                })
            }
        }
    
        return aggregatedRows;
    }
    
    private static getGroupingValue<T extends GridRow>(row: T, groupingBy: GroupingOption): string | string[]
    {
        if (groupingBy == 'Commodity') {
            return row.commodity?.commodityName ?? "";
        }
    
        if (groupingBy == 'Counterparty') {
            return row.counterparty?.counterpartyName ?? "";
        }
    
        if (groupingBy == 'BudgetMonth') {
            const date = dayjs(`${row.budgetDate?.year}-${String(row.budgetDate?.month).padStart(2, '0')}-01`);
            return date.format('MMMM YYYY');
        }

        if (groupingBy == "BudgetYear") {
            return row.budgetDate?.year?.toString() ?? "";
        }
    
        if (groupingBy == 'ContractMonth') {
            const date = dayjs(`${row.effectiveContractDate?.year}-${String(row.effectiveContractDate?.month).padStart(2, '0')}-01`);
            return date.format('MMMM YYYY');
        }
    
        if (groupingBy == 'BudgetingTier') {
            return row.location?.locationName! ?? "";
        }
    
        if (groupingBy == 'Tiers') {
            // Convert the budgets location hierarchy to a path array
            // We reverse the order so that we're going top to bottom of the hierarchy
            // We also skip the first node since it will be one location anyway
            const result = row.locationHierarchy?.sort((a: LocationModel, b: LocationModel) => a.tierRank! - b.tierRank!).slice(1).map(x => x.locationName) ?? [];
            return result;
        }
    
        throw `Unhandled grouping option ${groupingBy}`;
    }
}