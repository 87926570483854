import { Box,FormControl } from '@mui/material';
import React,{ ReactNode } from 'react';

interface StoneXLabeledItemProps {
  width?: number | string;
  label?: string;
  inline?: boolean;
  showRequired?: boolean;
  children?: ReactNode;
  fullWidth?: boolean;
  fullHeight?: boolean;
}

export function StoneXLabeledItem(props: StoneXLabeledItemProps) {

  const width = props.fullWidth 
    ? '100%' 
    : props.width ?? 'auto';

  const height = props.fullHeight
    ? '100%'
    : 'auto';

  return (
    <FormControl sx={{ width: width, height: height, display: props.inline ? 'inline' : undefined }}>
      {props.label && <label className='label'>{props.label}{props.showRequired && (<span style={{ color: 'var(--red-number)', marginLeft: '2px' }}>*</span>)}</label>}
      {props.children ?? '\u00A0'}
    </FormControl>
  );
}
