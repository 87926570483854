import { Button } from '@mui/material';
import { uniq, uniqBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { BudgetModel, GetCustomerSettingsResponse, YearDataModel } from '../../../../../Generated/Commodity-Management-Api';
import { ContractDateHelper } from '../../../../../Helpers';
import { ModelToRowTransformer } from '../../../../../Helpers/ModelToRowTransformer';
import { useLoadingState } from '../../../../../Hooks';
import { GroupingOption } from '../../../../../Types';
import { StoneXDivider, StoneXLoading, StoneXRow } from '../../../../StoneX';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXSelectPills } from '../../../../StoneXMui';
import { BudgetRow } from '../Types';
import BudgetTable from './BudgetTable';
import dayjs from 'dayjs';

export default function ViewBudgetsPage() {

  const customerDataLoadingState = useLoadingState();
  const customeSettingsLoadingState = useLoadingState();
  const budgetYearsLoadingState = useLoadingState();
  const budgetsLoadingState = useLoadingState();

  const [ customerSettings, setCustomerSettings ] = useState<GetCustomerSettingsResponse>({});
  const [ years, setYears ] = useState<YearDataModel[]>([]);
  const [ budgets, setBudgets ] = useState<BudgetModel[]>([]);
  const [ selectedYears, setSelectedYears ] = useState<number[]>([]);
  const rows = useMemo(getReportRows, [budgets]);

  function getInitialBudgets() {
    const year = dayjs().year();
    var startDate = ContractDateHelper.getStartContractDate(year, customerSettings!.fiscalMonthOffset);

    CommodityManagementApi.listBudgets(
      budgetsLoadingState.setLoadingState,
      undefined,
      startDate.year,
      startDate.month,
    ).then((q) => {
      setBudgets(previous => uniqBy([...previous, ...q.data.rows ?? []], x => x.budgetId))
      const years = uniq(q.data.rows?.map(x => x.budgetDate.year!));
      setSelectedYears(years);
    });
  }

  function addYearBudgets(year: number) {
    var startDate = ContractDateHelper.getStartContractDate(year, customerSettings!.fiscalMonthOffset);
    var endDate = ContractDateHelper.addMonths(startDate, 11);

    CommodityManagementApi.listBudgets(
      budgetsLoadingState.setLoadingState,
      undefined,
      startDate.year,
      startDate.month,
      undefined,
      endDate.year,
      endDate.month,
    ).then((q) => {
      setBudgets(previous => uniqBy([...previous, ...q.data.rows ?? []], x => x.budgetId))
      setSelectedYears(previous => [...previous, year]);
    });
  }

  function removeYearBudgets(year: number) {
    const startDate = ContractDateHelper.getStartContractDate(year, customerSettings?.fiscalMonthOffset);
    const endDate = ContractDateHelper.addMonths(startDate, 12);
    setSelectedYears(previous => previous.filter(x => x != year));
    setBudgets(previous => previous.filter(x => !(ContractDateHelper.isGreaterThanOrEqualTo(x.budgetDate, startDate) && ContractDateHelper.isLessThanOrEqualTo(x.budgetDate, endDate))));
  }

  function getReportRows(): BudgetRow[] {
    const rows: BudgetRow[] =  budgets.map(x => ({path: [], ...x}) as BudgetRow);

    // Filter out undefined groupings
    // const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1]].filter(x => !!x).map(x => x!);
    const selectedGroupings: GroupingOption[] = ['BudgetYear'];

    // Update the path for each row based on groupings
    ModelToRowTransformer.updatePaths(rows, selectedGroupings);

    // Add budgetId to path to make each path unique
    rows.forEach(x => x.path.push(x.budgetId?.toString() ?? ''));

    return rows;
  }

  useEffect(() => {
    CommodityManagementApi.getCustomerSettings(customeSettingsLoadingState.setLoadingState).then((q) => setCustomerSettings(q.data));
    CommodityManagementApi.listBudgetYears(budgetYearsLoadingState.setLoadingState).then((q) => setYears(q.data.rows ?? []));
    getInitialBudgets();
  }, []);
  
  return (
    <StoneXMainPage>
      <StoneXRow>
        <h1>Budgets</h1>
        <NavLink to={'update'} className="pull-right">
          <Button>Add new budget</Button>
        </NavLink>
      </StoneXRow>

      <StoneXLoading show={customerDataLoadingState.isLoading()} />

      <StoneXRow>
        {/* <StoneXYearPills label="Years" years={years} onYearSelected={setSelectedYear} /> */}
        <StoneXSelectPills 
          label="Fiscal Year" 
          options={years.map(x => x.year)} 
          multiple={true}
          getOptionId={x => x} 
          getOptionLabel={x => `${x}`}
          getSortingValue={x => x} 
          value={selectedYears}
          onSelect={addYearBudgets}
          onDeselect={removeYearBudgets}
        />
      </StoneXRow>

      <StoneXDivider />

      <StoneXLoading show={budgetsLoadingState.isLoading()} />

      <BudgetTable budgets={rows}/>

      {budgetsLoadingState.isLoaded() && rows.length == 0 && <p>No budgets found. Please revise the search criteria.</p>}
    </StoneXMainPage>
  );
}
