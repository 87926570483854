import { Button, Stack } from '@mui/material';
import { uniq, without } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { StoneXAutocomplete, StoneXLabeledItem, StoneXMultiAutocomplete } from '..';
import './StoneXSelectPills.css';

interface StoneXSelectPillsBaseProps<TItem> {
  label?: string;
  options: TItem[];
  displayCount?: number;
  getOptionLabel: (q: TItem) => string;
  getOptionId: (q: TItem) => string | number;
  getSortingValue: (q: TItem) => number;
  onSelect?: (value: TItem) => void;
  onDeselect?: (value: TItem) => void;
}

interface StoneXSingleSelectPillsProps<TItem> extends StoneXSelectPillsBaseProps<TItem> {
  multiple?: false;
  value: TItem;
}

interface StoneXMultiSelectPillsProps<TItem> extends StoneXSelectPillsBaseProps<TItem> {
  multiple: true;
  value: TItem[];
}

type StoneXSelectPillsProps<TItem> = StoneXSingleSelectPillsProps<TItem> | StoneXMultiSelectPillsProps<TItem>;

export function StoneXSelectPills<TItem>(props: StoneXSelectPillsProps<TItem>) {

  const { label, options = [], displayCount = 3, value, multiple, getOptionLabel, getOptionId, getSortingValue, onSelect, onDeselect } = props;

  // const [ selectedOptions, setSelectedOptions ] = useState<TItem | TItem[] | null>(value ?? (multiple ? [] : null));
  const isNullOrEmpty = (object: any) => object === null || (Array.isArray(object) && object.length === 0);
  const [ showAll, setShowAll ] = useState<boolean>(false);
  const pillsToShow = useMemo(getOptionsToShow, [options, value]);

  // For pills
  function onToggle(optionId: string | number) {
    const isCurrentlySelected = multiple
      ? (value as TItem[]).map(getOptionId).findIndex(x => x == optionId) >= 0
      : getOptionId(value as TItem) == optionId;

    const item = options.find(x => getOptionId(x) == optionId);

    if (isCurrentlySelected) {
      onDeselectInternal(item!);
    }
    else {
      onSelectInternal(item!);
    }
  }

  // For single autocomplete
  function onChangeSingleInternal(item: TItem | null) {
    const current = value as TItem | null;
    if (current == item) {
      onDeselectInternal(item!);
    }
    else {
      onSelectInternal(item!);
    }
  }

  // For multi autocomplete
  function onChangeMultiInternal(items: TItem[] | null) {
    const current = value as TItem[] | null;
    const currentIds = current?.map(getOptionId) ?? [];
    const itemIds = items?.map(getOptionId) ?? [];

    const addedItemIds = without(itemIds, ...currentIds);
    if (!isNullOrEmpty(addedItemIds)) {
      const item = options.find(x => getOptionId(x) == addedItemIds[0]);
      onSelectInternal(item!);
    }

    const removedItemIds = without(currentIds, ...itemIds);
    if (!isNullOrEmpty(removedItemIds)) {
      const item = options.find(x => getOptionId(x) == removedItemIds[0]);
      onDeselectInternal(item!);
    }
  }

  function onSelectInternal(item: TItem) {
    // setSelectedOptions(previous => Array.isArray(previous) ? [...previous, item!] : item!);
    onSelect?.(item!);
  }

  function onDeselectInternal(item: TItem) {
    onDeselect?.(item!);
  }

  function getOptionsToShow(): TItem[] {
    let optionsToShow = options.sort((a, b) => getSortingValue(b) - getSortingValue(a)).slice(0, displayCount);

    // Also include currently selected options
    if (multiple) {
        optionsToShow.push(...(value as TItem[]))
    }
    else {
      optionsToShow.push(value as TItem);
    }

    optionsToShow = uniq(optionsToShow).sort((a, b) => getSortingValue(b) - getSortingValue(a));
    return optionsToShow;
  }
  
  function showPills() {
    return (
      <StoneXLabeledItem label={label}>
        <Stack direction="row" spacing="10px" className="pills">
          {pillsToShow?.map((q) => {

            const isSelected = multiple 
              ? (value as TItem[]).map(getOptionId).findIndex(x => x == getOptionId(q)) >= 0 
              : (value as TItem) == q;

            return <Button
            variant="outlined"
            className={`pill-button ${isSelected ? 'active' : ''}`}
            value={getOptionId(q)}
            key={getOptionId(q)}
            onClick={(e: any) => onToggle(e.currentTarget.value)}
          >
            {getOptionLabel(q)}
          </Button>;
          })}
          {options.length > displayCount && (
            <Button onClick={() => setShowAll(true)} variant="text">
              Show more
            </Button>
          )}
        </Stack>
      </StoneXLabeledItem>
    );
  }

  function showDropdown() {
    return (
      <>
        {
          multiple
            ? <StoneXMultiAutocomplete
              options={options}
              getOptionLabel={getOptionLabel}
              placeholder="Select a year"
              disableClearable
              label={label}
              value={value ?? []}
              onChange={(e: any, items: TItem[] | null) => onChangeMultiInternal(items)}
            />
            : <StoneXAutocomplete
              options={options}
              getOptionLabel={getOptionLabel}
              placeholder="Select a year"
              disableClearable
              label={label}
              value={value ?? null}
              onChange={(e: any, item: TItem | null) => onChangeSingleInternal(item)}
            />
        }
       
        <Button onClick={() => setShowAll(false)} variant="text">
          Show fewer
        </Button>
      </>
    );
  }

  return showAll ? showDropdown() : showPills();
}
