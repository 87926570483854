import { isArray, isFunction, isObject } from 'lodash';

export function deepMerge(original: any, partial: any): any {
  const result: any = { ...original };

  if (!partial) {
    return result;
  }

  Object.keys(partial).forEach(key => {
    const partialValue = partial[key];

    if (isArray(partialValue)) {
      result[key] = partialValue;
    }
    else if (isFunction(partialValue)){
      result[key] = partialValue;
    } 
    else if (isObject(partialValue)) {
      result[key] = deepMerge(original[key], partialValue);
    }
    else if (partialValue !== undefined) {
      result[key] = partialValue;
    }
  })

  return result;
}