import { ColDef, GetRowIdParams } from "ag-grid-community";
import 'ag-grid-enterprise';
import { AgGridReactProps } from "ag-grid-react";
import { useEffect, useState } from "react";
import { StoneXGrid } from '../../../../../StoneX';
import { PositionColumns, PositionReportColumn, PositionReportRow } from "./Types";

interface PositionReportTableProps {
  rows: PositionReportRow[];
  columnsToShow: PositionReportColumn[];
}

export default function PositionReportTable(props: PositionReportTableProps) {
 
  const { columnsToShow, rows } = props;

  const [columns, setColumns] = useState<ColDef<PositionReportRow>[]>();

  function updateColumns() {
    if (!columnsToShow) {
      setColumns([]);
      return;
    }

    const columnDefs = columnsToShow.map((column: PositionReportColumn) => PositionColumns[column] as ColDef<PositionReportRow>);
    setColumns(columnDefs);
  }

  useEffect(updateColumns, [columnsToShow])

  const gridOptions: AgGridReactProps = {
    grandTotalRow: 'bottom',
    treeData: true,
    getDataPath: (data: PositionReportRow) => data.path
  };

  return <StoneXGrid onlyShowGroupLabels columns={columns!} rows={[...rows]} gridOptions={gridOptions} groupColumnWidth={200} getRowId={(x: GetRowIdParams) => x.data.positionId} />
}