import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Dictionary, debounce, groupBy, isEqual, remove, sum, uniq, without } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { ScenarioReportRow, ScenarioReportSeries } from "./Types";
import { useResizeListener } from "../../../../../../Hooks";
import { StoneXNumberFormat } from "../../../../../StoneXMui/StoneXNumber/StoneXNumber";
import { StressIncrementType } from "../../../../../../Generated/Commodity-Management-Api";

interface VarianceValueChartProps {
  keys: string[],
  rows: ScenarioReportRow[];
  activeSeries: ScenarioReportSeries[];
  pathToChart?: string[] | undefined;
}


export function ScenarioChart (props: VarianceValueChartProps) {

    const { rows, keys, activeSeries, pathToChart } = props;

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({});

    
    const gray4 = useMemo(() =>getComputedStyle(document.documentElement).getPropertyValue('--gray4'), []);
    const gray3 = useMemo(() =>getComputedStyle(document.documentElement).getPropertyValue('--gray3'), []);
    const gray2 = useMemo(() =>getComputedStyle(document.documentElement).getPropertyValue('--gray2'), []);

    const chartContainerRef = useRef(null);
    const chartRef = useRef<HighchartsReact.RefObject>(null);
    const chartContainerDimensions = useResizeListener(chartContainerRef);

    const chartSizeUpdate = useMemo(() =>
        debounce(() => {
          chartRef.current?.chart.setSize(chartContainerDimensions.x, chartContainerDimensions.y);
        }, 50),
      [chartContainerDimensions],
    );
  

    useEffect(() => {       

      if (chartContainerDimensions.x == 0 && chartContainerDimensions.y == 0) {
        return;
      }

      chartSizeUpdate();
  
      return () => {
        chartSizeUpdate.cancel();
      };
    }, [chartContainerDimensions]);

    const seriesOptionToHighchartsSeries: {[key in ScenarioReportSeries]: (rowDictionary: ScenarioReportRow[]) => SeriesOptionsType} = {
        budgetValue: (rowDictionary: ScenarioReportRow[]) => ({
            name: 'Budget Value',
            data: rowDictionary.map(x => x.budgetValue),
            type: 'column',
            color: '#39B6F2',
            yAxis: 0
        } as SeriesOptionsType),
        marketValue: (rowDictionary: ScenarioReportRow[]) => ({
            name: 'Market Value',
            data: rowDictionary.map(x => x.marketValue),
            type: 'column',
            color: '#4590DB',
            yAxis: 0
        } as SeriesOptionsType),
        blendedValue: (rowDictionary: ScenarioReportRow[]) => ({
            name: 'Blended Value',
            data: rowDictionary.map(x => x.blendedValue),
            type: 'column',
            color: '#1327E1',
            yAxis: 0
        } as SeriesOptionsType),
        budgetPrice: (rowDictionary: ScenarioReportRow[]) => ({
            name: 'Budget Price',
            data: rowDictionary.map(x => x.budgetPrice),
            type: 'line',
            yAxis: 1
        } as SeriesOptionsType),
        marketPrice: (rowDictionary: ScenarioReportRow[]) => ({
            name: 'Market Price',
            data: rowDictionary.map(x => x.marketPrice),
            type: 'line',
            yAxis: 1
        } as SeriesOptionsType),
        blendedPrice: (rowDictionary: ScenarioReportRow[]) => ({
            name: 'Blended Price',
            data: rowDictionary.map(x => x.blendedPrice),
            type: 'line',
            yAxis: 1
        } as SeriesOptionsType),
        blendedPriceWithStrategy: (rowDictionary: ScenarioReportRow[]) => ({
            name: 'Blended Price',
            data: rowDictionary.map(x => x.whatIfBlendedPrice),
            type: 'line',
            yAxis: 1
        } as SeriesOptionsType),
    }

    function updateChart() {

        const isSameCommodity = uniq(rows.map(x => x.commodity?.commodityId)).length == 1 && !!rows[0]?.commodity;
        const commodity = isSameCommodity ? rows?.[0]?.commodity : undefined;

        const formattedNumber = (number?: number, series?: number) => {
            const decimals = series == 1 ? commodity?.displayPrecision ?? 2 : 0;

            let format = number == undefined
                ? undefined
                : "$" + Math.abs(number).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })

            return format;
        };

        setChartOptions({
            chart: {
                height: '500px',
            },
            title: {
                text: 'Variance'
            },
            tooltip: {
                useHTML: true, 
                formatter: function() {
                    return `
                        <div style="margin: 10px; font-weight: 700; line-height: 1.5em">
                            ${this.x}
                            <br/>
                            ${this.series.name}: ${formattedNumber(this.point.y, (this.series.yAxis as any).index)}
                        </div>
                    `;
                  }
            },
           plotOptions: {
                series: {
                    states: {
                        inactive: {
                        opacity: 1 // Set opacity to 1 to prevent other series from fading
                        }
                    }
                }
            },
            xAxis: {
                categories: keys,
                labels: {
                    style: {
                        color: gray3,
                        fontWeight: '700'
                    },
                }
            },
            yAxis: [{
                allowDecimals: false,
                title: {
                    text: 'Dollar Value',
                    style: {
                        color: gray3,
                        fontWeight: '700'
                    }
                },
                labels: {
                    style: {
                        color: gray3,
                        fontWeight: '700'
                    }
                }},
                    ...isSameCommodity 
                        ? [{   // Secondary yAxis
                        gridLineWidth: 0,
                        title: {
                            text: 'Price',
                            style: {
                                color: gray3,
                                fontWeight: '700'
                            }
                        },
                        labels: {
                            format: '${value}',
                            style: {
                                color: gray3,
                                fontWeight: '700'
                            }
                        },
                        opposite: true // Makes this y-axis appear on the right    
                    }]
                    : []
                ],
            series: getSeries(isSameCommodity)
        })
    }

    function getSeries(isSameCommodity: boolean) {

      const series = isSameCommodity
        ? activeSeries
        : without(activeSeries, 'blendedPrice', 'budgetPrice', 'marketPrice', 'blendedPriceWithStrategy');

        return series.map(series => seriesOptionToHighchartsSeries[series](rows));
    }

    useEffect(updateChart, [rows]);

    return <div ref={chartContainerRef} style={{ width: '100%', height: '500px', justifySelf: 'center', overflow: 'hidden', resize: 'both', padding: 0, margin: 0, zIndex: 2 }}>
        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
    </div>;
}