import { object, number, date, mixed, array, string, SchemaOf } from 'yup';
import { Unit } from '../Generated/Mark-It-View-Api';

export const BudgetSelectionFormSchema = object({
  commodityId: number().required(),
  // locationId: number().required(),
  pricingStrategyId: number().required(),
  counterpartyId: number().optional(),
  year: number().required().min(2000),
  unit: mixed<Unit>().required()
});
