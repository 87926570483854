import { Star } from "@mui/icons-material";
import Highcharts, { SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { Dictionary, every, get, groupBy, isEqual, round, sum, uniq } from "lodash";
import { useEffect, useRef, useState } from "react";
import { FixedCallSeriesOption, VarianceReportRow } from "./Types";

interface FixedOptionCoverageChartProps {
  rows: VarianceReportRow[];
  pathToChart: string[] | undefined;
}


export function FixedOptionCoverageChart(props: FixedOptionCoverageChartProps) {

    const { rows, pathToChart } = props;

    const [chartOptions, setChartOptions] = useState<Highcharts.Options>({});

    const [rowsToChart, setRowsToChart] = useState<VarianceReportRow[]>(rows);
    
    const gray4 = getComputedStyle(document.documentElement).getPropertyValue('--gray4').trim();

    const chartContainerRef = useRef(null);
    const chartRef = useRef<HighchartsReact.RefObject>(null);

    const seriesOptionToHighchartsSeries: {[key in FixedCallSeriesOption]: (rowDictionary: Dictionary<VarianceReportRow[]>) => SeriesOptionsType} = {

        underlyingCallHedgedVolume: (rowDictionary: Dictionary<VarianceReportRow[]>) => ({
            name: 'Call Option',
            data: Object.entries(rowDictionary).map(([key, entries]) => sum(entries.map(y => round(y.underlyingCallHedgeVolume! / y.budgetVolume! * 100,0)))),
            type: 'column',
            color: '#4590DB',
            yAxis: 0,
            index: 0
        } as SeriesOptionsType),
        underlyingFixedHedgeVolume: (rowDictionary: Dictionary<VarianceReportRow[]>) => ({
            name: 'Fixed Price',
            data: Object.entries(rowDictionary).map(([key, entries]) => sum(entries.map(y => round(y.underlyingFixedHedgeVolume! / y.budgetVolume!*100,0)))),
            type: 'column',
            color: '#39B6F2',
            yAxis: 0,
            index: 1
        } as SeriesOptionsType),
        strikePrice: (rowDictionary: Dictionary<VarianceReportRow[]>) => ({
            name: 'Weighted Avg. Call Strike Price',
            data: Object.entries(rowDictionary).map(([key, entries]) => sum(entries.map(y => y.underlyingCallStrikePrice))),
            type: 'line',
            yAxis: 1,
            lineWidth: 0,
            color: 'red',
            marker: {
                symbol: 'square'
            }
        } as SeriesOptionsType),
        marketPrice: (rowDictionary: Dictionary<VarianceReportRow[]>) => ({
            name: 'Market Price',
            data: Object.entries(rowDictionary).map(([key, entries]) => sum(entries.map(y => y.marketPrice))),
            type: 'line',
            yAxis: 1
        } as SeriesOptionsType),
        blendedPrice: (rowDictionary: Dictionary<VarianceReportRow[]>) => ({
            name: 'Blended Price',
            data: Object.entries(rowDictionary).map(([key, entries]) => sum(entries.map(y => y.underlyingBlendedPrice))),
            type: 'line',
            yAxis: 1
        } as SeriesOptionsType),
    }

    function updateChart() {

        const isSameCommodity = uniq(rowsToChart.map(x => x.commodity?.commodityId)).length == 1;
        const commodity = isSameCommodity ? rowsToChart?.[0]?.commodity : undefined;

        const formattedNumber = (number?: number, series?: number) => {
            const decimals = series == 1 ? commodity?.displayPrecision ?? 2 : 0;

            let format = number == undefined
                ? undefined
                : "$" + Math.abs(number).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })

            return format;
        };

        setChartOptions({
            chart: {
                height: 700,
            },
            title: {
                text: 'Coverage'
            },
            tooltip: {
                useHTML: true, 
                formatter: function() {
                    return `
                        <div style="margin: 10px; font-weight: 700; line-height: 1.5em">
                            ${this.x}
                            <br/>
                            ${this.series.name}: ${formattedNumber(this.point.y, (this.series.yAxis as any).index)}
                        </div>
                    `;
                  }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    dataLabels: {
                        enabled: true
                    }
                },
                series: {
                states: {
                    inactive: {
                    opacity: 1 // Set opacity to 1 to prevent other series from fading
                    }
                }
                }
            },
            xAxis: {
                categories: getMonths(),
                labels: {
                    style: {
                        color: gray4,
                        fontWeight: '700'
                    }
                }
            },
            yAxis: [{
                allowDecimals: false,
                title: {
                    text: '% Hedge',
                    style: {
                        color: gray4,
                        fontWeight: '700'
                    }
                },
                labels: {
                    style: {
                        color: gray4,
                        fontWeight: '700'
                    },
                    formatter: function () {
                        return Highcharts.numberFormat(Number(this.value), 0) + '%'
                    }
                },
                stackLabels: {
                    enabled: true,
                    formatter: function () {
                        return Highcharts.numberFormat(this.total, 0) + '%'
                    }
                }
},
                    ...isSameCommodity 
                        ? [{   // Secondary yAxis
                        gridLineWidth: 0,
                        title: {
                            text: 'Price',
                            style: {
                                color: gray4,
                                fontWeight: '700'
                            }
                        },
                        labels: {
                            format: '${value}',
                            style: {
                                color: gray4,
                                fontWeight: '700'
                            }
                        },
                        opposite: true // Makes this y-axis appear on the right    
                    }]
                    : []
                ],
            series: isSameCommodity  
                ? getSeries('underlyingFixedHedgeVolume', 'underlyingCallHedgedVolume', 'strikePrice', 'marketPrice', 'blendedPrice')
                : getSeries('underlyingFixedHedgeVolume', 'underlyingCallHedgedVolume') 
        })
    }

    function getMonths() {
        var allMonths = rows.filter(x => !x.isAggregated).map(x => x.budgetDate?.label!);
        return uniq(allMonths);
    }

    function getSeries(...columns: FixedCallSeriesOption[]) {
        const groupedRows = groupBy(rowsToChart, x => x.budgetDate?.label!);
        var series = columns.map(column => seriesOptionToHighchartsSeries[column](groupedRows));
        return series;
    }

    function updateRowsToChart() {
        let filteredRows = rows.filter(x => !x.isAggregated);

        if (pathToChart) {
            // Get all rows where the given path 'pathToChart' is a subset of the row's path
            const pathLength = pathToChart.length;
            filteredRows = filteredRows.filter(x => isEqual(x.path.slice(0, pathLength), pathToChart));
        } 

        setRowsToChart(filteredRows);
    }

    useEffect(updateRowsToChart, [rows, pathToChart]);
    useEffect(updateChart, [rowsToChart]);

    return <div ref={chartContainerRef} style={{ width: '100%', justifySelf: 'center', overflow: 'auto', resize: 'both', padding: 0, margin: 0, zIndex: 2 }}>
        <HighchartsReact ref={chartRef} highcharts={Highcharts} options={chartOptions} />
    </div>;
}