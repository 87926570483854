import { CellClassParams } from "ag-grid-community";

let numeral = require('numeraljs');

const Volume = (x: number | null | undefined): string => {
  if (!x) {
    return '-';
  }

  return numeral(x).format('(0,0)');
};

const Value = (x: number | null | undefined): string => {
  if (!x) {
    return '-';
  }

  return numeral(x).format('$(0,0)');
};

const Price = (x: number | null | undefined): string => {
  if (!x) {
    return '-';
  }

  return numeral(x).format('$(0,0.000)');
};

function getAgGridProfitLossCellClasses(params: CellClassParams<any, any>): string | undefined | string[] {
  const number = params.value;
  const isGrouped = params.node.allChildrenCount ?? 0 > 0;

  if (number === undefined) {
    return undefined;
  }

  if (number >= 0) {
    if (isGrouped) {
      return ['ag-green-cell-background', 'bold'];
    }
    else {
      return undefined;
    }
  }
  else {
    if (isGrouped) {
      return ['ag-red-cell-background', 'ag-red-cell-text', 'bold'];
    } 
    else {
      return ['ag-red-cell-text'];
    }
  }
}

export default {
  Value: Value,
  Volume: Volume,
  Price: Price,
  getAgGridProfitLossCellClasses: getAgGridProfitLossCellClasses
}