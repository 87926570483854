import { useOktaAuth } from '@okta/okta-react';
import { useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { AdminSolution, CommodityManagementSolution, Dashboard, DecilesSolution, Header, MarketViewSolution, SidebarNavigation } from '.';
import { CommodityManagementMarketViewApi, MarkItViewApi } from '../Apis/Apis';
import OktaLoginCallback from '../Core/Okta/OktaLoginCallback';
import OktaSecurity from '../Core/Okta/OktaSecurity';
import { useLoadingState } from '../Hooks';
import store, { RootState, useApplicationDispatch, useApplicationSelector } from '../Redux/ReduxStore';
import { UserAccessState, setUserAccess } from '../Redux/Slices/UserAccessSlice';
import './App.scss';
import LoginPage from './LoginPage';
import TermsAndConditions from './TermsAndConditions';
import { SnackbarProvider, MaterialDesignContent } from 'notistack';
import { styled } from '@mui/material';

function AuthWrapper(props: { children: React.ReactNode }) {
  const { authState, oktaAuth } = useOktaAuth();
  const prevTokenRef = useRef<string | null>(null);
  
  const navigate = useNavigate();
  const dispatch = useApplicationDispatch();
  const authorizationLoadingState = useLoadingState();
  const userAccess = useApplicationSelector((state: RootState): UserAccessState => state.userAccess);

  function authorizeUser() {
    oktaAuth.getUser().then((userInfo) => {
      // If authorizationLoadingState is already loaded, then don't update the loading state. It will force the page to refresh.
      MarkItViewApi.marketViewUserAuthorizationCallback(authorizationLoadingState.isLoaded() ? undefined : authorizationLoadingState.setLoadingState).then((res) => {
        dispatch(
          setUserAccess({
            commodityManagementUserSiteAccesses: res.data.user?.commodityManagementUserSiteAccesses,
            marketViewApplications: res.data.user?.marketViewApplications,
            hasAccess: res.data.user?.email == null ? false : true,
            isAdmin: res.data.user?.isAdministrator,
            agreedToTermsAndConditions: res.data.user?.getTermsAndConditionsResponse?.agreed
          }),
        );
      });
    });
  }

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState.isAuthenticated) {
      navigate('/login');
    }

    const currentToken = authState.accessToken?.accessToken || null;
    
    if (prevTokenRef.current !== currentToken) {
      prevTokenRef.current = currentToken; 
      authorizeUser();
    }
    
  }, [authState, oktaAuth]);

  if (!authorizationLoadingState.isLoaded()) {
    if (authorizationLoadingState.isLoading()) {
      return <>Show Loading Screen Here</>;
    }

    return <>Nothing is happenning...</>;
  }
  else if (authorizationLoadingState.isLoaded()) {
    
    if (!userAccess.agreedToTermsAndConditions) {
      return <TermsAndConditions />
    }

    return <>
      {props.children}
    </>
  }

  return <>Default...</>
}

function StandardLayout() {
  return <AuthWrapper>
    <Header />
    <div style={{ display: 'flex', flexDirection: 'row', flexGrow: 1 }}>
      <SidebarNavigation />
      <main>
        <Outlet />
      </main>
    </div>
  </AuthWrapper>
}

export default function App() {

  const StyledMaterialDesignContent = styled(MaterialDesignContent)(() => ({
    '&.notistack-MuiContent-success': {
      backgroundColor: 'var(--toast-success-background-color)',
      color: 'var(--toast-success-text-color)',
    },
  }));

  return (
    <SnackbarProvider Components={{success: StyledMaterialDesignContent}} anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}>
      <Provider store={store}>
        <OktaSecurity>
          <BrowserRouter>
            <Routes>
              <Route>
                <Route element={<Outlet />}>
                  <Route path="/login" element={<LoginPage />} />
                </Route>
                <Route element={<StandardLayout />}>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/commodity-management/*" element={<CommodityManagementSolution />} />
                  <Route path="/market-view" element={<MarketViewSolution />} />
                  {/* <Route path="/deciles" element={<DecilesSolution />} /> */}
                  <Route path="/admin/*" element={<AdminSolution />} />
                </Route>
              </Route>
              <Route path="/callback" element={<OktaLoginCallback />} />
            </Routes>
            {/* <MainLayout /> */}
          </BrowserRouter>
        </OktaSecurity>
      </Provider>
    </SnackbarProvider>
  );
}
