import { ColDef, ICellRendererParams, ITooltipParams } from 'ag-grid-community';
import { ChevronRight } from '@mui/icons-material';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import { StoneXGrid, StoneXRow } from '../../../../../StoneX';
import { StoneXFileUploader } from '../../../../../StoneXMui';
import { useEffect, useState } from 'react';
import { Button, IconButton } from '@mui/material';
import { ScenarioMarketPriceExcelTemplateRow, ScenarioMarketPriceModel } from '../../../../../../Generated/Commodity-Management-Api';
import { Constants } from '../../../../../../Core/Constants';

interface ScenarioMarketPricesProps {
  onScenarioMaretPricesUpdated: (prices: ScenarioMarketPriceModel[] | undefined) => void;
}

export default function ScenarioMarketPrices(props: ScenarioMarketPricesProps) {

  const { onScenarioMaretPricesUpdated } = props;

  const [expanded, setExpanded] = useState(false);
  // const [ scenarioMarketPrices, setScenarioMarketPrices ] = useState<ScenarioMarketPriceModel[] | undefined>();
  const [ processedRows, setProcessedRows ] = useState<ScenarioMarketPriceExcelTemplateRow[]>([]);

  function getScenarioMarketPricesFromUpload(file: File) {
    CommodityManagementApi.listScenarioMarketPricesFromUpload(undefined, file).then((res) => {
        // setScenarioMarketPrices(res.data.rows ?? undefined)
        setProcessedRows(res.data.rows ?? []);
    });
  }

  function downloadTemplate() {
    CommodityManagementApi.getScenarioMarketPricesExcelTemplate(undefined,  {responseType: 'blob', headers: {'ContentType': Constants.MediaType.Excel}})
    .then(res => {
      const contentDispositionHeader = res.headers['content-disposition'];
      const fileName  = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '') ?? 'KnowRisk Export';
      const url = window.URL.createObjectURL(new Blob([res.data! as any], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    });
  }

  useEffect(() => onScenarioMaretPricesUpdated(processedRows?.filter(x => x.isSuccessful).map(x => x.model)), [processedRows]);

  const columns = [
    {
      colId: 'Row',
      headerName: 'Row',
      field: 'row',
    },
    {
      colId: 'Budget Month',
      headerName: 'Budget Month',
      field: 'budgetMonth',
      cellClass: (params: ICellRendererParams<ScenarioMarketPriceExcelTemplateRow>) => params.data?.fieldErrors?.['budgetMonth'] ? 'ag-red-cell-background' : undefined,
      headerTooltip: 'Budget month tooltip',
      tooltipValueGetter: (params: ITooltipParams<ScenarioMarketPriceExcelTemplateRow>) => params.data?.fieldErrors?.['budgetMonth']?.join('.<br>')
    },
    {
      colId: 'Budget Year',
      headerName: 'Budget Year',
      field: 'budgetYear',
      cellClass: (params: ICellRendererParams<ScenarioMarketPriceExcelTemplateRow>) => params.data?.fieldErrors?.['budgetYear'] ? 'ag-red-cell-background' : undefined,
      tooltipValueGetter: (params: ITooltipParams<ScenarioMarketPriceExcelTemplateRow>) => params.data?.fieldErrors?.['budgetYear']?.join('.<br>')
    },
    {
      colId: 'Market Price',
      headerName: 'Market Price',
      field: 'marketPrice',
      cellClass: (params: ICellRendererParams<ScenarioMarketPriceExcelTemplateRow>) => params.data?.fieldErrors?.['marketPrice'] ? 'ag-red-cell-background' : undefined,
      tooltipValueGetter: (params: ITooltipParams<ScenarioMarketPriceExcelTemplateRow>) => params.data?.fieldErrors?.['marketPrice']?.join('.<br>')
    },
  ] as ColDef[];

  return (
    <div style={{ borderTop: '1px solid var(--gray5)', borderBottom: '1px solid var(--gray5)' }}>
      <StoneXRow align="center" marginDirection="topAndBottom">
        <IconButton onClick={() => setExpanded((previous) => !previous)}>
          <ChevronRight sx={{ transform: expanded ? 'rotate(90deg)' : undefined }} />
        </IconButton>
        <h3 style={{ color: 'var(--gray2)' }}>Scenario Market Prices</h3>
      </StoneXRow>
      {expanded && <>
        <Button variant='text' onClick={downloadTemplate}>Download scenario market price template</Button>
        <StoneXRow>
          <StoneXFileUploader message="Upload scenario market prices" onUpload={getScenarioMarketPricesFromUpload}></StoneXFileUploader>
          <StoneXGrid gridOptions={{domLayout: 'print'}} rows={processedRows} columns={columns} hideGridOptions />
        </StoneXRow>
      </>}
    </div>
  );
}
