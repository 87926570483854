import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { StoneXSelect } from '../../StoneXMui';

interface StoneXPaginationProps<T> {
  items: T[];
  defaultItemsPerPage?: number;
  className?: string;
  invisibleIfNoPages?: boolean;
  onVisibleItemsUpdate: (items: T[]) => void;
}

export function StoneXPagination<T>(props: StoneXPaginationProps<T>) {
  const { items, defaultItemsPerPage = 10, className, onVisibleItemsUpdate } = props;
  const [itemsPerPage, setItemsPerPage] = useState<number>(defaultItemsPerPage!);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const [itemStart, setItemStart] = useState<number>();
  const [itemEnd, setItemEnd] = useState<number>();
  const [totalPages, setTotalPages] = useState<number>();
  const itemsPerPageOptions = useMemo(() => Array.from({ length: 20 ?? 0 }, (_, i) => i + 1), []);

  function update() {
    const itemStart = itemsPerPage * (currentPage - 1) + 1;
    const itemEnd = Math.min(itemsPerPage * currentPage, items.length);
    const totalPages = Math.ceil(items.length / itemsPerPage);

    setItemStart(itemStart);
    setItemEnd(itemEnd);
    setTotalPages(totalPages);
    onVisibleItemsUpdate(items.slice(itemStart - 1, itemEnd));
  }

  useEffect(update, [items, itemsPerPage, currentPage]);

  return (
    <span className={className} style={{ display: 'flex', flexDirection: 'row', gap: '16px', alignItems: 'center' }}>
      Items per page:
      <StoneXSelect
        variant="standard"
        width="50px"
        options={itemsPerPageOptions}
        getId={(x) => x}
        getOptionLabel={(x) => x}
        value={itemsPerPage}
        onChange={(value: number | null) => setItemsPerPage(value!)}
      />
      {itemStart} - {itemEnd} of {items.length}
      <span>
        <IconButton disabled={currentPage == 1} onClick={() => setCurrentPage((previous) => previous - 1)}>
          <ChevronLeft />
        </IconButton>
        <IconButton disabled={currentPage == totalPages} onClick={() => setCurrentPage((previous) => previous + 1)}>
          <ChevronRight />
        </IconButton>
      </span>
    </span>
  );
}
