import { Button, CircularProgress } from '@mui/material';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo, useState } from 'react';
import { CommodityManagementApi } from '../../../../Apis/Apis';
import { NoteModel } from '../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../Hooks';
import { StoneXTextField } from '../../../StoneXMui';
import './NotesPage.css';

interface NoteUpdateProps {
  note?: NoteModel;
  onSave: (note: NoteModel) => void;
  onCancel: () => void;
}

export default function NoteUpdate(props: NoteUpdateProps) {
  const { note, onSave, onCancel } = props;

  const saveLoadingState = useLoadingState();
  const { enqueueSnackbar } = useSnackbar();

  const [noteEditSubject, setNoteEditSubject] = useState<string | null>(note?.subject ?? '');
  const [noteEditBody, setNoteEditBody] = useState<string>(note?.content ?? '');
  const noteIsValid = useMemo(() => !(isEmpty(noteEditBody?.trim()) || isEmpty(noteEditSubject?.trim())), [noteEditBody, noteEditSubject]);

  function saveNote() {
    if (!noteIsValid) {
      return;
    }

    const subject = noteEditSubject!.trim();
    const content = noteEditBody!.trim();

    if (note === undefined) {
      // Add the note.
      const request = { subject, content };
      CommodityManagementApi.addNote(saveLoadingState.setLoadingState, request)
        .then((res) => {
          enqueueSnackbar('Note has been succesfully added', { variant: 'success' });
          resetEditNote();
          onSave(res.data.note!);
        })
        .catch((res) => {
          enqueueSnackbar('An error occured while adding the note', { variant: 'error' });
        });
    } else {
      // Save the note.
      CommodityManagementApi.updateNote(saveLoadingState.setLoadingState, note.noteGuid, subject, content)
        .then((res) => {
          enqueueSnackbar('Note has been succesfully updated', { variant: 'success' });
          resetEditNote(res.data.note!);
          onSave(res.data.note!);
        })
        .catch((res) => {
          enqueueSnackbar('An error occured while updating the note', { variant: 'error' });
        });
    }
  }

  function resetEditNote(note?: NoteModel) {
    setNoteEditSubject(note?.subject ?? '');
    setNoteEditBody(note?.content ?? '');
  }

  return (
    <div className="note-container">
      <div className="note-input-body">
        <StoneXTextField
          disabled={saveLoadingState.isLoading()}
          value={noteEditSubject}
          placeholder="Note Subject"
          fullWidth
          onUpdate={setNoteEditSubject}
        />
        <StoneXTextField
          disabled={saveLoadingState.isLoading()}
          value={noteEditBody}
          placeholder="Add your note here"
          multiline
          minRows={4}
          fullWidth
          onUpdate={setNoteEditBody}
        />
      </div>
      <div className="note-footer">
        <Button variant="outlined" disabled={saveLoadingState.isLoading()} onClick={onCancel}>
          Cancel
        </Button>
        <Button disabled={!noteIsValid || saveLoadingState.isLoading()} onClick={saveNote}>
          Save &nbsp; {saveLoadingState.isLoading() && <CircularProgress size='1em' />}
        </Button>
      </div>
    </div>
  );
}
