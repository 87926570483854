import { Button,ButtonGroup } from '@mui/material';
import { ColDef, GetRowIdParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import React,{ useEffect,useRef,useState } from 'react';
import { CommodityManagementApi } from '../../../../Apis/Apis';
import { CounterpartyModel } from '../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../Hooks';
import useActiveApplication from '../../../../Hooks/CommodityManagement/useActiveApplication';
import { StoneXRow } from '../../../StoneX';
import { StoneXModal,StoneXTextField } from '../../../StoneXMui';

interface CounterpartySettingsProps {
  counterparties: CounterpartyModel[];
}

export default function CounterpartySettings(props: CounterpartySettingsProps) {
  const [counterpartys, setCounterpartys] = useState<CounterpartyModel[]>(props.counterparties);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [modalCounterparty, setModalCounterparty] = useState<CounterpartyModel | undefined>();
  const applicationGuid = useActiveApplication();
  const saveCounterpartyLoadingState = useLoadingState();
  const gridRef = useRef<AgGridReact<CounterpartyModel>>(null);

  const columnDefs: ColDef<any>[] = [
    {
      headerName: 'Counterparty Name',
      field: 'counterpartyName',
      sortable: true,
      editable: true,
      flex: 1,
    },
    {
      headerName: 'Actions',
      width: 150,
      cellRenderer: (params: any) => {
        return (
          <ButtonGroup variant="text">
            <Button onClick={() => updateCounterparty(params.data)}>Edit</Button>
            <Button onClick={() => deleteCounterparty(params.data)}>Delete</Button>
          </ButtonGroup>
        );
      },
    },
  ];

  function saveCounterparty(counterpartyToSave: Partial<CounterpartyModel>) {

    if (counterpartyToSave.counterpartyId) {

      CommodityManagementApi.addCounterparty(saveCounterpartyLoadingState.setLoadingState, counterpartyToSave.counterpartyName!)
      .then(res => setCounterpartys(previous => [...previous, res.data.counterparty as CounterpartyModel]));
    } else {
      CommodityManagementApi.updateCounterparty(
        saveCounterpartyLoadingState.setLoadingState,
        counterpartyToSave.counterpartyId!.toString(),
        counterpartyToSave.counterpartyName!
      ).then(res => setCounterpartys((previous) => previous.map(counterparty => counterparty.counterpartyId == res.data.counterparty?.counterpartyId ? (res.data.counterparty as CounterpartyModel) : counterparty)));
    }

  }

  function deleteCounterparty(counterpartyToDelete: CounterpartyModel) {
    const porfolioToDeleteId = counterpartyToDelete.counterpartyId!;
    CommodityManagementApi.deleteCounterparty(null, porfolioToDeleteId.toString()).then((res) =>
      setCounterpartys((previous) => previous.filter((counterparty) => counterparty.counterpartyId != porfolioToDeleteId)),
    );
  }

  useEffect(() => {
    // console.log('applying transaction', counterpartys)
    // gridRef?.current?.api?.applyTransaction({ update: counterpartys });
  }, [counterpartys]);

  function addCounterparty() {
    setModalCounterparty(undefined);
    setModalOpen(true);
  }

  function updateCounterparty(counterparty: CounterpartyModel) {
    setModalCounterparty(counterparty);
    setModalOpen(true);
  }

  return (
    <>
      <StoneXRow align="end">
        <h3>Counterpartys</h3>
        <Button className="pull-right" variant="outlined" onClick={addCounterparty}>
          Add
        </Button>
      </StoneXRow>
      <div className="ag-theme-alpine" style={{ width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          getRowId={(params: GetRowIdParams) => params.data.counterpartyId}
          enableRangeSelection
          columnDefs={columnDefs}
          domLayout="autoHeight"
          rowData={counterpartys}
        />
      </div>
      <UpdateCounterpartyModal open={modalOpen} onClose={() => setModalOpen(false)} onCounterpartySave={saveCounterparty} counterparty={modalCounterparty} />
    </>
  );
}

interface UpdateCounterpartyProps {
  open: boolean;
  onClose: () => void;
  onCounterpartySave: (counterparty: Partial<CounterpartyModel>) => void;
  counterparty?: CounterpartyModel;
}

function UpdateCounterpartyModal(props: UpdateCounterpartyProps) {
  const { open, onClose, onCounterpartySave, counterparty } = props;
  const [counterpartyName, setCounterpartyName] = useState<string>();
  const actionLabel = props.counterparty ? 'Update' : 'Add';

  useEffect(() => {
    setCounterpartyName(counterparty?.counterpartyName ?? '');
  }, [counterparty]);

  function updateCounterparty() {
    //todo: validate

    onCounterpartySave({
      counterpartyId: counterparty?.counterpartyId ?? 0,
      counterpartyName: counterpartyName,
    });

    onClose();
  }

  return (
    <StoneXModal header={actionLabel + ' Counterpartys'} open={open} onClose={onClose}>
      <StoneXRow>
        <StoneXTextField label="Counterparty Name" value={counterpartyName} onChange={(event: any) => setCounterpartyName(event.target.value)} />
      </StoneXRow>

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={updateCounterparty}>{actionLabel + ' Counterparty'}</Button>
      </StoneXRow>
    </StoneXModal>
  );
}
