import React, { forwardRef } from 'react';
import './Tabs.css';

interface StoneXTabProps {
  tabIndex?: number;
  isActive?: boolean;
  onClick?: (index: number) => void;
  children: React.ReactNode;
}

const StoneXTab = forwardRef<HTMLDivElement, StoneXTabProps>((props, ref) => {
  const { isActive, tabIndex, onClick, children } = props;

  function internalOnClick() {
    onClick?.(tabIndex!);
  }

  return (
    <div ref={ref} className={`tab ${isActive ? 'active' : ''}`} onClick={internalOnClick}>
      {children}
    </div>
  );
});

export default StoneXTab;
