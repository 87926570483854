import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useEffect,useState } from 'react';
import { ContractDate } from '../../../Generated/Mark-It-View-Api';
import { ContractDateHelper } from '../../../Helpers';
import { StoneXLabeledItem } from '..';

interface PropsType {
  value?: ContractDate | null;
  onChange?: (contractDate: ContractDate | null) => void;
  label?: string;
  error?: string | undefined;
  disabled?: boolean;
  width?: string | number;
  name?: string;
}

// The month is offset by 1 in this component. Should fix it to match StoneXContractMonthDropdown

export function StoneXContractMonthPicker(props: PropsType) {
  //when date is undefined, datepicker automatically assumes current day.
  //we prevent the behavior of undefined by converting it to null

  const { value, onChange } = props;
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<Date | null>(value ? ContractDateHelper.toDate(value) : null);

  function updateExternalContractDate() {

    if (!onChange) {
      return;
    }
      
    const contractDate = date ? ContractDateHelper.fromDate(date) : null;

    if (ContractDateHelper.isEqualTo(contractDate, value)) {
      // They are both the same dates, don't update anything which will cause recursion
      return;
    }

    onChange(contractDate);
  }

  function updateInternalDate() {
    const contractDate = date ? ContractDateHelper.fromDate(date) : null;

    if (ContractDateHelper.isEqualTo(contractDate, value)) {
      // They are both the same dates, don't update anything which will cause recursion
      return;
    }

    const newDate = value ? ContractDateHelper.toDate(value) : null;
    setDate(newDate);
  }

  useEffect(updateExternalContractDate, [date]);
  useEffect(updateInternalDate, [value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StoneXLabeledItem label={props.label}>
        <DatePicker
          // componentsProps={{actionBar: {actions: ['clear']}}}
          openTo="month"
          views={['year', 'month']}
          value={date}
          onChange={setDate}
          inputFormat="MM/YYYY"
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          renderInput={function (params) {
            return (
              <TextField
                {...params}
                sx={{ borderBottom: '1px solid', fieldset: { border: '0px' } }}
                name={props.name}
                error={!!props.error}
                helperText={props.error}
                disabled={props.disabled}
                onClick={(e) => setOpen(true)}
              />
            );
          }}
        />
      </StoneXLabeledItem>
    </LocalizationProvider>
  );
}
