import { ReactNode } from 'react';
import './StoneXRow.css';

interface StoneXRowProps {
  children: ReactNode;
  justify?: 'start' | 'end';
  align?: 'start' | 'center' | 'baseline' | 'end';
  marginDirection?: 'top' | 'bottom' | 'topAndBottom';
  extraHorizontalSpacing?: boolean;
  style?: React.CSSProperties;
}

export default function StoneXRow(props: StoneXRowProps) {

  const {align, justify, marginDirection = 'bottom', extraHorizontalSpacing, style, children} = props;

  const alignmentStyle = align ? { alignItems: align } : { alignItems: 'end'};
  const justifyStyle = { justifyContent: justify };


  let className = 'row';

  if (marginDirection == 'top' || marginDirection == 'topAndBottom') {
    className += ' row-with-top-margin';
  }

  if (marginDirection == 'bottom' || marginDirection == 'topAndBottom') {
    className += ' row-with-bottom-margin';
  }

  if (extraHorizontalSpacing) {
    className += ' row-extra-horizontal-spacing';
  }

  return (
    <div className={className} style={{...alignmentStyle, ...justifyStyle, ...style}}>
      {children}
    </div>
  );
}
