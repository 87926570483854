import { Button } from '@mui/material';
import { groupBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import {
  GetCustomerDataResponse,
  GetCustomerSettingsResponse,
  PositionModel, YearDataModel
} from '../../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../../Hooks';
import { RootState, useApplicationSelector } from '../../../../../Redux/ReduxStore';
import { StoneXDivider, StoneXLoading, StoneXRow } from '../../../../StoneX';
import StoneXMainPage from '../../../../StoneX/StoneXMainPage/StoneXMainPage';
import BasicQueryForm from './BasicQueryForm';
import PositionTable from './PositionTable';
import PositionValidationModal from './PositionValidationModal';
import { GridRow, GroupingOption } from '../../../../../Types';
import { ModelToRowTransformer } from '../../../../../Helpers/ModelToRowTransformer';
import { PositionRow } from './Types';

export default function ViewPositionsPage() {

  const yearsLoadingState = useLoadingState();

  const customerData = useApplicationSelector((state: RootState): GetCustomerDataResponse => state.commodityManagement.customerData);
  const customerSettings = useApplicationSelector((state: RootState): GetCustomerSettingsResponse => state.commodityManagement.customerSettings);

  const [years, setYears] = useState<YearDataModel[]>([]);

  const postionsLoadingState = useLoadingState();
  const [positions, setPositions] = useState<PositionModel[]>([]);
  const rows = useMemo(getReportRows, [positions]);

  const [validationModalOpen, setValidationModalOpen] = useState(false);
  const [validationDealTicketId, setDealTicketValidationId] = useState<number | undefined>();

  useEffect(() => {
    CommodityManagementApi.listPositionYears(yearsLoadingState.setLoadingState).then((q) => setYears(q.data.rows ?? []));
  }, []);

  function onPositionsUpdated(positions: PositionModel[]) {
    setPositions(positions);
  }

  function setPositionValidation(dealTicketId: number) {
    setValidationModalOpen(true);
    setDealTicketValidationId(dealTicketId);
  }

  function onValidationUpdated(dealTicketId: number, validatedPositionIds: number[]) {
    setPositions(previous => previous.map(q => {
      if (q.dealTicketId === dealTicketId) {
        q.isValidated = !!validatedPositionIds.find((r) => r === q.positionId);
      }
      return q;
    }))
  }

  function getReportRows(): PositionRow[] {
    const rows: PositionRow[] =  positions.map(x => ({path: [], ...x}) as PositionRow);

    // Filter out undefined groupings
    // const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1]].filter(x => !!x).map(x => x!);
    const selectedGroupings: GroupingOption[] = ['BudgetMonth'];

    // Update the path for each row based on groupings
    ModelToRowTransformer.updatePaths(rows, selectedGroupings);

    // Add positionId to path to make each path unique
    rows.forEach(x => x.path.push(x.positionId?.toString() ?? ''));

    return rows;
  }

  return (
    <StoneXMainPage>
      <StoneXRow align="baseline">
        <h1>Positions</h1>
    
        <NavLink to={'update'} className="pull-right">
          <Button>Add position</Button>
        </NavLink>
      </StoneXRow>

      <StoneXLoading show={yearsLoadingState.isLoading()} />

      {yearsLoadingState.isLoaded() && (
        <BasicQueryForm
          customerSettings={customerSettings!}
          years={years}
          loadingStateSetter={postionsLoadingState.setLoadingState}
          onPositionsUpdated={onPositionsUpdated}
        />
      )}

      <StoneXDivider />

      <StoneXLoading show={postionsLoadingState.isLoading()} />

      <PositionTable customerSettings={customerSettings} positions={rows} onSetPositionValidation={setPositionValidation} />
      {/* {postionsLoadingState.isLoaded() && (
      )} */}

      <PositionValidationModal
        open={validationModalOpen}
        dealTicketId={validationDealTicketId}
        onClose={() => setValidationModalOpen(false)}
        onValidationUpdated={onValidationUpdated}
      />
    </StoneXMainPage>
  );
}
