import { CloseOutlined } from '@mui/icons-material';
import { IconButton, SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';
import StoneXRow from '../../StoneX/StoneXRow/StoneXRow';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxWidth: '1500px',
  maxHeight: '80%',
  overflow: 'auto',
  backgroundColor: 'var(--modal-background-color)',
  display: 'flex',
  flexDirection: 'column',
   boxShadow: 24,
  padding: 4,
} as SxProps;

interface StoneXModalProps {
  header: string;
  open: boolean;
  onClose?: () => void;
  children?: ReactNode;
  width?: string | number;
  height?: string | number;
}

export default function StoneXModal(props: StoneXModalProps) {

  const {header, open, onClose, width, height, children} = props;

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{...style, width: width, height: height, padding: '16px'}}>
        <div style={{display: 'flex', alignItems: 'start', justifyContent: 'space-between'}}>
          <h2>{header}</h2>

          <IconButton className="pull-right" onClick={onClose}>
            <CloseOutlined />
          </IconButton>
        </div>

        {props?.children}
      
      </Box>
    </Modal>
  );
}
