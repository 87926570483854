import { AttachMoney, Percent } from '@mui/icons-material';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import dayjs from 'dayjs';
import { defaults, groupBy, isArray, multiply, uniq } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import {
  CommodityModel,
  ContractDate,
  CustomerReportModel,
  GetCustomerDataResponse,
  GetCustomerSettingsResponse,
  ScenarioVarianceModel,
  StressIncrementType,
} from '../../../../../../Generated/Commodity-Management-Api';
import { ContractDateHelper } from '../../../../../../Helpers';
import { deepMerge } from '../../../../../../Helpers/ObjectHelper';
import { useLoadingState } from '../../../../../../Hooks';
import { StoneXLoading, StoneXRow } from '../../../../../StoneX';
import { StoneXContractMonthDropdown, StoneXDatePicker, StoneXMultiselect, StoneXSelect, StoneXTextField } from '../../../../../StoneXMui';
import { StoneXNumberFormat } from '../../../../../StoneXMui/StoneXNumber/StoneXNumber';
import { ScenarioChart } from './ScenarioChart';
import ScenarioReportSettingsModal from './ScenarioReportSettingsModal';
import ScenarioTable from './ScenarioTable';
import { ScenarioVarianceModelToRowTransformer } from './ScenarioVarianceModelToRowTransformer';
import { ScenarioReportConfiguration, ScenarioReportRow, ScenarioReportUserOverride, allColumns } from './Types';
import { Constants } from '../../../../../../Core/Constants';
import { LoadingState } from '../../../../../../Types';

type ReportAction = 'Run' | 'Export to Excel';
const reportActions: ReportAction[] = ['Run', 'Export to Excel'];

interface ScenarioReportProps {
  customerName?: string;
  customerReport: CustomerReportModel;
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  quoteDate: Date;
}

export default function ScenarioReport(props: ScenarioReportProps) {
  const { customerName, customerReport, customerData, customerSettings } = props;

  const reportConfiguration = useMemo(() => JSON.parse(customerReport.configuration!) as ScenarioReportConfiguration, [customerReport]);
  const userOverrides = useMemo(() => (customerReport.userOverrides ? (JSON.parse(customerReport.userOverrides) as ScenarioReportUserOverride) : undefined), [customerReport]);
  const reportSettings: ScenarioReportConfiguration = useMemo(() => deepMerge(reportConfiguration, userOverrides), [reportConfiguration, userOverrides]);

  const [ scenarios, setScenarios ] = useState<ScenarioVarianceModel[]>([]);
  const [ rows, setRows ] = useState<ScenarioReportRow[]>([]);
  const isSingleCommodity = useMemo(getIsSingleCommodity, [scenarios]);

  const rowsToChart = useMemo<ScenarioReportRow[]>(getRowsToChart, [rows]);
  const keysToChart = useMemo<string[]>(getKeysToChart, [rowsToChart]);

  const [quoteDate, setQuoteDate] = useState<Date | null>(props.quoteDate);
  const quoteDateString = useMemo(() => (quoteDate ? dayjs(quoteDate).format('YYYY-MM-DD') : null), [quoteDate]);

  const [budgetStartMonth, setBudgetStartMonth] = useState<ContractDate | null>(null);
  const [budgetEndMonth, setBudgetEndMonth] = useState<ContractDate | null>(null);
  const reportLoadingState = useLoadingState();
  const reportDownloadingState = useLoadingState();

  const [ stressIncrementString, setStressIncrementString ] = useState<string>("5, 10, 15");
  const [ selectedStressIncrementType, setSelectedStressIncrementType ] = useState<StressIncrementType>(StressIncrementType.Percent);
  const [ selectedCommodityIds, setSelectedCommodityIds ] = useState<number[]>([]);

  const [ isConfiguringReport, setIsConfiguringReport ] = useState<boolean>(false);
  const [ selectedReportAction, setSelectedReportAction ] = useState<ReportAction | null>('Run');


  // This should be generalized
  function updateStartAndEndDates() {
    if (reportSettings && customerSettings) {
      const currentMonth = dayjs().month() + 1;
      const currentYear = dayjs().year();

      const startMonth = (() => {
        const startMonthOffset = reportSettings.inputs?.budgetStartDate?.offset ?? 0;

        switch (reportSettings.inputs?.budgetStartDate?.strategy ?? 'fiscalYearStart') {
          case 'currentMonth':
            return currentMonth + startMonthOffset;
          case 'fiscalYearStart':
            return customerSettings.defaultActiveMonthStart!.month! + startMonthOffset;
        }
      })();

      // If the current month is the roll off month, then the default year is for next month
      const startYear =
        reportSettings.inputs?.budgetStartDate?.rollOffInToNewYearMonth && currentMonth >= reportSettings.inputs?.budgetStartDate?.rollOffInToNewYearMonth
          ? currentYear + 1
          : currentYear;

      const startBudgetMonth: ContractDate = { year: startYear, month: startMonth };
      const endBudgetDate: ContractDate | null = ContractDateHelper.addMonths(
        startBudgetMonth,
        (reportSettings.inputs?.budgetEndDate?.offsetFromStartMonth ?? 12) - 1,
      );

      setBudgetStartMonth(startBudgetMonth);
      setBudgetEndMonth(endBudgetDate);
    } else {
      setBudgetStartMonth(null);
      setBudgetEndMonth(null);
    }
  }

  function runReport() {
    const startYear = budgetStartMonth?.year;
    const startMonth = budgetStartMonth ? budgetStartMonth.month! : undefined;
    const endYear = budgetEndMonth?.year;
    const endMonth = budgetEndMonth ? budgetEndMonth.month! : undefined;

    CommodityManagementApi.getScenarioReport(
      reportLoadingState.setLoadingState, 
      quoteDateString!, 
      customerReport.customerReportId,
      startYear, 
      startMonth, 
      undefined, 
      endYear, 
      endMonth,
      undefined,
      selectedCommodityIds,
      undefined,
      selectedStressIncrementType,
      stressIncrementString?.split(',').map(num => Number(num.trim())).filter(num => !isNaN(num)) ?? []
    ).then((res) => { 
        setScenarios(res.data.rows ?? []);
    });
  };

  const downloadToExcel = () => {
    const startYear = budgetStartMonth?.year;
    const startMonth = budgetStartMonth ? budgetStartMonth.month! : undefined;
    const endYear = budgetEndMonth?.year;
    const endMonth = budgetEndMonth ? budgetEndMonth.month! : undefined;
    
    CommodityManagementApi.getScenarioExcelReport(
      reportDownloadingState.setLoadingState, 
      quoteDateString!, 
      customerReport.customerReportId,
      startYear, 
      startMonth, 
      undefined, 
      endYear, 
      endMonth,
      undefined,
      selectedCommodityIds,
      undefined,
      selectedStressIncrementType,
      stressIncrementString?.split(',').map(num => Number(num.trim())).filter(num => !isNaN(num)) ?? [],
      undefined,
      {responseType: 'blob', headers: {'ContentType': Constants.MediaType.Excel}}
    ).then(res => {
      reportDownloadingState.setLoadingState(LoadingState.Loaded);
      const contentDispositionHeader = res.headers['content-disposition'];
      const fileName  = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '') ?? 'KnowRisk Export';
      const url = window.URL.createObjectURL(new Blob([res.data! as any], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }).catch(x => {
      reportDownloadingState.setLoadingState(LoadingState.Failed);
    });
  }
  
  const getReport = () => {
    selectedReportAction === 'Run'
      ? runReport()
      : downloadToExcel();
  }

  function updateRows() {
    let rows = scenarios.flatMap(x => ScenarioVarianceModelToRowTransformer.scenarioVarianceModelToRow(x, !isSingleCommodity));

    // This shows the totals on each grouped row
    const levelsToAggregate = isSingleCommodity ? 1 : 2;
    let aggregatedRows = ScenarioVarianceModelToRowTransformer.getAggregatedRows(rows, levelsToAggregate, ScenarioVarianceModelToRowTransformer.aggregateScenarioReportRows);
    setRows([...rows, ...aggregatedRows]);
  }

  function getKeysToChart() {
    const stressIncrementType = rowsToChart[0]?.stressIncrementType;
    const currency = rowsToChart[0]?.currency;

    var allStressIncrements = rowsToChart.map(x => x.stressIncrement!);

    if (stressIncrementType == StressIncrementType.Percent) {
        return uniq(allStressIncrements).sort((a, b) => a - b).map(increment => StoneXNumberFormat({number: increment, flipped: true, standardNotation: true, percent: true  }));
    }
    else {
        return uniq(allStressIncrements).sort((a, b) => a - b).map(increment => StoneXNumberFormat({number: increment, flipped: true, standardNotation: true, currency: currency }));
    }
  }

  function getRowsToChart() {
    var rowsByStressIncrement = groupBy(rows, x => x.stressIncrement!);
    const result = Object.entries(rowsByStressIncrement).sort((a,b) => parseFloat(a[0]) - parseFloat(b[0])).map(([key, rows]) => {
      return ScenarioVarianceModelToRowTransformer.aggregateScenarioReportRows(rows.filter(x => !x.isAggregated), []);
    });
    return result;
  }

  function getIsSingleCommodity() {
    const allCommodityIds = scenarios.flatMap(x => x.commodityScenarios.map(y => y.commodity))?.map(x => x?.commodityId);
    return uniq(allCommodityIds).length == 1;
  }

  function onSelectedCommodityChange(commodities: CommodityModel | number[] | null) {
    if (commodities === null) {
      setSelectedCommodityIds([]);
      return;
    }

    if (isArray(commodities)) {
      console.log(commodities);
      setSelectedCommodityIds(commodities);
      return;
    }

    setSelectedCommodityIds([commodities.commodityId]);
  }

  function onStessIncrementChange(event: any) {
    setStressIncrementString(event?.target?.value);
  }

  function onSelectedStressIncrementTypeChange(event: React.MouseEvent<HTMLElement>, newValue: string | null) {
    if (newValue !== null) {
      setSelectedStressIncrementType(newValue as keyof typeof StressIncrementType);
    }
  }

  function closeModal() {
    setIsConfiguringReport(false);
  }

  useEffect(updateRows, [scenarios, customerReport]);
  useEffect(updateStartAndEndDates, [reportSettings, customerSettings]);

  return (
    <div>
      <StoneXRow extraHorizontalSpacing>
        <StoneXDatePicker onChange={setQuoteDate} label="Quote Date" value={quoteDate} width='small'/>
        {reportConfiguration?.inputs?.commodities?.multiple
          ? <StoneXMultiselect label="Commodities" list={customerData?.commodities ?? []} labelSelector={x => x.commodityName} valueSelector={x => x.commodityId} resultSetter={onSelectedCommodityChange} />
          : <StoneXSelect label="Commodity" options={customerData?.commodities ?? []} getId={x => x.commodityId} getOptionLabel={x => x.commodityName} onChange={onSelectedCommodityChange} width='small'/>
        }
        <StoneXTextField label="Stress Increments" width="small" defaultValue={stressIncrementString} onChange={onStessIncrementChange}/>
        <ToggleButtonGroup exclusive value={selectedStressIncrementType} onChange={onSelectedStressIncrementTypeChange}>
            <ToggleButton value={StressIncrementType.Percent}><Percent /></ToggleButton>
            <ToggleButton value={StressIncrementType.Price}><AttachMoney /></ToggleButton>
        </ToggleButtonGroup>
        {!reportSettings.inputs?.budgetStartDate?.hide && <StoneXContractMonthDropdown value={budgetStartMonth} label="Start Month" onChange={setBudgetStartMonth} width='small'/> }
        {!reportSettings.inputs?.budgetEndDate?.hide && <StoneXContractMonthDropdown value={budgetEndMonth} label="End Month" onChange={setBudgetEndMonth} width='small'/> }
        <StoneXSelect options={reportActions} getId={x => x} getOptionLabel={x => x} value={selectedReportAction} onChange={setSelectedReportAction} width='small' />
        <Button variant="outlined" onClick={getReport}>Go</Button>
        <Button className='pull-right' variant='outlined' onClick={() => setIsConfiguringReport(true)}>Configure Report</Button>
      </StoneXRow>

      <StoneXLoading show={reportLoadingState.isLoading()} />
      {reportLoadingState.isLoaded() && (
        <>
          <StoneXRow align='center'>
            <h2>{customerName} {customerReport.reportName}</h2> 
          </StoneXRow> 
         
          {/* reportSettings?.columns?.active */} 
          {rows && <ScenarioChart keys={keysToChart} rows={rowsToChart} activeSeries={reportSettings?.chart?.series?.active}/>}
          {scenarios && <ScenarioTable rows={rows} activeColumns={reportSettings?.columns?.active} />}
        </>
      )}

      <ScenarioReportSettingsModal open={isConfiguringReport} customerReport={customerReport} onClose={closeModal} onSave={closeModal} />
    </div>
  );
}
