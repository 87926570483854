import { List, ListItemButton, ListItemText } from '@mui/material';
import { every } from 'lodash';
import { useMemo } from 'react';
import { StoneXLabeledItem } from '..';

interface StoneXSelectListProps<T> {
  label: string;
  list: T[];
  selectedItems?: T[];
  onSelect: (selected: T[]) => void;
  onDeselect: (selected: T[]) => void;
  getId: (item: T) => string | number;
  getGroup?: (item: T) => string | number | undefined;
  getOptionLabel: (item: T) => string | number;
}

export function StoneXSelectList<T>(props: StoneXSelectListProps<T>) {
  const { label, list, selectedItems, onSelect, onDeselect, getId, getGroup, getOptionLabel } = props;

  type IdMap = { [key: string | number]: boolean };

  const selectedIdMap = useMemo<IdMap>(getSelectedIdMap, [selectedItems]);
  const groupedList = useMemo(updateGroupedList, [list]);

  function updateGroupedList(): [string | number | undefined, T[]][] {

    const groupedList: [string | number | undefined, T[]][] = [];

    let currentGroup = undefined;
    let groupedListIndex = 0;

    for (let i = 0; i < list.length; i++) {

      const item = list[i];
      const group = getGroup ? getGroup(item) : undefined;

      if (groupedListIndex == 0) {
        currentGroup = group;
        groupedList[groupedListIndex] = [group, [item]];  
        groupedListIndex++;
      }
      else {
        if (group == currentGroup) {
          const previousValues = groupedList[groupedListIndex - 1]?.[1] ?? [];
          groupedList[groupedListIndex- 1] = [group, [...previousValues, item]];
        }
  
        if (group != currentGroup) {
          currentGroup = group;
          groupedList[groupedListIndex] = [group, [item]];
          groupedListIndex++;
        }
      }
    }

    return groupedList;
  }

  function getSelectedIdMap() {

    if (!selectedItems) {
      return {};
    }

    const newMap: IdMap = {};
    for (let i = 0; i < selectedItems.length; i++) {
      const itemId = getId(selectedItems[i]);
      newMap[itemId] = true;
    }

    return newMap;
  }

  function toggleSelected(item: T) {
    const itemId = getId(item);
    const isSelected = selectedIdMap[itemId];

    if (isSelected) {
        onDeselect([item]);
    } else {
        onSelect([item]);
    }
  }

  function toggleSelectedGroup(group: [string | number | undefined, T[]]) {

    if (every(group[1], x => selectedIdMap[getId(x)])) {
      onDeselect(group[1])
    }
    else 
    {
      const unselectedItems = group[1].filter(x => !selectedIdMap[getId(x)]);
      onSelect(unselectedItems)
    }

  }

  return (
    <StoneXLabeledItem label={label} fullWidth fullHeight>
      <List style={{height: '100%', padding: 0, border: '1px solid var(--box-border-color)'}}>
        {groupedList.map((group, index) => {
          return <div key={index}>
            {group[0] === undefined ? <></> : <ListItemButton sx={{height: '40px'}} onClick={() => toggleSelectedGroup(group)}><strong>{group[0]}</strong></ListItemButton>}
            {group[1].map((x) => (
              <ListItemButton sx={{height: '40px', paddingLeft: group[0] == undefined ? undefined : '32px'}} key={getId(x)} selected={selectedIdMap[getId(x)]} onClick={() => toggleSelected(x)}>
                <ListItemText primary={getOptionLabel(x)} />
              </ListItemButton>
            ))}
          </div>;
        })}
      </List>
    </StoneXLabeledItem>
  );
}
