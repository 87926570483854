import { TextField } from '@mui/material';
import { useMemo } from 'react';
import { StoneXLabeledItem } from '..';

interface StoneXTextFieldProps {
  id?: string;
  label?: string;
  type?: any;
  defaultValue?: any;
  min?: number;
  max?: number;
  name?: string;
  value?: any;
  error?: boolean;
  placeholder?: any;
  fullWidth?: boolean;
  width?: 'small' | string | number;
  disabled?: boolean;
  showRequired?: boolean;
  multiline?: boolean;
  minRows?: string | number;
  maxRows?: string | number;
  onChange?: (event: any) => void;
  onUpdate?: (value: string) => void;
}

export function StoneXTextField(props: StoneXTextFieldProps) {

  const width = useMemo(() => {

    if (props.width === 'small') {
      return '180px';
    }

    if (props.fullWidth) {
      return '100%';
    }

    return props.width;

  }, [props.width]);

  function onChangeInternal(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    props.onChange && props.onChange(event);
    props.onUpdate && props.onUpdate(event.target.value)
  }

  return (
    <StoneXLabeledItem label={props.label} fullWidth={props.fullWidth} showRequired={props.showRequired}>
      <TextField
         sx={{
          '& .MuiInputBase-input': { 
            textAlign: props.type === 'price' || props.type == 'volume' ? 'right' : undefined, 
          },
          '& .MuiInputBase-root': {
            width: width,
            '& textarea': {
              resize: 'vertical', // Allows resizing both horizontally and vertically
              overflow: 'auto', // Allows scrolling if the content exceeds the size
            },
          },
        }}
        InputProps={{ inputProps: { min: props.min, max: props.max } }}
        id={props.id}
        error={props.error}
        disabled={props.disabled}
        value={props.value}
        onChange={onChangeInternal}
        type={props.type}
        name={props.name}
        placeholder={props.placeholder}
        defaultValue={props.defaultValue}
        multiline={props.multiline}
        minRows={props.minRows} 
        maxRows={props.maxRows}
      />
    </StoneXLabeledItem>
  );
}
