import { Button, ButtonGroup, Chip, ClickAwayListener, IconButton, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import { ColDef, ITooltipParams, ValueGetterParams } from 'ag-grid-community';
import { ICellRendererParams } from 'ag-grid-enterprise';
import { AgGridReactProps } from 'ag-grid-react';
import dayjs from 'dayjs';
import { memo, useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { GetCustomerSettingsResponse } from '../../../../../Generated/Commodity-Management-Api';
import { StoneXGrid } from '../../../../StoneX';
import { MoreVert } from '@mui/icons-material';
import { PositionRow } from './Types';

interface PositionTableProps {
  customerSettings: GetCustomerSettingsResponse;
  positions: PositionRow[];
  onSetPositionValidation: (dealTicketId: number) => void;
}

export default function PositionTable(props: PositionTableProps) {
  const { customerSettings, positions, onSetPositionValidation } = props;

  const navigate = useNavigate();

  function editDealTicket(dealTicketId: number) {
    const params = new URLSearchParams({
      dealTicketId: dealTicketId.toString(),
    });

    navigate(`update?${params.toString()}`);
  }

  const allColumns = useMemo<ColDef[]>(
    () => [
      {
        colId: 'tradeDate',
        headerName: 'Trade Date',
        field: 'tradeDate',
        sortable: true,
        minWidth: 110,
        flex: 1,
        valueFormatter: (params: any) => dayjs(params.value).format('L'),
        filter: 'agDateColumnFilter',
      },
      {
        colId: 'budgetDate',
        headerName: 'Budget Month',
        field: 'budgetDate.label',
        sortable: true,
        minWidth: 120,
        flex: 1,
        filter: 'agDateColumnFilter',
      },
      // {
      //   colId: 'contractDate',
      //   headerName: 'Contract Month',
      //   field: 'contractDate.label',
      //   sortable: true,
      //   minWidth: 130,
      //   flex: 1,
      //   filter: 'agDateColumnFilter',
      // },
      {
        colId: 'commodity',
        headerName: 'Commodity',
        field: 'commodity.commodityName',
        sortable: true,
        minWidth: 145,
        flex: 2,
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'location',
        headerName: 'Location',
        field: 'location.locationName',
        sortable: true,
        minWidth: 120,
        flex: 2,
      },
      {
        colId: 'counterparty',
        headerName: 'Counterparty',
        field: 'counterparty.counterpartyName',
        sortable: true,
        minWidth: 140,
        flex: 2,
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'side',
        headerName: 'Side',
        field: 'side',
        sortable: true,
        minWidth: 110,
        flex: 1,
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'volume',
        headerName: 'Volume',
        field: 'volume',
        sortable: true,
        cellClass: 'justify-right',
        minWidth: 125,
        flex: 1,
        filter: 'agNumberColumnFilter',
      },
      {
        colId: 'price',
        headerName: 'Price',
        field: 'price',
        sortable: true,
        cellClass: 'justify-right',
        minWidth: 110,
        flex: 1,
        filter: 'agNumberColumnFilter',
      },
      {
        colId: 'unit',
        headerName: 'Unit',
        field: 'unit',
        sortable: true,
        minWidth: 110,
        flex: 1,
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'currency',
        headerName: 'Ccy',
        field: 'currency',
        sortable: true,
        minWidth: 100,
        flex: 1,
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'positionTypeOrOption',
        headerName: 'Position Type',
        field: 'positionTypeOrOption',
        sortable: true,
        minWidth: 150,
        flex: 1,
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'dealTicket',
        headerName: 'Deal Ticket',
        field: 'dealTicketNumber',
        sortable: true,
        minWidth: 100,
        flex: 3,
        filter: 'agTextColumnilter',
      },
      {
        colId: 'validated',
        headerName: 'Status',
        field: 'isValidated',
        sortable: true,
        width: 120,
        tooltipValueGetter: (params: ITooltipParams<PositionRow>) => (params.data?.isValidated ? 'Validated' : 'Unvalidated'),
        cellRenderer: (params: ICellRendererParams) => (
          <Chip variant={params.data?.isValidated ? 'success' : 'neutral'} label={params.data?.isValidated ? 'VAL' : 'UNV'} />
        ),
        valueGetter: (params: ValueGetterParams<PositionRow>) => params.data?.isValidated ? "Validated" : "Unvalidated",
        filter: 'agSetColumnFilter',
      },
      {
        colId: 'actions',
        headerName: '',
        width: 40,
        resizable: false,
        cellStyle: { padding: '0' },
        sortable: false,
        suppressAutoSize: true,
        suppressMovable: true,
        cellRenderer: (props: ICellRendererParams<PositionRow>) => {

            const [open, setOpen] = useState(false);
            const anchorRef = useRef<HTMLButtonElement | null>(null);

            return <div>
              <IconButton ref={anchorRef} sx={{borderRadius: 1}} onClick={() => setOpen(true)}><MoreVert /></IconButton>
              
              <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" >
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <Paper>
                    <MenuList autoFocusItem={open}>
                      <MenuItem onClick={() => {onSetPositionValidation(props.data!.dealTicketId!); setOpen(false)}}>Set Validation</MenuItem>
                      <MenuItem onClick={() => editDealTicket(props.data!.dealTicketId!)}>Edit</MenuItem>
                      <MenuItem onClick={() => {}}>Delete</MenuItem>
                      <MenuItem onClick={() => {}}>Audit</MenuItem>
                    </MenuList>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
        },
      },
    ],
    [],
  );

  const columns = useMemo<ColDef[]>(() => allColumns.filter(x => {

    if (x.colId == 'location' && !customerSettings.positionsByLocation ) {
      return false;
    }
    
    if (x.colId == 'counterparty' && !customerSettings.positionsByCounterparty ) {
      return false;
    }

    return true;

  }), [allColumns, customerSettings])


  const gridOptions: AgGridReactProps = useMemo(() => {
    return {
      treeData: true,
      getDataPath: (x: PositionRow) => x.path,
      enableRangeSelection: true,
      groupDisplayType: "groupRows",
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
      groupRowRendererParams: {
        suppressCount: true
      }
    };
  }, []);

  return (
    <StoneXGrid rows={positions} columns={columns} gridOptions={gridOptions} getRowId={x => x.data.positionId} onlyShowGroupLabels />
  );
}
