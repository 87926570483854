import { Button, Chip } from '@mui/material';
import { ColDef, FirstDataRenderedEvent, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { CommodityManagementApi } from '../../../../../Apis/Apis';
import { DealTicketModel, PositionModel, Unit } from '../../../../../Generated/Commodity-Management-Api';
import { useLoadingState } from '../../../../../Hooks';
import { StoneXLoading, StoneXRow, StoneXGrid } from '../../../../StoneX';
import { StoneXLabeledItem, StoneXModal, StoneXNumberFormat } from '../../../../StoneXMui';
import { groupBy, includes, sum } from 'lodash';
import { useSnackbar } from 'notistack';

interface PositionValidationModalProps {
  open: boolean;
  dealTicketId?: number;
  onClose: () => void;
  onValidationUpdated: (tradeId: number, validatedPositionIds: number[]) => void;
}

export default function PositionValidationModal(props: PositionValidationModalProps) {
  const { open, dealTicketId, onClose, onValidationUpdated } = props;
  const gridRef = useRef<AgGridReact<PositionModel>>(null);

  const [dealTicket, setDealTicket] = useState<DealTicketModel>();
  const tradeLoadingState = useLoadingState();
  const validationSaveLoadingState = useLoadingState();
  
  type UnitVolumeAndPrice = {unit: Unit, volume: number, weightedPrice: number};
  const totalVolumeAndWeightedPrice: UnitVolumeAndPrice[] = useMemo(getVolumeAndWeightedPrices, [dealTicket]);
  const [ originalValidatedPositionIds, setOriginalValidatedPositionIds ] = useState<number[]>();
  
  const { enqueueSnackbar } = useSnackbar();

  useEffect(getDealTicketOnOpen, [open]);

  function getDealTicketOnOpen() {

    if (!open) {
      setDealTicket(undefined);
    }

    if (open && dealTicketId) {
      CommodityManagementApi.getDealTicket(tradeLoadingState.setLoadingState, `${dealTicketId}`).then((res) => {
        setDealTicket(res.data.dealTicket);
        setOriginalValidatedPositionIds(res.data.dealTicket?.positions?.filter(x => x.isValidated).map(x => x.positionId));
      });
    }
  }

  function updateValidation() {
    const selectedRows = gridRef!.current!.api!.getSelectedRows();
    const validatedPositionIds = selectedRows.map((q) => q.positionId) ?? [];

    let numberOfPositionsAffected: number = 0;

    dealTicket?.positions.forEach(x => {
      const previousValidation = includes(originalValidatedPositionIds, x.positionId);
      const currentValidation = includes(validatedPositionIds, x.positionId);

      if (previousValidation !== currentValidation) {
        numberOfPositionsAffected++;
      }
    });

    CommodityManagementApi.updateDealTicketPositionValidation(
      validationSaveLoadingState.setLoadingState,
      dealTicket!.dealTicketId!.toString(),
      validatedPositionIds,
    ).then(() => {
      if (numberOfPositionsAffected > 0) {
        onValidationUpdated(dealTicket!.dealTicketId!, validatedPositionIds);
        enqueueSnackbar(`Validation successfully updated for ${numberOfPositionsAffected} positions.`, {variant: 'success'})
      }
    });

    onClose();
  }

  function onDataFirstRendered(params: FirstDataRenderedEvent<PositionModel>) {
    params.api.forEachNode((node) => node.setSelected(!!node.data && node.data.isValidated));
    params.api.sizeColumnsToFit();
  }

  function onRowSelected(event: any) {
    const node = event.node;
    node.data.isValidated = !node.data.isValidated;
    gridRef?.current?.api.refreshCells({ rowNodes: [node]});
  }

  function getVolumeAndWeightedPrices(): UnitVolumeAndPrice[] {
    const positionsByUnit = groupBy(dealTicket?.positions, x => x.unit);
    const result: UnitVolumeAndPrice[] = [];

    Object.entries(positionsByUnit).forEach((([unit,positions]) => {
      const totalVolume = sum(positions.map(x => x.volume));
      const weightedPrice = sum(positions.map(x => x.price * x.volume)) / totalVolume;

      result.push({
        unit: unit as Unit,
        volume: totalVolume,
        weightedPrice: weightedPrice
      });
    }))

    return result;
  }

  const columnDefs: ColDef[] = [
    { width: 65, headerCheckboxSelection: true, checkboxSelection: true },
    {
      headerName: 'Status',
      field: 'isValidated',
      sortable: true,
      width: 110,
      cellRenderer: (props: ICellRendererParams) => (
        <Chip variant={props.node.isSelected() ? 'success' : 'neutral'} label={props.node.isSelected() ? 'VAL' : 'UNV'} />
      )
    },
    { headerName: 'Budget Date', field: 'budgetDate.label' },
    { headerName: 'Contract Date', field: 'contractDate.label' },
    // { headerName: 'Location', field: 'location.locationName' },
    { headerName: 'Price', field: 'price', cellClass: 'justify-right' },
    { headerName: 'Volume', field: 'volume', cellClass: 'justify-right' },
    { headerName: 'Position Type', field: 'positionTypeOrOption' },
    { headerName: 'Side', field: 'side' },
    { headerName: 'Unit', field: 'unit' },
    { headerName: 'Currency', field: 'currency' },
  ];

  const defaultColDef = {
    resizable: true,
    suppressMenu: true,
  };

  return (
    <StoneXModal header="Set Validation" open={open} onClose={onClose} width="100%">
      <StoneXLoading show={tradeLoadingState.isLoading()} />

      {tradeLoadingState.isLoaded() && dealTicket && (
        <>
          <StoneXRow>
            <StoneXLabeledItem width="175px" label="Trade Date">
              {dayjs(dealTicket.tradeDate).format('L')}
          </StoneXLabeledItem>
            <StoneXLabeledItem width="175px" label="Commodity">
              {dealTicket.commodity?.commodityName}
            </StoneXLabeledItem>
            <StoneXLabeledItem width="175px" label="Counterparty">
              {dealTicket.counterparty?.counterpartyName}
            </StoneXLabeledItem>
            <StoneXLabeledItem width="175px" label="Deal Ticket">
              {dealTicket.dealTicketNumber}
            </StoneXLabeledItem>
            <StoneXLabeledItem width="175px" label="Total Volume">
              {totalVolumeAndWeightedPrice.map((x, i) => <span key={i} style={{ display: 'block' }}>{x.volume} {x.unit}</span>)}
            </StoneXLabeledItem>
            <StoneXLabeledItem width="175px" label="Weighted Average">
              {totalVolumeAndWeightedPrice.map((x, i) => <span key={i} style={{ display: 'block' }}>{StoneXNumberFormat({number: x.weightedPrice, decimals: dealTicket.commodity.displayPrecision})}</span>)}
            </StoneXLabeledItem>
          </StoneXRow>

          <StoneXRow>
            <div style={{width: '100%', height: '100%'}}>
              <StoneXGrid ref={gridRef} rows={dealTicket?.positions} columns={columnDefs} gridOptions={{suppressRowClickSelection: true, rowSelection: 'multiple', onGridReady: onDataFirstRendered, onRowSelected: onRowSelected}} pattern='alternate' hideGridOptions/>
            </div>
          </StoneXRow>
{/* 
          <StoneXRow>
            <div className="ag-theme-alpine" style={{ height: '600px', width: '100%' }}>
              <AgGridReact
                ref={gridRef}
                domLayout='normal'
                suppressRowClickSelection
                rowData={trade?.positions}
                defaultColDef={defaultColDef}
                columnDefs={columnDefs}
                rowSelection="multiple"
                onGridReady={onDataFirstRendered}
                onRowSelected={onRowSelected}
              />
            </div>
          </StoneXRow> */}
        </>
      )}

      {validationSaveLoadingState.isLoading() ? 'Saving....' : ''}

      <StoneXRow justify="end">
        <Button variant="outlined" onClick={onClose}>
          Cancel
        </Button>
        <Button disabled={!dealTicket} onClick={updateValidation}>
          Update Validation
        </Button>
      </StoneXRow>
    </StoneXModal>
  );
}
