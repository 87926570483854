import React from 'react';

interface StoneXNumberProps {
  number?: number;
  decimals?: number;
  colored?: boolean;
}

export function StoneXPercent(props: StoneXNumberProps) {
  
  const { number, decimals = 0, colored = false } = props;


  const formattedNumber = number !== undefined 
    ? Math.abs(number*100).toLocaleString(undefined, { minimumFractionDigits: decimals, maximumFractionDigits: decimals })
    : '-';

  const colorClass = getColorClass(number, colored);

  const displayNumber = formattedNumber;
  console.log(formattedNumber, Number(formattedNumber));  


  return (
    <span className={colorClass} >
      {displayNumber}%
    </span>
  );
}

function getColorClass(number: number | undefined, colored: boolean) {
  if (!colored || number === undefined || number === 0) return undefined;
  return number > 0 ? 'black' : 'red';
}
