import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import { BudgetVarianceModel, ContractDate, CustomerReportModel, GetCustomerDataResponse, GetCustomerSettingsResponse } from '../../../../../../Generated/Commodity-Management-Api';
import { ContractDateHelper } from '../../../../../../Helpers';
import { deepMerge } from '../../../../../../Helpers/ObjectHelper';
import { useLoadingState } from '../../../../../../Hooks';
import { GroupingOption, LoadingState } from '../../../../../../Types';
import { StoneXLoading, StoneXRow } from '../../../../../StoneX';
import { StoneXContractMonthDropdown, StoneXDatePicker, StoneXSelect } from '../../../../../StoneXMui';
import { BudgetVarianceModelToRowTransformer } from './BudgetVarianceModelToRowTransformer';
import { VarianceReportConfiguration, VarianceReportRow, VarianceReportUserOverride, ChartTypes } from './Types';
import VarianceReportSettingsModal from './VarianceReportSettingsModal';
import { VarianceTable } from './VarianceTable';
import { VarianceValueChart } from './VarianceValueChart';
import { FixedOptionCoverageChart } from './FixedOptionCoverageChart';
import { sum } from 'lodash';
import VarianceReportSummaryDisplay from './VarianceReportSummaryDisplay';
import { Constants } from '../../../../../../Core/Constants';

type ReportAction = 'Run' | 'Export to Excel';
const reportActions: ReportAction[] = ['Run', 'Export to Excel'];

export interface VarianceReportProps {
  customerName?: string;
  customerReport: CustomerReportModel;
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  quoteDate: Date;
} 

export default function VarianceReport(props: VarianceReportProps) {

  const { customerName, customerReport, customerData, customerSettings } = props;

  const [quoteDate, setQuoteDate] = useState<Date | null>(props.quoteDate);
  const quoteDateString = useMemo(() => quoteDate ? dayjs(quoteDate).format('YYYY-MM-DD') : null, [quoteDate]);
  
  const [budgetStartMonth, setBudgetStartMonth] = useState<ContractDate | null>(null);
  const [budgetEndMonth, setBudgetEndMonth] = useState<ContractDate | null>(null);

  const reportLoadingState = useLoadingState();
  const reportDownloadingState = useLoadingState();

  const [budgetVariances, setBudgetVariances] = useState<BudgetVarianceModel[]>([]);
  const [rows, setRows] = useState<VarianceReportRow[]>([]);

  const reportConfiguration = useMemo(() => JSON.parse(customerReport.configuration!) as VarianceReportConfiguration, [customerReport]);
  const userOverrides = useMemo(() => customerReport.userOverrides ? JSON.parse(customerReport.userOverrides) as VarianceReportUserOverride : undefined, [customerReport]);
  const reportSettings: VarianceReportConfiguration = useMemo(() => deepMerge(reportConfiguration, userOverrides), [reportConfiguration, userOverrides]);

  const [groupingOptions, setGroupingOptions] = useState<GroupingOption[]>([]);
  const [ isConfiguringReport, setIsConfiguringReport ] = useState<boolean>(false);
  const [ selectedReportAction, setSelectedReportAction ] = useState<ReportAction | null>('Run');

  const runReport = () => {
    const startYear = budgetStartMonth?.year;
    const startMonth = budgetStartMonth ? budgetStartMonth.month! : undefined;
    const endYear = budgetEndMonth?.year;
    const endMonth = budgetEndMonth ? budgetEndMonth.month! : undefined;

    CommodityManagementApi.getVarianceReport(
      reportLoadingState.setLoadingState, 
      quoteDateString!, 
      customerReport.customerReportId,
      startYear, 
      startMonth, 
      undefined, 
      endYear, 
      endMonth).then((res) => { 
      setBudgetVariances(res.data.rows ?? []);
    });
    
  };

  const downloadToExcel = () => {
    const startYear = budgetStartMonth?.year;
    const startMonth = budgetStartMonth ? budgetStartMonth.month! : undefined;
    const endYear = budgetEndMonth?.year;
    const endMonth = budgetEndMonth ? budgetEndMonth.month! : undefined;
    
    CommodityManagementApi.getVarianceExcelReport(
      reportDownloadingState.setLoadingState, 
      quoteDateString!, 
      customerReport.customerReportId,
      startYear, 
      startMonth, 
      undefined, 
      endYear, 
      endMonth,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      {responseType: 'blob', headers: {'ContentType': Constants.MediaType.Excel}}
    ).then(res => {
      reportDownloadingState.setLoadingState(LoadingState.Loaded);
      console.log('saving file now');
      console.log('header', res.headers);
      const contentDispositionHeader = res.headers['content-disposition'];
      console.log('header', contentDispositionHeader);
      const fileName  = contentDispositionHeader.split(';')[1].trim().split('=')[1].replace(/"/g, '') ?? 'KnowRisk Export';
      // const fileName = 'Export.xlsx';
      console.log('filename is ', fileName);
      const url = window.URL.createObjectURL(new Blob([res.data! as any], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download',fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    }).catch(x => {
      reportDownloadingState.setLoadingState(LoadingState.Failed);
    });
  }

  const getReport = () => {
    selectedReportAction === 'Run'
      ? runReport()
      : downloadToExcel();
  }

  // const exportXlsx = () => CommodityManagementApi.getMarkToMarketExcelReport(undefined, quoteDateString!, false, {responseType: 'blob', headers: {'ContentType': Constants.MediaType.Excel}});

  function updateStartAndEndDates() {
    if (reportSettings && customerSettings) {

      const currentMonth = dayjs().month() + 1;
      const currentYear = dayjs().year();

      const startMonth = (() => {
        
        const startMonthOffset = reportSettings.inputs?.budgetStartDate?.offset ?? 0;

        switch(reportSettings.inputs?.budgetStartDate?.strategy ?? 'fiscalYearStart') {
          case 'currentMonth': return currentMonth + startMonthOffset;
          case 'fiscalYearStart': return customerSettings.defaultActiveMonthStart!.month! + startMonthOffset;
        }
      })();

      // If the current month is the roll off month, then the default year is for next month
      const startYear = reportSettings.inputs?.budgetStartDate?.rollOffInToNewYearMonth && currentMonth >= reportSettings.inputs?.budgetStartDate?.rollOffInToNewYearMonth
        ? currentYear + 1
        : currentYear;

      const startBudgetMonth: ContractDate = {year: startYear, month: startMonth};
      const endBudgetDate: ContractDate | null = ContractDateHelper.addMonths(startBudgetMonth, (reportSettings.inputs?.budgetEndDate?.offsetFromStartMonth ?? 12) - 1);

      setBudgetStartMonth(startBudgetMonth);
      setBudgetEndMonth(endBudgetDate);
    }
    else {
      setBudgetStartMonth(null);
      setBudgetEndMonth(null);
    }
  }

  function updateRows() {

    // Filter out undefined groupings
    const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1], "BudgetMonth" as GroupingOption].filter(x => !!x).map(x => x!);

    let variances = budgetVariances.map(b=>BudgetVarianceModelToRowTransformer.budgetVarianceModelToRow(b, new Date(quoteDate!)));

    BudgetVarianceModelToRowTransformer.updatePaths(variances, selectedGroupings);

    // This shows the totals on each grouped row
    let aggregatedRows = BudgetVarianceModelToRowTransformer.getAggregatedRows(variances, selectedGroupings.length, BudgetVarianceModelToRowTransformer.aggregateVarianceReportRows);

    // Get totals by month
    let monthTotals = BudgetVarianceModelToRowTransformer.getAggregatedRowsGrouped(variances, selectedGroupings.length - 2, BudgetVarianceModelToRowTransformer.aggregateVarianceReportRows, x => x.budgetDate!.label!, 'Totals by month');
    monthTotals.forEach(x => x.isTotalByMonth = true);

    setRows([...variances, ...aggregatedRows, ...monthTotals]);
  }
  
  function updateGroupingOptions() {

    const groupingOptions: GroupingOption[] = [];

    if (customerSettings.positionsByLocation) {
      groupingOptions.push('BudgetingTier');
      groupingOptions.push('Tiers');
    }

    if (customerSettings.positionsByCounterparty) {
      groupingOptions.push('Counterparty');
    }

    setGroupingOptions(groupingOptions);
  }0

  function closeModal() {
    setIsConfiguringReport(false);
    }

    function getChartType() {
        if (userOverrides?.chart?.chart == 'Fixed/Call Coverage')
            return <FixedOptionCoverageChart rows={rows} pathToChart={pathToChart} />
        else
            return <VarianceValueChart rows={rows} pathToChart={pathToChart} />
    }

  useEffect(updateStartAndEndDates, [reportSettings, customerSettings]);
  useEffect(updateRows, [budgetVariances, customerReport])
  useEffect(updateGroupingOptions, []);

  const [pathToChart, setPathToChart] = useState<string[] | undefined>();

  return (
    <div>
      <StoneXRow extraHorizontalSpacing>
        <StoneXDatePicker onChange={setQuoteDate} label="Quote Date" value={quoteDate} width='small'/>
        {!reportSettings.inputs?.budgetStartDate?.hide && <StoneXContractMonthDropdown value={budgetStartMonth} label="Start Month" onChange={setBudgetStartMonth} width='small'/> }
        {!reportSettings.inputs?.budgetEndDate?.hide && <StoneXContractMonthDropdown value={budgetEndMonth} label="End Month" onChange={setBudgetEndMonth} width='small'/> }
        <StoneXSelect options={reportActions} getId={x => x} getOptionLabel={x => x} value={selectedReportAction} onChange={setSelectedReportAction} width='small' />
        <Button variant="outlined" onClick={getReport}>Go</Button>
        <Button className='pull-right' variant='outlined' onClick={() => setIsConfiguringReport(true)}>Configure Report</Button>
      </StoneXRow>

      <StoneXLoading show={reportLoadingState.isLoading()} />
      {reportLoadingState.isLoaded() && (
        <>
          <StoneXRow align='center'>
            <h2>{customerName} {customerReport.reportName}</h2>
            <VarianceReportSummaryDisplay variances={rows} quoteDateString={quoteDateString!} />
          </StoneXRow> 
         
          {rows && getChartType()}
          {rows && <VarianceTable rows={rows} activeColumns={reportSettings?.columns?.active} setPathToChart={setPathToChart}/>}
        </>
      )}

      <VarianceReportSettingsModal open={isConfiguringReport} customerReport={customerReport} groupingOptions={groupingOptions} onClose={closeModal} onSave={closeModal} />
    </div>
  );
}
