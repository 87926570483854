import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetCustomerDataResponse, GetCustomerSettingsResponse, GetCustomerReportsResponse, CustomerReportModel } from '../../Generated/Commodity-Management-Api';

interface CommodityManagementState {
  customerData?: GetCustomerDataResponse;
  customerSettings?: GetCustomerSettingsResponse;
  customerReports?: CustomerReportModel[];
}

interface SetCommodityManagementPayload {
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  customerReports: CustomerReportModel[];
}

interface SetCustomerReportUserOverridePayload {
  customerReportId: number;
  userOverrides: string | null | undefined;
}
  
const initialState: CommodityManagementState = {
};

export const userAccessSlice = createSlice({
    name: 'userAccess',
    initialState,
    reducers: {
      setCommodityManagementState: (state: CommodityManagementState, { payload }: PayloadAction<SetCommodityManagementPayload>): void => {
        state.customerData = payload.customerData;
        state.customerSettings = payload.customerSettings;
        state.customerReports = payload.customerReports;
      },
      resetCommodityManagementState: (state: CommodityManagementState) => {
        state.customerData = undefined;
        state.customerSettings = undefined;
        state.customerReports = undefined;
      },
      setCustomerReportUserOverride: (state: CommodityManagementState, { payload }: PayloadAction<SetCustomerReportUserOverridePayload>): void => {
        state.customerReports = state.customerReports?.map(x => {

          if (x.customerReportId == payload.customerReportId) {
            return {...x, userOverrides: payload.userOverrides}
          }

          return x;
        })
      }
    }
  });
  
  export const { setCommodityManagementState, resetCommodityManagementState, setCustomerReportUserOverride } = userAccessSlice.actions;
  export default userAccessSlice.reducer;