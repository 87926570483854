import { Add, Close } from '@mui/icons-material';
import { Box, Tab, Tabs } from '@mui/material';
import dayjs from 'dayjs';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { MarkItViewApi } from '../../../Apis/Apis';
import { MarkItViewQuerySavedParameters, MarkItViewSavedQueryModel } from '../../../Generated/Mark-It-View-Api';
import { useLoadingState, useMtmDate } from '../../../Hooks';
import { MarkItViewQueryParameters } from '../../../Types/MarkItViewQueryParameters';
import { MarkItViewSavedQuerySet } from '../../../Types/MarkItViewSavedQuerySet';
import StoneXMainPage from '../../StoneX/StoneXMainPage/StoneXMainPage';
import { StoneXAutocomplete } from '../../StoneXMui';
import './MarketView.scss';
import { QueryPage } from './QueryPage/QueryPage';
import { StoneXRow } from '../../StoneX';

interface QueryPageData {
  id: number;
  title: string;
  queryPage: ReactElement;
}

interface MarketViewProps {
}

export default function MarketViewSolution(props: MarketViewProps) {
  // const [queryPages, setQueryPages] = useState<ReactElement[]>([]);
  const [queryPages, setQueryPages] = useState<QueryPageData[]>([]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const querySaveLoadingState = useLoadingState();
  // const savedQuerySets = useListResource<MarkItViewSavedQueryModel, string>([], null, (item) => item.name);
  const savedQuerySetsLoadingState = useLoadingState();
  const [savedQuerySets, setSavedQuerySets] = useState<MarkItViewSavedQueryModel[]>([]);

  const loadSavedQuerySets = (): void => {
    MarkItViewApi.listMarketViewSavedQuery(savedQuerySetsLoadingState.setLoadingState).then((q) => setSavedQuerySets(q.data.rows ?? []));
  };

  const newQueryPage = (savedQueryToLoad?: MarkItViewSavedQuerySet) => {
    const newPage = (
      <QueryPage id={pageCount} onTitleChange={onTitleChange} save={(q: MarkItViewSavedQuerySet) => saveQuerySet(q)} querySet={savedQueryToLoad} />
    );
    const newPageData = { id: pageCount, title: `Query ${pageCount}`, queryPage: newPage };
    const newQueryPages = [...queryPages, newPageData];
    setQueryPages(newQueryPages);

    setActiveTabIndex(newQueryPages.length - 1);

    setPageCount(pageCount + 1);
  };

  const onTitleChange = (id: number, newTitle: string) => {
    setQueryPages((previous) => previous.map((q) => (q.id == id ? { ...q, title: newTitle } : q)));
  };

  const onTabChange = (event: React.SyntheticEvent, newTabIndex: number) => {
    setActiveTabIndex(newTabIndex);
  };

  const onLoadQueryChange = (e: any, item: MarkItViewSavedQueryModel | null) => {
    const savedQuerySet = {
      id: item?.savedQueryId,
      name: item?.name,
      querySetParameters: item?.queriesParameters.map((q) => {
        return {
          ...q,
          startQuoteDate: dayjs(useMtmDate())
            .subtract(q.daysToLookback ? -q.daysToLookback : 1, q.daysToLookback ? 'day' : 'year')
            .toDate(),
          endQuoteDate: useMtmDate(),
          mtmDate: useMtmDate(),
        } as MarkItViewQueryParameters;
      }),
    } as MarkItViewSavedQuerySet;

    newQueryPage(savedQuerySet);
  };

  const deleteTab = (indexToDelete: number) => {
    if (queryPages.length == 1) {
      // setActiveTabIndex(0);
      // newQueryPage();
      return;
    } else if (queryPages.length - 1 == activeTabIndex) {
      setActiveTabIndex(activeTabIndex - 1);
    }

    setQueryPages((previous) => previous.filter((q, i) => i !== indexToDelete));
  };

  const retrieveSavedQuerySets = (): void => {};

  const loadSavedQuerySet = (query: MarkItViewSavedQuerySet): void => {};

  const saveQuerySet = (query: MarkItViewSavedQuerySet): Promise<void> => {
    const newItemToSave = query.querySetParameters.map((q) => {
      let daysToLookback: number | null = null;

      if (q.startQuoteDate && q.endQuoteDate) {
        daysToLookback = dayjs(q.startQuoteDate).diff(q.endQuoteDate, 'day');
      }

      return {
        id: q.id,
        daysToLookback: daysToLookback,
        priceQueryType: q.priceQueryType,
        years: q.years,
        months: q.months,
        commodityType: q.commodityType,
        productId: q.productId,
        contractStartDate: q.startContractDate,
        contractEndDate: q.endContractDate,
        monthsAhead: q.monthsAhead,
      } as MarkItViewQuerySavedParameters;
    });

    const promise = MarkItViewApi.addMarketViewSavedQuery(querySaveLoadingState.setLoadingState, query.id, query.name, newItemToSave)
      .then((response) => {
        const newItem = response.data.savedQuery;
        setSavedQuerySets((previous) => {
          return [...previous.filter((q) => q.savedQueryId != newItem?.savedQueryId), newItem!];
        });
      })
      .catch((error) => console.log(error));

    return promise;
  };

  const deleteQuerySet = (query: MarkItViewSavedQuerySet): void => {};

  const renderTabs = (): ReactNode[] => {
    return queryPages.map((queryPage, index) => {
      return (
        <Tab
          sx={{ maxWidth: 300 }}
          label={
            <span style={{ width: '100%', display: 'inline-flex', justifyContent: 'space-between', gap: 10 }}>
              <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{queryPage.title}</span>
              <Close
                fontSize="small"
                sx={{ color: 'gray', '&:hover': { color: 'inherit' } }}
                onClick={(e) => {
                  e.stopPropagation();
                  deleteTab(index);
                }}
              />
            </span>
          }
          key={queryPage.id}
        />
      );
    });
  };

  useEffect(() => {
    loadSavedQuerySets();
    if (queryPages.length == 0) {
      newQueryPage();
    }
  }, []);

  const MarketViewContextualHeader = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', margin: '0 20px' }}>
        <Tabs value={activeTabIndex} onChange={onTabChange}>
          {renderTabs()}
          <Tab
            disableFocusRipple
            sx={{ padding: 0 }}
            icon={<Add sx={{ fontSize: '2em' }} />}
            onClick={(e) => {
              e.stopPropagation();
              newQueryPage();
            }}
          />
        </Tabs>
        <StoneXAutocomplete
          options={savedQuerySets}
          getOptionLabel={(savedQuery) => savedQuery!.name}
          loading={savedQuerySetsLoadingState.isLoading}
          value={{ savedQueryId: 0, name: 'Load saved query', queriesParameters: [] }}
          variant="standard"
          label=""
          onChange={onLoadQueryChange}
        />
      </div>
    );
  };

  return (
    <StoneXMainPage>
      <StoneXRow>
        <h1>Mark-It-View Price Query</h1>
      </StoneXRow>
      
      <MarketViewContextualHeader />

      {queryPages.map((q, i) => (
        <Box sx={{ display: activeTabIndex == i ? 'block' : 'none' }} key={q.id}>
          {q.queryPage}
        </Box>
      ))}
    </StoneXMainPage>
  );
}
