import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { sum } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import { Constants } from '../../../../../../Core/Constants';
import { ContractDate, CustomerReportModel, ExpiryStatus, GetCustomerDataResponse, GetCustomerSettingsResponse, PositionModel } from '../../../../../../Generated/Commodity-Management-Api';
import { deepMerge } from '../../../../../../Helpers/ObjectHelper';
import { useLoadingState } from '../../../../../../Hooks';
import { GroupingOption } from '../../../../../../Types/GridRow';
import { StoneXLoading, StoneXRow } from '../../../../../StoneX';
import { StoneXContractMonthPicker, StoneXDatePicker, StoneXDownloadButton, StoneXSelect } from '../../../../../StoneXMui';
import PositionReportSettingsModal from '../PositionReport/PositionReportSettingsModal';
import PositionReportSummaryDisplay from '../PositionReport/PositionReportSummaryDisplay';
import PositionReportTable from '../PositionReport/PositionReportTable';
import { PositionReportConfiguration, PositionReportRow, PositionReportUserOverride } from '../PositionReport/Types';
import { ModelToRowTransformer } from '../../../../../../Helpers/ModelToRowTransformer';

type ReportAction = 'Run' | 'Export to Excel';
const reportActions: ReportAction[] = ['Run', 'Export to Excel'];

export interface ProfitLossReportProps {
    customerName?: string;
    customerReport: CustomerReportModel;
    customerData: GetCustomerDataResponse;
    customerSettings: GetCustomerSettingsResponse;
    quoteDate: Date;
}

export function ProfitLossReport(props: ProfitLossReportProps) {
    const { customerName, customerReport, customerData, customerSettings } = props;

    const [quoteDate, setQuoteDate] = useState<Date | null>(props.quoteDate);
    const quoteDateString = useMemo(() => quoteDate ? dayjs(quoteDate).format('YYYY-MM-DD') : null, [quoteDate]);
    const [budgetStartMonth, setBudgetStartMonth] = useState<ContractDate | null>(null);
    const [budgetEndMonth, setBudgetEndMonth] = useState<ContractDate | null>(null);

    const reportLoadingState = useLoadingState();
    const [positions, setPositions] = useState<PositionModel[]>([]);
    const [positionsClosed, setPositionsClosed] = useState<PositionModel[]>([]);
    const [rows, setRows] = useState<PositionReportRow[]>([]);
    const [rowsClosed, setRowsClosed] = useState<PositionReportRow[]>([]);

    const reportConfiguration = useMemo(() => JSON.parse(customerReport.configuration!) as PositionReportConfiguration, [customerReport]);
    const userOverrides = useMemo(() => customerReport.userOverrides ? JSON.parse(customerReport.userOverrides) as PositionReportUserOverride : undefined, [customerReport]);
    const reportSettings = useMemo(() => deepMerge(reportConfiguration, userOverrides), [reportConfiguration, userOverrides]);

    const [groupingOptions, setGroupingOptions] = useState<GroupingOption[]>([]);

    const [isConfiguringReport, setIsConfiguringReport] = useState<boolean>(false);

    const [selectedReportAction, setSelectedReportAction] = useState<ReportAction | null>('Run');

    function getReport() {

        // TODO: Only call this report once using ExpiryStatus.All?

        CommodityManagementApi.getMarkToMarketReport(
            selectedReportAction === 'Run' ? reportLoadingState.setLoadingState : undefined,
            quoteDateString!,
            ExpiryStatus.Open,
            budgetStartMonth?.year,
            budgetStartMonth?.month,
            undefined,
            budgetEndMonth?.year,
            budgetEndMonth?.month,
            undefined,
            undefined,
            selectedReportAction === 'Run' ? undefined : { responseType: 'blob', headers: { 'ContentType': Constants.MediaType.Excel } },
        ).then((res) =>
            setPositions(res.data.rows ?? []),
        );
        getReportClosed();
    };

    function getReportClosed() {
        CommodityManagementApi.getMarkToMarketReport(
            selectedReportAction === 'Run' ? reportLoadingState.setLoadingState : undefined,
            quoteDateString!,
            ExpiryStatus.Expired,
            budgetStartMonth?.year,
            budgetStartMonth?.month,
            undefined,
            budgetEndMonth?.year,
            budgetEndMonth?.month,
            undefined,
            undefined,
            selectedReportAction === 'Run' ? undefined : { responseType: 'blob', headers: { 'ContentType': Constants.MediaType.Excel } },
        ).then((res) =>
            setPositionsClosed(res.data.rows ?? []),
        );
    };

    function updateReportRows() {
        const rows: PositionReportRow[] = positions.map(ModelToRowTransformer.convertModelToTableRow);

        // Filter out undefined groupings
        const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1]].filter(x => !!x).map(x => x!);

        // Update the path for each row based on groupings
        ModelToRowTransformer.updatePaths(rows, selectedGroupings);

        // Add positionId to path to make each path unique
        rows.forEach(x => x.path.push(x.positionId?.toString() ?? ''));

        setRows(rows);
    }
    function updateReportRowsClosed() {
        const rows: PositionReportRow[] = positionsClosed.map(ModelToRowTransformer.convertModelToTableRow);

        // Filter out undefined groupings
        const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1]].filter(x => !!x).map(x => x!);

        // Update the path for each row based on groupings
        ModelToRowTransformer.updatePaths(rows, selectedGroupings);

        // Add positionId to path to make each path unique
        rows.forEach(x => x.path.push(x.positionId?.toString() ?? ''));

        setRowsClosed(rows);
    }
    function updateGroupingOptions() {
        const groupingOptions: GroupingOption[] = ['BudgetMonth'];

        if (customerSettings.positionsByLocation) {
            groupingOptions.push('BudgetingTier');
            groupingOptions.push('Tiers');
        }

        if (customerSettings.positionsByCounterparty) {
            groupingOptions.push('Counterparty');
        }

        setGroupingOptions(groupingOptions);
    }

    function closeModal() {
        setIsConfiguringReport(false);
    }

    useEffect(updateReportRows, [positions, customerReport]);
    useEffect(updateReportRowsClosed, [positionsClosed, customerReport]);
    useEffect(updateGroupingOptions, []);

    return (
        <div>
            <StoneXRow extraHorizontalSpacing>
                {reportSettings?.inputs?.quoteDate?.show && <StoneXDatePicker onChange={setQuoteDate} label="Quote Date" value={quoteDate} width='small' />}
                {reportSettings?.inputs?.budgetStartDate?.show && <StoneXContractMonthPicker value={budgetStartMonth} label="Budget Start Month" onChange={setBudgetStartMonth} />}
                {reportSettings?.inputs?.budgetEndDate?.show && <StoneXContractMonthPicker value={budgetEndMonth} label="Budget End Month" onChange={setBudgetEndMonth} />}
                <StoneXSelect options={reportActions} getId={x => x} getOptionLabel={x => x} value={selectedReportAction} onChange={setSelectedReportAction} width='small' />
                <Button variant="outlined" onClick={getReport}>Go</Button>
                <Button className='pull-right' variant='outlined' onClick={() => setIsConfiguringReport(true)}>Configure Report</Button>
            </StoneXRow>

            <StoneXLoading show={reportLoadingState.isLoading()} />
            {reportLoadingState.isLoaded() && (
                <>
                    <StoneXRow align='center'>
                        <h2>{customerName} {customerReport.reportName} - Closed</h2>
                        <PositionReportSummaryDisplay positions={positionsClosed} quoteDateString={quoteDateString!} showNetOptionPremium={reportSettings?.behavior?.netOptionPremiums ?? false} />
                    </StoneXRow>

                    <PositionReportTable columnsToShow={reportSettings.columns?.active} rows={rowsClosed} />

                    <StoneXRow align='center'>
                        <h2>{customerName} {customerReport.reportName} - Open</h2>
                        <PositionReportSummaryDisplay positions={positions} quoteDateString={quoteDateString!} showNetOptionPremium={reportSettings?.behavior?.netOptionPremiums ?? false} />
                    </StoneXRow>

                    <PositionReportTable columnsToShow={reportSettings.columns?.active} rows={rows} />
                </>
            )}

            <PositionReportSettingsModal open={isConfiguringReport} customerReport={customerReport} groupingOptions={groupingOptions} onClose={closeModal} onSave={closeModal} />
        </div>
    );
} 