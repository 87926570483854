import { ICellRendererParams } from 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { find, uniqBy } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { BudgetModel } from '../../../../../Generated/Commodity-Management-Api';
import { StoneXGrid } from '../../../../StoneX';
import { StoneXNumber } from '../../../../StoneXMui';
import { BudgetRow } from '../Types';
import { MoreVert } from '@mui/icons-material';
import { ClickAwayListener, IconButton, MenuItem, MenuList, Paper, Popper } from '@mui/material';

interface BudgetTableProps {
  budgets: BudgetRow[];
}

export default function BudgetTable(props: BudgetTableProps) {
  const { budgets } = props;

  const gridRef = useRef<AgGridReact<BudgetModel>>(null);
  const navigate = useNavigate();

  const columns = useMemo(() => {
    const components = uniqBy(
      budgets.flatMap((x) => x.budgetPricingStrategy?.components ?? []),
      (x) => x!.componentType,
    );

    const pricingComponents =
      components.length > 1
        ? components.map((q) => {
            return {
              headerName: q.componentType!,
              valueGetter: (params: any) => {
                return find(params.data.budgetPrices, (r) => r.componentType == q.componentType)?.price ?? 0;
              },
            };
          })
        : [{ headerName: 'Price', field: 'price', cellClass: 'justify-right', filter: 'agNumberColumnFilter', flex: 1 }];

    const columns = [
      { headerName: 'Commodity', field: 'commodity.commodityName', flex: 1, filter: 'agSetColumnFilter' },
      { headerName: 'Counterparty', field: 'counterparty.counterpartyName', flex: 1, filter: 'agSetColumnFilter', hide: true },
      { headerName: 'Location', field: 'location.locationName', flex: 1, filter: 'agSetColumnFilter', hide: true },
      { headerName: 'Budget Month', field: 'budgetDate.label', flex: 1, filter: 'agDateColumnFilter' },
      // { headerName: 'Contract Month', field: 'contractDate.label', flex: 1, filter: 'agDateColumnFilter' },
      { headerName: 'Volume', field: 'volume', cellClass: 'justify-right', filter: 'agNumberColumnFilter', flex: 1, cellRenderer: (params: ICellRendererParams) => <StoneXNumber number={params.value} decimals={0} /> },
      ...pricingComponents,
      { headerName: 'Unit', field: 'unit', flex: 1, filter: 'agSetColumnFilter' },
      { headerName: 'Currency', field: 'currency', flex: 1, filter: 'agSetColumnFilter' },
      {
        colId: 'actions',
        headerName: '',
        width: 40,
        resizable: false,
        cellStyle: { padding: '0' },
        sortable: false,
        suppressAutoSize: true,
        suppressMovable: true,
        cellRenderer: (params: ICellRendererParams<BudgetRow>) => {

            const [open, setOpen] = useState(false);
            const anchorRef = useRef<HTMLButtonElement | null>(null);

            return <div>
              <IconButton ref={anchorRef} sx={{borderRadius: 1}} onClick={() => setOpen(true)}><MoreVert /></IconButton>
              
              <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" >
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <Paper>
                    <MenuList autoFocusItem={open}>
                      <MenuItem onClick={() => editBudgets(params.data!.budgetDate!.year!, params.data!.commodity!.commodityId, params.data!.location?.locationId, params.data!.counterparty?.counterpartyId)}>Edit Fiscal Year</MenuItem>
                      <MenuItem onClick={() => {}}>Audit</MenuItem>
                    </MenuList>
                  </Paper>
                </ClickAwayListener>
              </Popper>
            </div>
        },
      },
    ];

    return columns;
  }, [budgets]);

  function editBudgets(year: number, commodityId: number, locationId?: number | null, counterpartyId?: number | null) {
    const params = new URLSearchParams({
      year: year.toString(),
      commodityId: commodityId?.toString(),
    });
    if (locationId) {
      params.append('locationId', locationId.toString());
    }
    if (counterpartyId) {
      params.append('counterpartyId', counterpartyId.toString());
    }
    navigate(`update?${params.toString()}`);
  }

  const gridOptions: AgGridReactProps = useMemo(() => {
    return {
      treeData: true,
      getDataPath: (x: BudgetRow) => x.path,
      enableRangeSelection: true,
      groupDisplayType: 'groupRows',
      defaultColDef: {
        sortable: true,
        resizable: true,
      },
      groupRowRendererParams: {
        suppressCount: true,
      },
    };
  }, []);

  return <StoneXGrid rows={props.budgets} columns={columns} getRowId={x => x.data.budgetId}  gridOptions={gridOptions} onlyShowGroupLabels />;
}
