import { useEffect, useState } from "react";
import { StoneXNumber } from "../../../../../StoneXMui";
import { PositionModel, PositionType, Side } from "../../../../../../Generated/Commodity-Management-Api";
import { sum } from "lodash";

export interface PositionReportSummaryDisplayProps
{
    positions: PositionModel[];
    quoteDateString: string;
    showNetOptionPremium: boolean;
}

export default function PositionReportSummaryDisplay(props: PositionReportSummaryDisplayProps) 
{
    const { positions, quoteDateString, showNetOptionPremium } = props;

    const [totalMtm, setTotalMtm] = useState<number>();
    const [totalOptionPremiumn, setOptionPremiumn] = useState<number>();
    const [netMtm, setNetMtm] = useState<number>();

    function updateTotals() {
        const totalMtmValue = sum(positions.map((q) => q.markToMarketValue));

        const totalOptionPremium = showNetOptionPremium
            ? sum(positions.filter(x => x.positionType == PositionType.Option).map(x => x.price * x.effectiveVolume * (x.side == Side.Buy ? 1 : -1)))
            : 0;

        const netMtmValue = totalMtmValue - totalOptionPremium;

        setTotalMtm(totalMtmValue);
        setOptionPremiumn(totalOptionPremium);
        setNetMtm(netMtmValue);
    }

    useEffect(updateTotals, [positions, showNetOptionPremium]);

    return (
        <div className="pull-right">
            <div className="metric">
                <span className="metric-label"> MTM | {quoteDateString} </span> 
                <span className="metric-value">{<StoneXNumber number={netMtm} currency='USD' colored/>}</span>
            </div>
            { showNetOptionPremium && 
                <>
                    <div className="metric">
                        <span className="metric-label">Total MTM</span>
                        <span className="metric-value">{<StoneXNumber number={totalMtm} currency='USD' colored/>}</span>
                    </div>
                    <div className="metric">
                        <span className="metric-label">Net Option Premium</span>
                        <span className="metric-value">{<StoneXNumber number={totalOptionPremiumn} currency='USD'/>}</span>
                    </div>
                </>
            }
      </div>
    )
}