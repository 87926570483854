import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CommodityManagementSiteAccessModel } from '../../Generated/Commodity-Management-Api';

export interface ActiveSiteState {
    site: CommodityManagementSiteAccessModel | undefined;
}

interface SetActiveSitePayload {
    site: CommodityManagementSiteAccessModel | undefined;
}

const activeSiteFromSessionStorage = sessionStorage.getItem('activeSite') ? JSON.parse(sessionStorage.getItem('activeSite')!) : undefined;
const activeSiteFromLocalStorage = localStorage.getItem('lastActiveSite') ? JSON.parse(localStorage.getItem('lastActiveSite')!) : undefined;

const initialState: ActiveSiteState = {
    site: activeSiteFromSessionStorage ?? activeSiteFromLocalStorage
};

export const activeApplicationSlice = createSlice({
    name: 'activeSite',
    initialState,
    reducers: {
        setActiveSite: (state: ActiveSiteState, { payload }: PayloadAction<SetActiveSitePayload>): void => {
            sessionStorage.setItem('activeSite', JSON.stringify(payload.site));
            localStorage.setItem('lastActiveSite', JSON.stringify(payload.site));
            console.log('SITE STATE UPDATED');
            state.site = payload.site;
        },
        resetActiveSite: (state: ActiveSiteState) => {
            sessionStorage.removeItem('activeSite');
            delete state.site;
        }
    },
});

export const { setActiveSite, resetActiveSite } = activeApplicationSlice.actions;
export default activeApplicationSlice.reducer;
