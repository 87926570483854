import { Button } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { CommodityManagementApi } from '../../../../../../Apis/Apis';
import { Constants } from '../../../../../../Core/Constants';
import { ContractDate, CustomerReportModel, ExpiryStatus, GetCustomerDataResponse, GetCustomerSettingsResponse, PositionModel, ScenarioMarketPriceModel } from '../../../../../../Generated/Commodity-Management-Api';
import { ModelToRowTransformer } from '../../../../../../Helpers/ModelToRowTransformer';
import { deepMerge } from '../../../../../../Helpers/ObjectHelper';
import { useLoadingState } from '../../../../../../Hooks';
import { GroupingOption } from '../../../../../../Types/GridRow';
import { StoneXLoading, StoneXRow } from '../../../../../StoneX';
import { StoneXContractMonthPicker, StoneXDatePicker, StoneXSelect } from '../../../../../StoneXMui';
import PositionReportSettingsModal from './PositionReportSettingsModal';
import PositionReportSummaryDisplay from './PositionReportSummaryDisplay';
import PositionReportTable from './PositionReportTable';
import ScenarioMarketPrices from './ScenarioMarketPrices';
import { PositionReportColumn, PositionReportConfiguration, PositionReportRow, PositionReportUserOverride } from './Types';
import { without } from 'lodash';


type ReportAction = 'Run' | 'Export to Excel';
const reportActions: ReportAction[] = ['Run', 'Export to Excel'];

export interface MarkToMarketReportProps {
  customerName?: string;
  customerReport: CustomerReportModel;
  customerData: GetCustomerDataResponse;
  customerSettings: GetCustomerSettingsResponse;
  quoteDate: Date;
} 

export function PositionReport(props: MarkToMarketReportProps) {

  const { customerName, customerReport, customerData, customerSettings } = props;

  const [ quoteDate, setQuoteDate ] = useState<Date | null>(props.quoteDate);
  const quoteDateString = useMemo(() => quoteDate ? dayjs(quoteDate).format('YYYY-MM-DD') : null, [quoteDate]);
  const [ budgetStartMonth, setBudgetStartMonth ] = useState<ContractDate | null>(null);
  const [ budgetEndMonth, setBudgetEndMonth ] = useState<ContractDate | null>(null);
  const [ scenarioMarketPrices, setScenarioMarketPrices ] = useState<ScenarioMarketPriceModel[] | undefined>();
  const isScenarioEnabled = useMemo(() => scenarioMarketPrices && scenarioMarketPrices.length > 0, [scenarioMarketPrices]);

  const reportLoadingState = useLoadingState();
  const [positions, setPositions] = useState<PositionModel[]>([]);
  const [rows, setRows] = useState<PositionReportRow[]>([]);

  const reportConfiguration = useMemo(() => JSON.parse(customerReport.configuration!) as PositionReportConfiguration, [customerReport]);
  const userOverrides = useMemo(() => customerReport.userOverrides ? JSON.parse(customerReport.userOverrides) as PositionReportUserOverride : undefined, [customerReport]);
  const reportSettings = useMemo(() => deepMerge(reportConfiguration, userOverrides), [reportConfiguration, userOverrides]);
  const columnsToShow = useMemo(getColumnsToShow, [reportSettings, isScenarioEnabled]);

  const [ groupingOptions, setGroupingOptions ] = useState<GroupingOption[]>([]);

  const [ isConfiguringReport, setIsConfiguringReport ] = useState<boolean>(false);

  const [ selectedReportAction, setSelectedReportAction ] = useState<ReportAction | null>('Run');

  function getReport() {
    CommodityManagementApi.getMarkToMarketReport(
      selectedReportAction === 'Run' ? reportLoadingState.setLoadingState : undefined, 
      quoteDateString!,
      reportConfiguration?.behavior?.expiryStatus ?? ExpiryStatus.Expired,
      budgetStartMonth?.year,
      budgetStartMonth ? budgetStartMonth.month! + 1 : undefined,
      undefined,
      budgetEndMonth?.year,
      budgetEndMonth ? budgetEndMonth.month! + 1 : undefined,
      undefined,
      scenarioMarketPrices,
      selectedReportAction === 'Run' ? undefined : {responseType: 'blob', headers: {'ContentType': Constants.MediaType.Excel}}, 
    ).then((res) =>
      setPositions(res.data.rows ?? []),
    );
  };

  function getColumnsToShow() {
    if (isScenarioEnabled) {
      return reportSettings.columns?.active;
    }
    else {
      const scenarioColumns: PositionReportColumn[] = ['ScenarioMarketPrice', 'ScenarioMtmPrice', 'ScenarioMtmValue'];
      return without(reportSettings.columns?.active, ...scenarioColumns);
    }
  }

  function updateReportRows() {

    const rows: PositionReportRow[] = positions.map(ModelToRowTransformer.convertModelToTableRow);

    // Filter out undefined groupings
    const selectedGroupings = [reportSettings?.behavior?.grouping?.[0], reportSettings?.behavior?.grouping?.[1]].filter(x => !!x).map(x => x!);

    // Update the path for each row based on groupings
    ModelToRowTransformer.updatePaths(rows, selectedGroupings);

    // Add positionId to path to make each path unique
    rows.forEach(x => x.path.push(x.positionId?.toString() ?? ''));

    setRows(rows);
  }

  function updateGroupingOptions() {

    const groupingOptions: GroupingOption[] = ['Commodity', 'BudgetMonth'];

    if (customerSettings.positionsByLocation) {
      groupingOptions.push('BudgetingTier');
      groupingOptions.push('Tiers');
    }

    if (customerSettings.positionsByCounterparty) {
      groupingOptions.push('Counterparty');
    }

    setGroupingOptions(groupingOptions);
  }

  function closeModal() {
    setIsConfiguringReport(false);
  }

  useEffect(updateReportRows, [positions, customerReport]);
  useEffect(updateGroupingOptions, []);

  return (
    <div>
      <StoneXRow extraHorizontalSpacing>
        { reportSettings?.inputs?.quoteDate?.show && <StoneXDatePicker onChange={setQuoteDate} label="Quote Date" value={quoteDate} width='small' /> }
        { reportSettings?.inputs?.budgetStartDate?.show && <StoneXContractMonthPicker value={budgetStartMonth} label="Budget Start Month" onChange={setBudgetStartMonth}/> }
        { reportSettings?.inputs?.budgetEndDate?.show && <StoneXContractMonthPicker value={budgetEndMonth} label="Budget End Month" onChange={setBudgetEndMonth} /> }
        <StoneXSelect options={reportActions} getId={x => x} getOptionLabel={x => x} value={selectedReportAction} onChange={setSelectedReportAction} width='small' />
        <Button variant="outlined" onClick={getReport}>Go</Button>
        <Button className='pull-right' variant='outlined' onClick={() => setIsConfiguringReport(true)}>Configure Report</Button>
      </StoneXRow>

      <ScenarioMarketPrices onScenarioMaretPricesUpdated={setScenarioMarketPrices} />

      <StoneXLoading show={reportLoadingState.isLoading()} />
      {reportLoadingState.isLoaded() && (
        <>
          <StoneXRow align='center'>
            <h2>{customerName} {customerReport.reportName}</h2>
            <PositionReportSummaryDisplay positions={positions} quoteDateString={quoteDateString!} showNetOptionPremium={reportSettings?.behavior?.netOptionPremiums ?? false} />
          </StoneXRow> 
         
          <PositionReportTable columnsToShow={columnsToShow} rows={rows} />
        </>
      )}

      <PositionReportSettingsModal open={isConfiguringReport} customerReport={customerReport} groupingOptions={groupingOptions} onClose={closeModal} onSave={closeModal} />
    </div>
  );
} 