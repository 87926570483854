export type MonthInteger = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

export interface MonthDictionary {
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
  5: boolean;
  6: boolean;
  7: boolean;
  8: boolean;
  9: boolean;
  10: boolean;
  11: boolean;
  12: boolean;
}

export class MonthDictionaryHelper {
  public static fromArray(months: number[]): MonthDictionary {
    if (!months) {
      return this.empty();
    }

    return {
      1: months.indexOf(1) >= 0,
      2: months.indexOf(2) >= 0,
      3: months.indexOf(3) >= 0,
      4: months.indexOf(4) >= 0,
      5: months.indexOf(5) >= 0,
      6: months.indexOf(6) >= 0,
      7: months.indexOf(7) >= 0,
      8: months.indexOf(8) >= 0,
      9: months.indexOf(9) >= 0,
      10: months.indexOf(10) >= 0,
      11: months.indexOf(11) >= 0,
      12: months.indexOf(12) >= 0,
    };
  }

  public static toArray(monthDict: MonthDictionary): number[] {
    return Object.entries(monthDict)
      .filter(([month, value]) => value == true)
      .map(([month, value]) => parseInt(month));
  }

  public static empty(): MonthDictionary {
    return {
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
      6: false,
      7: false,
      8: false,
      9: false,
      10: false,
      11: false,
      12: false,
    };
  }
}