import { BarChart } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { ICellRendererParams } from "ag-grid-community";
import { ReactNode, useEffect, useState } from "react";

interface GroupcellRendererProps<T> {
  params: ICellRendererParams<T, any>;
  onlyShowGroupLabel?: boolean;
  innerRenderer?: (params: ICellRendererParams<T, any>) => ReactNode;
  onChartClick?: (path: string[]) => void;
  chartButtonDisplayCondition?: (params: ICellRendererParams<T, any>) => boolean;
}

export default function GroupCellRenderer<T>(props: GroupcellRendererProps<T>) {
  const { params, onlyShowGroupLabel, innerRenderer, onChartClick, chartButtonDisplayCondition } = props;

  const [isExpanded, setIsExpanded] = useState(params.node.expanded);

  const toggleExpand = () => {
    params.node.setExpanded(!isExpanded);
  };

  const chart = () => {
    if (onChartClick === undefined) {
      return;
    }

    // If this grouped cell is being rendered, it should have a path value.

    if (params?.node?.data !== undefined) {
      onChartClick((params.node.data as any)?.path);
      return;
    }

    // Is a grouped row. Chart the parent
    if (params?.node?.parent?.data !== undefined) {
      onChartClick((params.node.parent?.data as any)?.path);
      return;
    }
  };

  const isShowingChartButton = () => {
    if (onChartClick === undefined) {
      return false;
    }

    if (chartButtonDisplayCondition && !chartButtonDisplayCondition(params)) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const listener = () => setIsExpanded(params.node.expanded);

    params.node.addEventListener('expandedChanged', listener);

    return () => params.node.removeEventListener('expandedChanged', listener);
  }, [params.node]);

  if (onlyShowGroupLabel && !params.node.group) {
    return <></>;
  }

  if (!params.node.isExpandable()) {
    return <span className={`ag-group-value  ag-row-group-indent-${params.node.level}`}>{params.value}</span>;
  }

  return (
    <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      <span className={`ag-cell-wrapper ag-cell-expandable ag-row-group ag-row-group-indent-${params.node.level}`}>
        <span className="ag-group-contracted ">
          <span
            className={isExpanded ? 'ag-icon ag-icon-tree-open' : 'ag-icon ag-icon-tree-closed'}
            unselectable="on"
            role="presentation"
            onClick={toggleExpand}
          ></span>
        </span>
        <span className="ag-group-value">{innerRenderer ? innerRenderer(params) : params.value}</span>
      </span>
      {isShowingChartButton() && (
        <IconButton sx={{ color: 'var(--button-primary-color)' }} onClick={chart}> <BarChart /> </IconButton>
      )}
    </span>
  );
}