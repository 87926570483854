import React, { Children, Component, useState } from 'react';
import './Tabs.css';

interface StoneXTabsProps {
    activeTabIndex: number | undefined;
    children: any[];
}

export default function StoneXTabs(props: StoneXTabsProps) {
  
    const { activeTabIndex, children } = props;

    return (
      <div className="tabs-container">
          {Children.map(children, (child, index) =>
            React.cloneElement(child, {tabIndex: index, isActive: activeTabIndex === index})
          )}
      </div>
    );
}